import React, { useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import { Button, Card, message } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../../service/index';
import { Link } from 'react-router-dom';

const HistoryCallDetail = (props) => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [isSmall] = useGlobal('isSmall');
  const [audioUrl, setAudioUrl] = useState();

  const getAudio = () => {
    setLoading(true);
    api
      .get(`/rest/contact-us/history-calls/${params.company}/${params.id}`)
      .then(({ data: { result, result_message } }) => {
        if (result) setAudioUrl(result.url);
        else message.error('Detay bilgisi alınamadı !');
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error('Detay bilgisi alınamadı !');
        setLoading(false);
      });
    setLoading(false);
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Çağrı Detayları</h1>
        </div>
        <div className="list-buttons">
          <Link to={'/contact-us/history-calls/' + params.company}>
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && 'Geri'}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card title={'Bilgi'} loading={loading}>
          <div>
            <button
              type="button"
              onClick={getAudio}
              className="btn btn-outline-danger"
            >
              {' '}
              Ses Kayıtlarını getir
            </button>
            <br />
            <br />
            {audioUrl && (
              <>
                <audio
                  controls
                  style={{ objectFit: 'cover', width: 360 }}
                  alt=""
                  src={audioUrl}
                />
              </>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};
export default HistoryCallDetail;
