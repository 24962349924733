import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Breadcrumb } from 'antd';
import api from '../service/index';
import { Link, useParams } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  Loading3QuartersOutlined,
  LeftOutlined,
} from '@ant-design/icons';

const HistoryChargepoint = (props) => {
  const { Search } = Input;
  let params = useParams();
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let stationId = params.stationId;

  let query = [];
  query['stationId'] = stationId;

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'unique_id,name,chargepointStatus';
    }
    let restData = await api
      .get(`/rest/chargepoints?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            if (item.hasDC) item.hasDC = <div className="true-circle"></div>;
            else item.hasDC = <div className="false-circle"></div>;

            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let columns = [
    {
      title: languagesCms.UNIQUE_ID,
      dataIndex: 'unique_id',
      key: 'unique_id',
      sorter: (a, b) => a.unique_id - b.unique_id,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.NAME,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Şarj İstasyonu Adı',
      dataIndex: 'station_name',
      key: 'station_name',
      sorter: (a, b) => a.station_name - b.station_name,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'AC',
      dataIndex: 'ac',
      key: 'ac',
      sorter: (a, b) => a.ac - b.ac,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'DC',
      dataIndex: 'dc',
      key: 'dc',
      sorter: (a, b) => a.dc - b.dc,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ACTIONS ,
      key: 'action',
      align: 'right',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={'/history/' + stationId + '/' + record.unique_id}>
            <Button icon={<EditOutlined />}> {languagesCms.CONNECTORS} </Button>
          </Link>
          <Link to={'/historyView/' + stationId + '/' + record.unique_id}>
            <Button icon={<EditOutlined />}> {languagesCms.CHARGING_HISTORY} </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h4
            style={{
              display: 'inline-block',
              marginRight: '2rem',
              marginBottom: '1rem',
              color: '#fff',
            }}
          >
            {languagesCms.CHARGING_HISTORY}
           
          </h4>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href="/history"> {languagesCms.CHARGING_HISTORY} </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {data.length > 0 && data[0].station_name
                ? data[0].station_name
                : ''}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="list-buttons">
          {/* {!isSmall &&
                        <Button type="danger" icon={<DeleteOutlined />} size="large" onClick={deleteSelectedRows}
                            style={{ marginRight: "5px" }}>{languagesCms.DELETE_SELECTED}</Button>
                    } */}
          {/* <Button size={'large'} style={{ marginRight: "5px" }}
                        icon={<DownloadOutlined />}>{!isSmall && 'Export Excel'}</Button> */}
          {/* <Link to={"/stations/detail/" + id + "/add"} style={{ marginRight: "5px" }}>
                        <Button type="light" icon={<PlusOutlined />}
                            size={'large'}>{!isSmall && languagesCms.ADD_NEW}</Button>
                    </Link> */}
          {/* {!isSmall &&
                        <ImportExcel setExcelData={setExcelData} />
                    } */}
          <Link to="/history">
            <Button
              type="light"
              icon={<LeftOutlined />}
              Şarj
              Geçmişi
              size={'large'}
            >
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder={languagesCms.SEARCH_NAME_LASTNAME_PHONE}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default HistoryChargepoint;
