import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import { Button, Card, message, Row, Col } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import api from '../../service/index';
import LoadingAnimation from '../../components/LoadingAnimation';

const ActiveDevicesDetail = (props) => {
  const params = useParams();
  const id = params.id !== 'add' ? params.id : false;
  const [languagesCms] = useGlobal('languagesCms');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(id ? true : false);
  const [isSmall] = useGlobal('isSmall');

  const [modules] = useGlobal('modules');
  const path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  const get = async () => {
    setLoading(true);

    api
      .post('/rest/get-status', {
        chargeBoxId: id,
        triggerMessage: true,
      })
      .then(({ data }) => {
        if (data) {
          setData(data.result);
        }
      })
      .catch((err) => {
        message.error('Cihaz Durum Bilgisi Alınamadı ! ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // transaction

  useEffect(() => {
    if (modules && id) {
      get();
    }
  }, [id]);

  const refresh = () => {
    setData([]);
    setTimeout(() => {
      return get();
    }, 50);
  };

  console.log('loading ', loading);

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Button
            type="light"
            onClick={() => refresh()}
            icon={<i className="fa-solid fa-arrows-rotate"></i>}
            size={'large'}
            style={{
              marginRight: '5px',
            }}
          >
            Yenile
          </Button>
          <Link to="/active-devices">
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {loading === true ? (
          <LoadingAnimation />
        ) : (
          <Card title={'Cihaz Durum Detayları'} loading={loading}>
            <div style={{ maxHeight: '35%' }} className="site-card-wrapper">

              <Row direction="row">
                {data &&
                  data.map((item) => (
                    <>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <div
                          className="card"
                          style={{
                            width: '100%',
                            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                            marginBottom: '.7rem',
                          }}
                        >
                          <div className="card-header font-bold d-flex justify-content-between">
                            <span>
                              {item &&
                                item.connectorId +
                                  ` Nolu Konnektör ${
                                    item && String(item.connectorId) === '0'
                                      ? '( Cihaz )'
                                      : ''
                                  }`}
                            </span>
                          </div>
                          <div className="p-3">
                            <pre
                              style={{
                                fontWeight: 'medium',
                                padding: '.5rem',
                                fontSize: '1rem',
                              }}
                            >
                              {JSON.stringify(item, null, 2)}
                            </pre>
                          </div>
                        </div>
                      </Col>
                    </>
                  ))}
              </Row>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};
export default ActiveDevicesDetail;
