import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import { Button, Card } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';

const AlertDetail = (props) => {
  let params = useParams();
  let id = params.id !== 'add' ? params.id : false;
  let [languagesCms] = useGlobal('languagesCms');

  let [data, setData] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [isSmall] = useGlobal('isSmall');

  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }
  //onetime run
  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/alert/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/alert">
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card title={'Cihaz Durum'} loading={loading}>
          <pre
            style={{
              fontWeight: 'medium',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '1.3rem',
            }}
          >
            {JSON.stringify(data, null, 2)}
          </pre>
        </Card>
      </div>
    </div>
  );
};
export default AlertDetail;
