import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, message } from 'antd';
import { UserPicker } from '../index';
import { useParams, useHistory } from 'react-router-dom';
import { useGlobal } from 'reactn';
import api from '../../service/index';

const faultRecordStepOne = () => {
  const params = useParams();
  const history = useHistory();
  const id = params.id !== 'add' ? params.id : false;
  const newRecord = {
    userId: '',
    step: 0,
  };
  const [data, setData] = useState(id ? [] : newRecord);
  const [languagesCms] = useGlobal('languagesCms');
  const [validationCheck, setValidationCheck] = useState(false);
  const [errors, setErrors] = useState([]);

  const validate = useCallback(() => {
    let errors = {};

    if (data.userId === null || data.userId === undefined || data.userId === '')
      errors.userId = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  const save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        if (data.devicePublicId) delete data.devicePublicId;
        api
          .put('/rest/fault-record/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/fault-record');
            } else message.error(result_message.message, 2);
          });
      } else {
        if (data.devicePublicId) delete data.devicePublicId;
        data.step = 1;
        api
          .post('/rest/fault-record', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/fault-record/edit/' + result._id);
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  return (
    <div>
      <Form
        name="basic"
        layout="vertical"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <h4 className="text-center mt-3 mb-3">
          <i className="fa-solid fa-user-check"> </i> Son Kullanıcı ( Sürücü ) Seçiniz
        </h4>
        <Form.Item
          name="userId"
          style={{
            margin: '2rem 0',
          }}
          required
          help={errors.userId}
          validateStatus={errors.userId ? 'error' : 'success'}
        >
          <UserPicker
            disabled={id ? true : false}
            name="userId"
            languagesCms={languagesCms}
            record={data}
            setRecord={setData}
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 12,
            span: 16,
          }}
        >
          <Button type="primary" onClick={() => save()}>
            Kayıt Oluştur
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default faultRecordStepOne;
