import React, { useEffect, useGlobal } from 'reactn';
import { Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import SideMenu from './layout/SideMenu';

import {
  RedirectComp,
  Logs,
  NotFoundPage,
  PushNotification,
  PushNotificationDetail,
  Attendee,
  AttendeeDetail,
  Chargepoint,
  ChargepointDetail,
  Stations,
  StationsDetail,
  Connector,
  ConnectorDetail,
  Price,
  PriceDetail,
  PrivatePrice,
  PrivatePriceDetail,
  Manager,
  ManagerDetail,
  HistoryStations,
  HistoryChargepoint,
  HistoryConnector,
  HistoryView,
  Dashboard,
  Reservation,
  ReservationDetail,
  ReservationStationList,
  Dealer,
  DealerDetail,
  ActiveDevices,
  ActiveDevicesDetail,
  Alert,
  AlertDetail,
  DealerCosts,
  DealerCostDetail,
  LoyaltyPrices,
  LoyaltyPriceDetail,
  FleetPrices,
  FleetPriceDetail,
  QrCode,
  UserChargeInformation,
  UserChargeInformationList,
  UserChargeInformationDetail,
  ServiceProviders,
  ServiceProvidersDetail,
  ContactUs,
  ContactUsDetail,
  ActiveCalls,
  PriceList,
  PriceListDetail,
  HistoryCall,
  HistoryCallDetail,
  Rfid,
  RfidDetail,
  Reports,
  ReportsDetail,
  RfidBalance,
  RfidBalanceDetail,
  CmsLog,
  RfidTransactions,
  RfidTransactionDetail,
  AuthorizationDetail,
  Authorization,
  PriceStationList,
  ActiveCharge,
  PriceHistory,
  FacilityReport,
  UserCharges,
} from './pages';

import Setting from './pages/Settings/Setting';
import Module from './pages/Settings/Module';
import ModuleDetail from './pages/Settings/ModuleDetail';
import Customization from './pages/Settings/Customization';
import LoginSetting from './pages/Settings/LoginSetting';
import SurveyWithMediaIcons from './pages/Settings/SurveyWithMediaIcons';
import Version from './pages/Settings/Version';
import TopRightModule from './pages/Settings/TopRightModule';
import TopLeftModule from './pages/Settings/TopLeftModule';
import SmarteventSetting from './pages/Settings/SmarteventSetting';
import Deneme from './pages/Settings/Deneme';
import DenemeDetail from './pages/Settings/DenemeDetail';
import BottomBar from './pages/Settings/BottomBar';
import BottomBarDetail from './pages/Settings/BottomBarDetail';
import Languages from './pages/Settings/Languages';
import LanguagesDetail from './pages/Settings/LanguagesDetail';
import ProfilSettingsMenu from './pages/Settings/ProfilSettingsMenu';
import ProfilSettingsMenuDetail from './pages/Settings/ProfilSettingsMenuDetail';
import LikeTypes from './pages/Settings/LikeTypes';
import LikeTypesDetail from './pages/Settings/LikeTypesDetail';
import CoordinateTypes from './pages/Settings/CoordinateTypes';
import CoordinateTypesDetail from './pages/Settings/CoordinateTypesDetail';
import CoordinateMenu from './pages/Settings/CoordinateMenu';
import CoordinateMenuDetail from './pages/Settings/CoordinateMenuDetail';
import PointTypes from './pages/Settings/PointTypes';
import PointTypesDetail from './pages/Settings/PointTypesDetail';
import PointMenu from './pages/Settings/PointMenu';
import PointMenuDetail from './pages/Settings/PointMenuDetail';
import SupportedLang from './pages/Settings/SupportedLang';
import SupportedLangDetail from './pages/Settings/SupportedLangDetail';
import Live from './pages/Settings/Live';
import Nword from './pages/Settings/Nword';
import Nword2 from './pages/Settings/Nword2';
import WebApp from './pages/Settings/WebApp';
import WebAppDetail from './pages/Settings/WebAppDetail';
import PofileSettings from './pages/Settings/PofileSettings';
import AppControls from './pages/Settings/AppControls';
import HealthList from './pages/Settings/HealthList';
import ShakeWinMessages from './pages/Settings/ShakeWinMessages';
import ShakeWinMessagesDetail from './pages/Settings/ShakeWinMessagesDetail';
import ResultMessages from './pages/Settings/ResultMessages';
import ResultMessagesDetail from './pages/Settings/ResultMessagesDetail';
import SmsAndEmailMessage from './pages/Settings/SmsAndEmailMessage';
import SmsAndEmailMessageDetail from './pages/Settings/SmsAndEmailMessageDetail';
import PostwallSettings from './pages/Settings/PostwallSettings';
import TopRightChatModule from './pages/Settings/TopRightChatModule';
import BirthdaySettings from './pages/Settings/BirthdaySettings';
import JobStartDateSettings from './pages/Settings/JobStartDateSettings';
import ReservationSettings from './pages/Settings/ReservationSettings';
import ReservationSettingsBranch from './pages/Settings/ReservationSettingsBranch';
import PageReservationSettings from './pages/Settings/PageReservationSettings';
import PageReservationSettingsBranch from './pages/Settings/PageReservationSettingsBranch';
import TopRightShareButton from './pages/Settings/TopRightShareButton';
import PublicHolidays from './pages/Settings/PublicHolidays';
import LanguagesCms from './pages/Settings/LanguagesCms';
import LanguagesCmsDetail from './pages/Settings/LanguagesCmsDetail';
import PublicHolidaysDetail from './pages/Settings/PublicHolidaysDetail';
import PagePublicHolidays from './pages/Settings/PagePublicHolidays';
import PagePublicHolidaysDetail from './pages/Settings/PagePublicHolidaysDetail';
import ReservationAllSettings from './pages/Settings/ReservationAllSettings';
import PageReservationDepartments from './pages/Settings/PageReservationDepartments';
import PageReservationDepartmentsDetail from './pages/Settings/PageReservationDepartmentsDetail';
import ReservationKVKKSettings from './pages/Settings/ReservationKVKKSettings';
import PageReservationKVKKSettings from './pages/Settings/PageReservationKVKKSettings';

const { Content } = Layout;

const Admin = (props) => {
  let [token, setToken] = useGlobal('token');
  let [user] = useGlobal('user');
  let [isSmall, setIsSmall] = useGlobal('isSmall');
  let [collapsed, setCollapsed] = useGlobal('collapsed');

  useEffect(() => {
    window.onresize = () => {
      setIsSmall(window.innerWidth < 1024);
    };
  }, [setIsSmall, setToken]);
  useEffect(() => {
    if (isSmall) setCollapsed(true);
    else setCollapsed(false);
  }, [isSmall, setCollapsed]);

  if ((!token || !user) && window.location.pathname !== '/login') {
    props.history.push('/login');
  }
  return (
    <Switch>
      <Route>
        <Layout>
          <SideMenu collapsed={collapsed} />
          <Layout className="site-layout" style={{ minHeight: '100vh' }}>
            <Content key="page" className="main-wrap">
              {token && (
                <>
                  <Switch>
                    <Route exact path="/" component={RedirectComp} />

                    {/* Settings */}
                    <Route exact path="/settings" component={Setting} />
                    <Route exact path="/modules" component={Module} />
                    <Route exact path="/modules/:id" component={ModuleDetail} />
                    <Route
                      exact
                      path="/modules/detail/:id"
                      component={ModuleDetail}
                    />
                    <Route exact path="/bottomBar" component={BottomBar} />
                    <Route
                      exact
                      path="/bottomBar/:id"
                      component={BottomBarDetail}
                    />
                    <Route exact path="/languages" component={Languages} />
                    <Route
                      exact
                      path="/languages/:id"
                      component={LanguagesDetail}
                    />
                    <Route
                      exact
                      path="/profilSettingsMenu"
                      component={ProfilSettingsMenu}
                    />
                    <Route
                      exact
                      path="/profilSettingsMenu/:id"
                      component={ProfilSettingsMenuDetail}
                    />
                    <Route
                      exact
                      path="/coordinateTypes"
                      component={CoordinateTypes}
                    />
                    <Route
                      exact
                      path="/coordinateTypes/:id"
                      component={CoordinateTypesDetail}
                    />
                    <Route exact path="/pointTypes" component={PointTypes} />
                    <Route
                      exact
                      path="/pointTypes/:id"
                      component={PointTypesDetail}
                    />
                    <Route
                      exact
                      path="/shakeWinMessages"
                      component={ShakeWinMessages}
                    />
                    <Route
                      exact
                      path="/shakeWinMessages/:id"
                      component={ShakeWinMessagesDetail}
                    />
                    <Route
                      exact
                      path="/resultMessages"
                      component={ResultMessages}
                    />
                    <Route
                      exact
                      path="/resultMessages/:id"
                      component={ResultMessagesDetail}
                    />
                    <Route
                      exact
                      path="/settings/customization"
                      component={Customization}
                    />
                    <Route
                      exact
                      path="/settings/login_setting"
                      component={LoginSetting}
                    />
                    <Route
                      exact
                      path="/settings/surveyWithMediaIcons"
                      component={SurveyWithMediaIcons}
                    />
                    <Route
                      exact
                      path="/settings/smatevent_settings"
                      component={SmarteventSetting}
                    />
                    <Route exact path="/settings/version" component={Version} />
                    <Route
                      exact
                      path="/settings/topRightModule"
                      component={TopRightModule}
                    />
                    <Route
                      exact
                      path="/settings/topLeftModule"
                      component={TopLeftModule}
                    />
                    <Route exact path="/settings/deneme" component={Deneme} />
                    <Route
                      exact
                      path="/settings/deneme/:id/edit/:subId"
                      component={DenemeDetail}
                    />
                    <Route
                      exact
                      path="/settings/like_types"
                      component={LikeTypes}
                    />
                    <Route
                      exact
                      path="/settings/like_types/:id/edit/:subId"
                      component={LikeTypesDetail}
                    />
                    <Route
                      exact
                      path="/settings/coordinate_menu"
                      component={CoordinateMenu}
                    />
                    <Route
                      exact
                      path="/settings/coordinate_menu/:id/edit/:subId"
                      component={CoordinateMenuDetail}
                    />
                    <Route
                      exact
                      path="/settings/point_menu"
                      component={PointMenu}
                    />
                    <Route
                      exact
                      path="/settings/point_menu/:id/edit/:subId"
                      component={PointMenuDetail}
                    />
                    <Route
                      exact
                      path="/settings/supportedLangs"
                      component={SupportedLang}
                    />
                    <Route
                      exact
                      path="/settings/supportedLangs/:id/edit/:subId"
                      component={SupportedLangDetail}
                    />
                    <Route exact path="/settings/live" component={Live} />
                    <Route exact path="/settings/nword" component={Nword} />
                    <Route exact path="/settings/nword2" component={Nword2} />
                    <Route exact path="/settings/webApp" component={WebApp} />
                    <Route
                      exact
                      path="/settings/languagesCms"
                      component={LanguagesCms}
                    />
                    <Route
                      exact
                      path="/settings/languagesCms/:id"
                      component={LanguagesCmsDetail}
                    />
                    <Route
                      exact
                      path="/settings/webApp/:id/edit/:subId"
                      component={WebAppDetail}
                    />
                    <Route
                      exact
                      path="/settings/profileSettings"
                      component={PofileSettings}
                    />
                    <Route
                      exact
                      path="/settings/appControls"
                      component={AppControls}
                    />
                    <Route
                      exact
                      path="/settings/healthList"
                      component={HealthList}
                    />
                    <Route
                      exact
                      path="/settings/smsAndEmailMessage"
                      component={SmsAndEmailMessage}
                    />
                    <Route
                      exact
                      path="/settings/smsAndEmailMessage/:name/:id/edit/:subId"
                      component={SmsAndEmailMessageDetail}
                    />
                    <Route
                      exact
                      path="/settings/postwallSettings"
                      component={PostwallSettings}
                    />
                    <Route
                      exact
                      path="/settings/topRightChatModule"
                      component={TopRightChatModule}
                    />
                    <Route
                      exact
                      path="/settings/birthdaySettings"
                      component={BirthdaySettings}
                    />
                    <Route
                      exact
                      path="/settings/jobStartDateSettings"
                      component={JobStartDateSettings}
                    />
                    <Route
                      exact
                      path="/settings/reservationSettings"
                      component={ReservationSettings}
                    />
                    <Route
                      exact
                      path="/settings/reservationSettingsBranch"
                      component={ReservationSettingsBranch}
                    />
                    <Route
                      exact
                      path="/settings/pageReservationSettings"
                      component={PageReservationSettings}
                    />
                    <Route
                      exact
                      path="/settings/pageReservationSettingsBranch"
                      component={PageReservationSettingsBranch}
                    />
                    <Route
                      exact
                      path="/settings/topRightShareButton"
                      component={TopRightShareButton}
                    />

                    <Route
                      exact
                      path="/settings/publicHolidays"
                      component={PublicHolidays}
                    />
                    <Route
                      exact
                      path="/settings/publicHolidays/:id/edit/:subId"
                      component={PublicHolidaysDetail}
                    />
                    <Route
                      exact
                      path="/settings/publicHolidays/:id/add"
                      component={PublicHolidaysDetail}
                    />

                    <Route
                      exact
                      path="/settings/pagePublicHolidays"
                      component={PagePublicHolidays}
                    />
                    <Route
                      exact
                      path="/settings/pagePublicHolidays/:id/edit/:subId"
                      component={PagePublicHolidaysDetail}
                    />
                    <Route
                      exact
                      path="/settings/pagePublicHolidays/:id/add"
                      component={PagePublicHolidaysDetail}
                    />

                    <Route
                      exact
                      path="/settings/reservationAllSettings"
                      component={ReservationAllSettings}
                    />
                    <Route
                      exact
                      path="/settings/pageReservationDepartments"
                      component={PageReservationDepartments}
                    />
                    <Route
                      exact
                      path="/settings/pageReservationDepartments/edit/:id"
                      component={PageReservationDepartmentsDetail}
                    />
                    <Route
                      exact
                      path="/settings/pageReservationDepartments/add"
                      component={PageReservationDepartmentsDetail}
                    />

                    <Route
                      exact
                      path="/settings/reservationKVKKSettings"
                      component={ReservationKVKKSettings}
                    />
                    <Route
                      exact
                      path="/settings/pageReservationKVKKSettings"
                      component={PageReservationKVKKSettings}
                    />
                    {/* Settings */}

                    <Route
                      exact
                      path="/pushNotifications"
                      component={PushNotification}
                    />
                    <Route
                      exact
                      path="/pushNotifications/edit/:id"
                      component={PushNotificationDetail}
                    />
                    <Route
                      exact
                      path="/pushNotifications/add"
                      component={PushNotificationDetail}
                    />

                    <Route exact path="/attendees" component={Attendee} />
                    <Route
                      exact
                      path="/attendees/edit/:id"
                      component={AttendeeDetail}
                    />
                    <Route
                      exact
                      path="/attendees/add"
                      component={AttendeeDetail}
                    />

                    <Route exact path="/managers" component={Manager} />
                    <Route
                      exact
                      path="/managers/edit/:id"
                      component={ManagerDetail}
                    />
                    <Route
                      exact
                      path="/managers/add"
                      component={ManagerDetail}
                    />

                    <Route exact path="/stations" component={Stations} />

                    <Route
                      exact
                      path="/stations/edit/:id"
                      component={StationsDetail}
                    />
                    <Route
                      exact
                      path="/stations/add"
                      component={StationsDetail}
                    />
                    <Route
                      exact
                      path="/stations/detail/:id"
                      component={Chargepoint}
                    />
                    <Route
                      exact
                      path="/stations/detail/:stationId/add"
                      component={ChargepointDetail}
                    />
                    <Route
                      exact
                      path="/stations/detail/:stationId/edit/:id"
                      component={ChargepointDetail}
                    />
                    <Route
                      exact
                      path="/stations/detail/:stationId/:uniqueId"
                      component={Connector}
                    />
                    <Route
                      exact
                      path="/stations/detail/:stationId/:uniqueId/edit/:id"
                      component={ConnectorDetail}
                    />
                    <Route
                      exact
                      path="/stations/detail/:stationId/:uniqueId/add"
                      component={ConnectorDetail}
                    />

                    <Route exact path="/history" component={HistoryStations} />
                    <Route
                      exact
                      path="/history/:stationId"
                      component={HistoryChargepoint}
                    />
                    <Route
                      exact
                      path="/history/:stationId/:uniqueId"
                      component={HistoryConnector}
                    />
                    <Route exact path="/historyView" component={HistoryView} />
                    <Route
                      exact
                      path="/historyView/:stationId"
                      component={HistoryView}
                    />
                    <Route
                      exact
                      path="/historyView/:stationId/:uniqueId"
                      component={HistoryView}
                    />
                    <Route
                      exact
                      path="/historyView/:stationId/:uniqueId/:connectorId"
                      component={HistoryView}
                    />

                    <Route exact path="/prices" component={PriceStationList} />
                    <Route exact path="/prices/:stationId" component={Price} />
                    <Route
                      exact
                      path="/prices/:stationId/edit/:id"
                      component={PriceDetail}
                    />
                    <Route
                      exact
                      path="/prices/:stationId/add"
                      component={PriceDetail}
                    />
                    <Route
                      exact
                      path="/price-history/:stationId/:id"
                      component={PriceHistory}
                    />

                    <Route
                      exact
                      path="/private-prices"
                      component={PrivatePrice}
                    />
                    <Route
                      exact
                      path="/private-prices/edit/:id"
                      component={PrivatePriceDetail}
                    />
                    <Route
                      exact
                      path="/private-prices/add"
                      component={PrivatePriceDetail}
                    />

                    {/* Rezervasyon */}
                    <Route
                      exact
                      path="/reservation"
                      component={ReservationStationList}
                    />
                    <Route
                      exact
                      path="/reservation/:chargepoint"
                      component={Reservation}
                    />
                    <Route
                      exact
                      path="/reservation/:chargepoint/add"
                      component={ReservationDetail}
                    />
                    <Route
                      exact
                      path="/reservation/:chargepoint/edit/:id"
                      component={ReservationDetail}
                    />

                    {/* Tesisler */}
                    <Route exact path="/dealers" component={Dealer} />
                    <Route exact path="/dealer/add" component={DealerDetail} />
                    <Route
                      exact
                      path="/dealer/edit/:id"
                      component={DealerDetail}
                    />

                    {/* Aktif Cihazlar */}
                    <Route
                      exact
                      path="/active-devices"
                      component={ActiveDevices}
                    />
                    <Route
                      exact
                      path="/active-devices/:id"
                      component={ActiveDevicesDetail}
                    />

                    {/* Alert */}
                    <Route exact path="/alert" component={Alert} />
                    <Route exact path="/alert/:id" component={AlertDetail} />

                    {/* Tesis Maliyetleri */}
                    <Route exact path="/dealer-costs" component={DealerCosts} />
                    <Route
                      exact
                      path="/dealer-costs/add"
                      component={DealerCostDetail}
                    />
                    <Route
                      exact
                      path="/dealer-costs/edit/:id"
                      component={DealerCostDetail}
                    />
                    <Route
                      exact
                      path="/dealer-costs/view/:id"
                      component={DealerCostDetail}
                    />

                    {/* fiyat listesi */}
                    <Route exact path="/price-list/:id" component={PriceList} />
                    <Route
                      exact
                      path="/price-list/add/:id"
                      component={PriceListDetail}
                    />
                    <Route
                      exact
                      path="/price-list/edit/:pirceId/:id"
                      component={PriceListDetail}
                    />

                    {/* Layolty Tarifeler */}
                    <Route
                      exact
                      path="/loyalty-prices"
                      component={LoyaltyPrices}
                    />
                    <Route
                      exact
                      path="/loyalty-prices/edit/:id"
                      component={LoyaltyPriceDetail}
                    />

                    {/* Fllet Tarifeler */}
                    <Route exact path="/fleet-prices" component={FleetPrices} />
                    <Route
                      exact
                      path="/fleet-prices/edit/:id"
                      component={FleetPriceDetail}
                    />

                    {/* QR code */}
                    <Route exact path="/qrcode" component={QrCode} />

                    {/* Kullanıcı Şarj Bilgisi */}
                    <Route
                      exact
                      path="/user-charge-information"
                      component={UserChargeInformation}
                    />
                    <Route
                      exact
                      path="/user-charge-information/list/:userId"
                      component={UserChargeInformationList}
                    />
                    <Route
                      exact
                      path="/user-charge-information/list/:userId/:historyId"
                      component={UserChargeInformationDetail}
                    />

                    {/* Hizmet Sağlayıcılar */}
                    <Route
                      exact
                      path="/service-providers"
                      component={ServiceProviders}
                    />
                    <Route
                      exact
                      path="/service-providers/add"
                      component={ServiceProvidersDetail}
                    />
                    <Route
                      exact
                      path="/service-providers/edit/:id"
                      component={ServiceProvidersDetail}
                    />

                    {/* Bize Ulaşın */}
                    <Route exact path="/contact-us" component={ContactUs} />
                    <Route
                      exact
                      path="/contact-us/add/:id"
                      component={ContactUsDetail}
                    />
                    <Route
                      exact
                      path="/contact-us/edit/:id"
                      component={ContactUsDetail}
                    />
                    {/* aktif çağrılar */}
                    <Route
                      exact
                      path="/contact-us/active-calls/:company"
                      component={ActiveCalls}
                    />

                    {/* geçmiş çağrılar */}
                    <Route
                      exact
                      path="/contact-us/history-calls/:company"
                      component={HistoryCall}
                    />
                    <Route
                      exact
                      path="/contact-us/history-calls/:company/:id"
                      component={HistoryCallDetail}
                    />

                    {/* geçmiş çağrılar */}
                    <Route exact path="/rfid-management" component={Rfid} />
                    <Route
                      exact
                      path="/rfid-management/add"
                      component={RfidDetail}
                    />
                    <Route
                      exact
                      path="/rfid-management/edit/:id"
                      component={RfidDetail}
                    />

                    {/* raporlar */}
                    <Route exact path="/facility-reports" component={Reports} />
                    <Route
                      exact
                      path="/facility-reports/view/:id"
                      component={ReportsDetail}
                    />

                    {/* rfid bakiye */}
                    <Route
                      exact
                      path="/rfid-balance-upload"
                      component={RfidBalance}
                    />
                    <Route
                      exact
                      path="/rfid-balance-upload/add"
                      component={RfidBalanceDetail}
                    />
                    <Route
                      exact
                      path="/rfid-balance-upload/edit/:id"
                      component={RfidBalanceDetail}
                    />

                    {/* geçmiş çağrılar */}
                    <Route exact path="/cms-log" component={CmsLog} />

                    {/* rfid işlemleri */}
                    <Route
                      exact
                      path="/rfid-transactions"
                      component={RfidTransactions}
                    />
                    <Route
                      exact
                      path="/rfid-transaction/:id"
                      component={RfidTransactionDetail}
                    />

                    <Route exact path="/Logs" component={Logs} />
                    <Route exact path="/dashboard" component={Dashboard} />

                    {/* Yetkilendirmeler */}

                    <Route
                      exact
                      path="/authorization"
                      component={Authorization}
                    />
                    <Route
                      exact
                      path="/authorization/edit/:id"
                      component={AuthorizationDetail}
                    />

                    {/* aktif şarjlar */}
                    <Route
                      exact
                      path="/active-charge"
                      component={ActiveCharge}
                    />


                    <Route
                      exact
                      path="/facility-charge-information"
                      component={FacilityReport}
                    />
                    <Route
                      exact
                      path="/facility-charge/:userId"
                      component={UserCharges}
                    />


                    <Route path="*" component={NotFoundPage} />
                  </Switch>
                </>
              )}
            </Content>
          </Layout>
        </Layout>
      </Route>
    </Switch>
  );
};

export default Admin;
