import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { Row, Form, Button, Card, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import moment from 'moment';
import UserTable from '../../components/dealer/userTable';

const ReportsDetail = (props) => {
  const [form] = Form.useForm();

  const params = useParams();
  const history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  const [languagesCms] = useGlobal('languagesCms');

  let newRecord = {
    unitCost: 0,
    facilityId: '',
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
    costDocuments: [],
    shareMeasurementCost: '',
    shareReading: 0,
  };

  const [data, setData] = useState(id ? [] : newRecord);
  const [users, setUsers] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [isSmall] = useGlobal('isSmall');
  const [validationCheck, setValidationCheck] = useState(false);
  let [errors, setErrors] = useState([]);
  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/dealer-cost/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (
      !id &&
      data &&
      data.facilityId &&
      data.unitCost &&
      data.facilityId.length > 0
    ) {
      api
        .get(
          `/rest/dealer-cost-calculation/${data && data.facilityId}/${
            data && data.startDate
          }/${data && data.endDate}/${data.unitCost}/${
            data.shareMeasurementCost
          }/${data.shareReading}`,
        )
        .then(({ data }) => {
          if (data && data.result) {
            return data.result;
          }
        })
        .then((res) => {
          setUsers(res && res.users);
          setData({
            ...data,
            siteTotalConsumption: res.siteTotalConsumption,
            siteTotalServiceCharge: res.siteTotalServiceCharge,
          });
        });
    }
    if (
      id &&
      data &&
      data.facilityId &&
      data.unitCost &&
      data.facilityId.length > 0
    ) {
      api.get(`/rest/dealer-cost-calculation-get/${id}`).then(({ data }) => {
        if (data && data.result) {
          console.log('data : ', data);
          setUsers(data.result);
        }
      });
    }
  }, [data && data.facilityId, data.unitCost, data.shareMeasurementCost]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.unitCost === null || data.unitCost === 0 || data.unitCost < 0)
      errors.unitCost = languagesCms.ENFORCED;

    if (data.facilityId === null || data.facilityId === '')
      errors.facilityId = languagesCms.ENFORCED;

    if (
      data.shareMeasurementCost === null ||
      data.shareMeasurementCost === 0 ||
      data.shareMeasurementCost < 0
    )
      errors.shareMeasurementCost = languagesCms.ENFORCED;

    if (
      data.shareReading === null ||
      data.shareReading === 0 ||
      data.shareReading < 0
    )
      errors.shareReading = languagesCms.ENFORCED;

    if (data.startDate === null || data.startDate === '')
      errors.startDate = languagesCms.ENFORCED;

    if (data.endDate === null || data.endDate === '')
      errors.endDate = languagesCms.ENFORCED;

    if (
      data.costDocuments === null ||
      !Array.isArray(data.costDocuments) ||
      !data.costDocuments.length
    )
      errors.costDocuments = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  console.log(data);
  console.log(errors);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  const onFinish = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/dealer-cost/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/dealer-costs');
            } else message.error(result_message.message, 2);
          });
      } else {
        data.users = users;
        api
          .post('/rest/dealer-cost', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/dealer-costs');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/facility-reports">
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title="Raporlar"
          loading={loading}
        >
          <Form
            form={form}
            layout="vertical"
            className="w-100"
            disabled={window.location.pathname.includes('view')}
            name="basic"
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="on"
            size="large"
          >
            <Row direction="row">
              <UserTable record={users} />
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default ReportsDetail;
