import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import { Button, Card, Row, Col, Form } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';

const UserChargeInformationDetail = (props) => {
  const params = useParams();

  const historyId = params.historyId ? params.historyId : false;
  const [languagesCms] = useGlobal('languagesCms');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(historyId ? true : false);
  const [isSmall] = useGlobal('isSmall');

  const [modules] = useGlobal('modules');
  const path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }
  //onetime run
  useEffect(() => {
    if (modules && historyId) {
      setLoading(true);
      api
        .get(`/rest/history-detail/${historyId}`)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [historyId]);

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to={`/user-charge-information/list/${params.userId}`}>
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card title={'Şarj Detayları'} style={{fontWeight: 'bold'}} loading={loading}>
          <Row justify="space-between">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Kullanıcı Ad Soyad'}>
                {data && data.name && data.lastname && data.name + " " + data.lastname}
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Kart Numarası'}>
                {data && data.idTag && data.idTag}
              </Form.Item>
            </Col>
          </Row>
          <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
          <Row justify="space-between">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Tesis'}>
                {data && data.dealerName && data.dealerName}
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'transaction Id'}>
                {data && data.transactionId && data.transactionId}
              </Form.Item>
            </Col>
          </Row>
          <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
          <Row justify="space-between">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Fiyat Adı'}>
                {data && data.priceName && data.priceName}
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Fiyat Tarifesi'}>
                {data && data.priceAmount && data.priceAmount +" TL / kWh"}
              </Form.Item>
            </Col>
          </Row>
          <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
          <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Cihaz Unique Id'}>
                {data && data.unique_id && data.unique_id}
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Konnektör Id'}>
                {data && data.connector_id && data.connector_id}
              </Form.Item>
            </Col>
          </Row>
          <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
          <Row direction="row">
            <Col xs={{ span: 12 }} md={{ span: 12 }}>
              <Form.Item
                style={{ color: 'red' }}
                label={'Şarj Başlangıç Tarihi'}
              >
                {data && data.createdAt && moment(data.createdAt).format('DD-MM-YYYY HH:mm:ss')}
              </Form.Item>
            </Col>
            <Col xs={{ span: 12 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Şarj Bitiş Tarihi'}>
                {data && data.updatedAt && moment(data.updatedAt).format('DD-MM-YYYY HH:mm:ss')}
              </Form.Item>
            </Col>
          </Row>
          <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
          <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                style={{ color: 'red' }}
                label={'Ölçüm Başlangıç Değer'}
              >
                {data && data.meterStart && (data.meterStart /1000).toFixed(3)} kWh
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Ölçüm Bitiş Değer'}>
                {data && data.meterStop && (data.meterStop / 1000).toFixed(3)} kWh
              </Form.Item>
            </Col>
          </Row>
          <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
          <Row direction="row">
            <Col xs={{ span: 12 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'SoC Start'}>
                {data && data.startSoC && data.startSoC} %
              </Form.Item>
            </Col>
            <Col xs={{ span: 12 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'SoC Stop'}>
                {data && data.soc && data.soc} %
              </Form.Item>
            </Col>
          </Row>
          <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
          <Row direction="row">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Yüklenen Değer'}>
                {data && data.wattHour && (data.wattHour / 1000).toFixed(3) + ' kWh'} 
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item style={{ color: 'red' }} label={'Ödeme Tutarı'}>
                {data && data.price && data.price + ' TL / kWh'}
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default UserChargeInformationDetail;
