import React, { useState } from 'react';
import { useGlobal } from 'reactn';
import { Row, Col, Form, Button, Card } from 'antd';
import { DecryptText, EncryptTextAndQrcodeGenerator } from '../components';

const QrCode = () => {
  const [modules] = useGlobal('modules');
  const path = window.location.pathname.split('/')[1];
  const [module] = modules.filter((el) => el._id === path);

  // şifre çözme input açmak için
  const [decrypt, setDecrypt] = useState(false);

  // şifreleme inputları açmak için
  const [encrypt, setEncrypt] = useState(false);

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
      </div>
      <div className="form-wrap">
        <Card title={'QR Kod Oluştur'}>
          <Form
            layout="vertical"
            className="w-100"
            name="basic"
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            // onFinish={onFinish}
            autoComplete="on"
            size="large"
          >
            <Row direction="row">
              <Button
                className="save-button"
                style={{ borderRadius: '7px', marginBottom: '1rem'}}
                type="primary"
                block
                onClick={() => setEncrypt(encrypt === false ? true : false)}
              >
                QR Kod ve Şifre Oluştur
              </Button>
              <Button
                className="save-button"
                style={{ borderRadius: '7px', marginBottom: '1rem' }}
                type="primary"
                block
                onClick={() => setDecrypt(decrypt === false ? true : false)}
              >
                QR Çözümle
              </Button>
            </Row>

            <Row
              direction="row"
              style={{ marginBottom: '11px' }}
              className="gutter-row"
            >
              <Col span={24} md={24}>
                {decrypt && <DecryptText setDecrypt={setDecrypt} />}
              </Col>
            </Row>
            <Row
              direction="row"
              style={{ marginBottom: '11px' }}
              className="gutter-row"
            >
              <Col span={24} md={24}>
                {encrypt && (
                  <EncryptTextAndQrcodeGenerator setEncrypt={setEncrypt} />
                )}
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default QrCode;
