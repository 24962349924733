import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index'
import qs from 'qs';

export default (props) => {
	let { record, setRecord, name } = props;
	let [attendee, setAttendee] = useState([]);
	let _params = { query: {deleted: { $ne: true }}, sort: "-createdAt", page: 1, pageSize: 100000 };
	let languagesCms = props.languagesCms;
	useEffect(() => {
		api.get(`/rest/attendees?${qs.stringify(_params)}`).then(({ data }) => {
			if(data && data.result){
			let dbAttendee = data.result.sort((a, b) => a.name.localeCompare(b.name))
			setAttendee(dbAttendee);
			}
		})
	}, [])

	let mentionedUsers = (v) => {
		let attendees = v.map(x => {
			let mentionedUser = attendee.find(e => e._id === x)
				return ({ _id: mentionedUser._id, name: mentionedUser.name, lastname: mentionedUser.lastname, avatar: mentionedUser.avatar, isSelected: true });
		})
		setRecord({ ...record, [name]: attendees })
	}


	return <Select defaultValue={record[name]? record[name].map(e => e._id) : []} mode='multiple' placeholder={languagesCms.SELECT_ATTENDEE}
		showSearch={true}
		onChange={mentionedUsers}
		 filterOption={(input, option) =>
       		option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
	   		option.props.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0 }>
				   
		{(attendee) && attendee.map(b =>
			<Select.Option key={b._id} value={b._id} >{b.name} {b.lastname}</Select.Option>)}
	</Select>;
};
