import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  DatePicker,
  Space,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/tr_TR';
import SelectFromAllUser from '../../components/rfid/selectFromAllUser';
import { DealerPicker } from '../../components';
import RfidTransaction from '../../components/rfidTransacction/rfidTransaction';

const RfidDetail = (props) => {
  const [form] = Form.useForm();

  const params = useParams();
  const history = useHistory();
  const id = params.id !== 'add' ? params.id : false;
  const [languagesCms] = useGlobal('languagesCms');

  const newRecord = {
    status: 'active',
  };

  const [data, setData] = useState(id ? {} : newRecord);
  const [loading, setLoading] = useState(id ? true : false);
  const [isSmall] = useGlobal('isSmall');
  const [validationCheck, setValidationCheck] = useState(false);
  const [errors, setErrors] = useState([]);
  const [modules] = useGlobal('modules');
  const [user] = useGlobal('user');

  const path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/rfid/' + id)
        .then(({ data }) => {
          if (data && data.result) {
            const plate =
              data &&
              data.result &&
              data.result.carPlate &&
              data.result.carPlate.split('-');

            delete data.result.carPlate;

            console.log('plate: ,', plate);

            data.result['carPlate1'] = plate && plate[0] && plate[0];
            data.result['carPlate2'] = plate && plate[1] && plate[1];
            data.result['carPlate3'] = plate && plate[2] && plate[2];

            setData(data.result);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (
      data.carPlate === null ||
      data.carPlate === undefined ||
      data.carPlate === ''
    )
      errors.carPlate = 'Lütfen Araç Plakası Girin';

    if (data.userId === null || data.userId === undefined || data.userId === '')
      errors.userId = 'Lütfen Kullanıcı Seçiniz';

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  const onFinish = async () => {
    const plate =
      data && data.carPlate1 + '-' + data.carPlate2 + '-' + data.carPlate3;

    data.carPlate = plate && plate.toUpperCase();

    console.log(data);

    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      delete data.carPlate1;
      delete data.carPlate2;
      delete data.carPlate3;

      if (id) {
        api.put('/rest/rfid/' + id, data).then(({ data }) => {
          if (data && data.result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/rfid-management');
          } else
            message.error(
              data && data.result_message && data.result_message.message,
              2,
            );
        });
      } else {
        api
          .post('/rest/rfid', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/rfid-management');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };
  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <RfidTransaction rfid={data && data.rfid} />

          <Link to="/rfid-management">
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form
            form={form}
            className="w-100"
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="on"
            size="large"
          >
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item
                  label="RFID Numarası"
                  name="rfid"
                  required
                  rules={[
                    {
                      required: id ? false : true,
                      message: 'Lütfen RFID Numarası Giriniz !',
                    },
                    {
                      min: 4,
                      message: 'En Az 4 Karakter Girişi Yapınız!',
                    },
                    {
                      max: 20,
                      message: 'En Fazka 20 Karakter Girişi Yapabilirsiniz!',
                    },
                  ]}
                >
                  {id ? (
                    <span className="fs-3">{data && data.rfid}</span>
                  ) : (
                    <Input
                      onChange={(e) =>
                        setData({ ...data, rfid: e.target.value })
                      }
                      disabled={id}
                      value={data && data.rfid}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="Kart Tanımlama Tarihi" name="cardIssueDate">
                  {id ? (
                    <span className="fs-3">
                      {moment(data && data.cardIssueDate).format('YYYY-MM-DD')}
                    </span>
                  ) : (
                    <DatePicker
                      locale={locale}
                      disabled
                      format="YYYY-MM-DD"
                      onChange={(date, dateString) => {
                        setData({ ...data, cardIssueDate: dateString });
                      }}
                      placeholder="Tarih"
                      defaultValue={
                        data &&
                        (data.cardIssueDate
                          ? moment(data.cardIssueDate, 'DD-MM-YYYY')
                          : moment(new Date(), 'DD-MM-YYYY'))
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="RFID Durumu" name="status">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(v) => {
                      setData({ ...data, status: v.target.value });
                    }}
                  >
                    <option
                      defaultSelected
                      selected={data.status === 'active' ? true : false}
                      value="active"
                    >
                      Aktif
                    </option>
                    <option
                      selected={data.status === 'passive' ? true : false}
                      value="passive"
                    >
                      Pasif
                    </option>
                  </select>
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Araç Plakası"
                  name="carPlate"
                  required
                  help={errors.carPlate}
                  validateStatus={errors.carPlate ? 'error' : 'success'}
                >
                  <Space.Compact size="large">
                    <Input
                      placeholder="İl Kodu"
                      defaultValue={data && data.carPlate1}
                      type="number"
                      onChange={(e) => {
                        console.log(e.target.value);
                        setData({ ...data, carPlate1: e.target.value });
                      }}
                    />
                    <Input
                      placeholder="Harf"
                      defaultValue={data && data.carPlate2}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setData({ ...data, carPlate2: e.target.value });
                      }}
                    />
                    <Input
                      placeholder="Sayı"
                      type="number"
                      defaultValue={data && data.carPlate3}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setData({ ...data, carPlate3: e.target.value });
                      }}
                    />
                  </Space.Compact>
                </Form.Item>
              </Col>
            </Row>
            {!id && (
              <Row direction="row">
                <>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      required
                      rules={[
                        {
                          required: id ? false : true,
                          message: 'Lütfen Tesis Seçiniz !!!',
                        },
                      ]}
                      label={'Tesis Seçiniz'}
                    >
                      <DealerPicker
                        languagesCms={languagesCms}
                        record={data}
                        setRecord={setData}
                        name="facilityId"
                        dataLimit={
                          [1, 2].includes(user && user.role) === true
                            ? 'allData'
                            : [5].includes(user && user.role) === true
                            ? 'theirSites'
                            : null
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label="Kullanıcı Seçimi"
                      name="userId"
                      disabled={data && !data.facilityId}
                      required
                      help={errors.userId}
                      validateStatus={errors.userId ? 'error' : 'success'}
                    >
                      <SelectFromAllUser
                        record={data}
                        setRecord={setData}
                        name="userId"
                        facilityId={data && data.facilityId}
                      />
                    </Form.Item>
                  </Col>
                </>
              </Row>
            )}
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label="Kart Açıklaması"
                  name="description"
                >
                  <textarea
                    style={{ width: '100% !important' }}
                    onChange={(e) =>
                      setData({ ...data, description: e.target.value })
                    }
                    defaultValue={
                      data && data.description ? data.description : ''
                    }
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                  ></textarea>
                </Form.Item>
              </Col>
            </Row>

            <Button type="primary" htmlType="submit" size="large" block>
              RFID Kaydet
            </Button>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default RfidDetail;
