import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button } from 'antd';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import ExcelJS from 'exceljs';
import moment from 'moment';
import { LoadingAnimation } from '../../components';

const Reports = (props) => {
  let [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [languagesCms] = useGlobal('languagesCms');
  const [modules] = useGlobal('modules');
  const path = props.location.pathname.split('/')[1];
  let [module] = modules.filter((el) => el._id === path);
  const [dealerInfo] = useState([]);
  const dealerSet = [...new Set(dealerInfo.map(JSON.stringify))].map(
    JSON.parse,
  );

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let query = [];

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = '-createdAt';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    const restData = await api
      .get(`/rest/dealer-cost?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result && data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            dealerInfo.push({ text: item.dealer, value: item.dealer });
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
    setLoading(false);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  const getReport = (id) => {
    api
      .get(`/rest/dealer-cost-calculation-get/${id}`)
      .then(({ data }) => {
        if (data && data.result) {
          return data.result;
        }
      })
      .then((res) => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('Sheet1');

        // Tablo başlığını ekleyin
        sheet.addRow([
          'Kat Sahibi',
          'Blok',
          'Kat',
          'Daire',
          'Toplam Tüketim ( kWh )',
          'Tüketim Bedeli ( TL )',
          'Pay Ölçme Bedeli ( TL )',
          'Toplam Elektrikli Araç Şarj Hizmeti Bedeli ( TL )',
        ]);

        // Verileri ekleyin
        const dataMap = res.map((item) => {
          return {
            homeowner: item.homeowner,
            blok: item.blok,
            floor: item.floor,
            apartment: item.apartment,
            consumption: item.consumption,
            consumptionCost: item.consumptionCost,
            shareMeasurementCost: item.shareMeasurementCost,
            totalServiceCharge: Number(item.totalServiceCharge),
          };
        });

        dataMap.forEach((row) => {
          sheet.addRow([
            row.homeowner,
            row.blok,
            row.floor,
            row.apartment,
            row.consumption,
            row.consumptionCost,
            row.shareMeasurementCost,
            row.totalServiceCharge,
          ]);
        });

        // Toplam değeri hesaplayın
        const totalRow = sheet.addRow([
          null,
          null,
          null,
          null,
          null,
          null,
          'Toplam:',
          null,
        ]);
        totalRow.getCell('G').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFF00' }, // Sarı renkte arka plan
        };
        totalRow.getCell('G').font = {
          bold: true, // Kalın font
        };
        totalRow.getCell('H').value = {
          formula: `SUM(H1:H${totalRow.number - 1})`, // Formül ile toplam değeri hesapla
        };
        totalRow.getCell('H').font = {
          bold: true, // Kalın font
        };

        sheet.getColumn('A').width = 20;
        sheet.getColumn('B').width = 7;
        sheet.getColumn('C').width = 7;
        sheet.getColumn('D').width = 7;
        sheet.getColumn('E').width = 20;
        sheet.getColumn('F').width = 20;
        sheet.getColumn('G').width = 30;
        sheet.getColumn('H').width = 45;

        workbook.calcProperties.fullCalcOnLoad = true;

        // Dosya verisini Uint8Array olarak alın
        workbook.xlsx.writeBuffer().then((buffer) => {
          const fileData = new Uint8Array(buffer);

          // Blob oluşturun
          const blob = new Blob([fileData], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          // Dosya adını ayarlayın
          const fileName = 'Rapor.xlsx';

          // Dosya indirme bağlantısı oluşturun
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.download = fileName;

          // Dosyayı indirme bağlantısını tıklayarak indirin
          downloadLink.click();

          // Dosya indirme bağlantısını temizleyin
          window.URL.revokeObjectURL(downloadLink.href);
        });
      });
  };

  let columns = [
    {
      title: 'Tesis',
      dataIndex: 'dealer',
      key: 'key',
      align: 'center',
      width: "15%",
      sorter: (a, b) => a.dealer - b.dealer,
      sortDirections: ['descend', 'ascend'],
      filters: dealerSet,
      onFilter: (value, record) =>
        record && record.dealer && record.dealer === value,
    },
    {
      title: 'Başlangıç Tarihi',
      dataIndex: 'startDate',
      key: 'key',
      align: 'center',
      width: '15%',
      render: (text) => moment(text).format('YYYY-MM-DD'),
    },
    {
      title: 'Bitiş Tarihi',
      dataIndex: 'endDate',
      key: 'key',
      align: 'center',
      width: '15%',
      render: (text) => moment(text).format('YYYY-MM-DD'),
    },
    {
      title: (
        <div>
          Birim Maliyet
          <br />( TL / kWh )
        </div>
      ),
      dataIndex: 'unitCost',
      key: 'key',
      width: "15%",
      align: 'center',
      render: (record, text) =>
        new Intl.NumberFormat('tr-TR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(record),
    },
    {
      title: (
        <div>
          Toplam Tüketim <br />
          (kWh)
        </div>
      ),
      width: "15%",
      dataIndex: 'siteTotalConsumption',
      key: 'siteTotalConsumption',
      align: 'center',
    },
    {
      title: (
        <div>
          Toplam Hizmet
          <br />
          Maliyeti ( TL )
        </div>
      ),
      width: "15%",
      dataIndex: 'siteTotalServiceCharge',
      key: 'siteTotalServiceCharge',
      align: 'center',
      render: (record, text) =>
        new Intl.NumberFormat('tr-TR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(record),
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'center',
      width: "10%",
      render: (text, record) => (
        <Space size="small">
          <Button
            type="danger"
            size='medium'
            onClick={() => getReport(record && record._id)}
            icon={<i className="fa-regular fa-file-excel"></i>}
          >
            İndir
          </Button>
          <Link to={'/facility-reports/view/' + record._id}>
            <Button
              className="btn btn-outline-secondary"
              icon={
                <i
                  style={{ fontSize: '1rem' }}
                  className="fa-solid fa-file-contract"
                ></i>
              }
            >
              Görüntüle
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}{' '}
          </h1>
        </div>
        <div className="list-buttons"></div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <LoadingAnimation spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          rowClassName={(record, index) =>
            record.completed ? 'disabled-row' : ''
          }
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default Reports;
