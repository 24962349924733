import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { Table, Button, Input, Popconfirm } from 'antd';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import RfidTransaction from '../../components/rfidTransacction/rfidTransaction';

const RfidBalance = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  const [module] = modules.filter((el) => el._id === path);
  let query = [];

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'rfid, carPlate';
    }

    let restData = await api
      .get(`/rest/rfid-balance-upload?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return (
            data &&
            data.result &&
            data.result.rows.map((item, key) => {
              item.key = key;
              return item;
            })
          );
        }
      });
    setData(restData);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let deleteRow = async (item_id) => {
    api.delete(`/rest/rfid/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  const columns = [
    {
      title: 'Rfid Numarası',
      dataIndex: 'rfid',
      key: 'rfid',
      width: '18%',
      align: 'center',
    },
    {
      title: 'Kullanıcı',
      dataIndex: 'userName',
      key: 'userName',
      width: '18%',
      align: 'center',
    },
    {
      title: 'Durumu',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '18%',
      render: (text) =>
        text === 'active' ? (
          <span style={{ color: 'green', fontWeight: 'bold' }}>Aktif</span>
        ) : (
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            {text === 'passive' ? 'Pasif' : text}
          </span>
        ),
    },
    {
      title: 'Plaka',
      dataIndex: 'carPlate',
      key: 'carPlate',
      width: '18%',
      align: 'center',
      ellipsis: true,
      render: (text) => (text ? text : ''),
    },
    {
      title: 'Bakiye',
      dataIndex: 'price',
      key: 'price',
      width: '18%',
      align: 'center',
      ellipsis: true,
      render: (text) => (text ? text : '0'),
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
      width: '18%',
      align: 'center',
      ellipsis: true,
    },
    {
      title: 'Aksiyonlar',
      dataIndex: 'description',
      key: 'description',
      width: '28%',
      align: 'center',
      render: (text, record) => (
        <>
          <div>
              <Link className="py-0" to={'/rfid-balance-upload/edit/' + record._id}>
                <Button
                  className="w-100 mb-1 py-0"
                  size="medium"
                  icon={<EditOutlined />}
                >
                  {!isSmall && 'Bakiye Yükleme'}
                </Button>
              </Link>
              <RfidTransaction
                size={'medium'}
                className="w-100 btn btn-outline-danger mb-1 btn-sm py-0"
                rfid={record && record.rfid}
              />
              <Popconfirm
                onConfirm={() => deleteRow(record._id)}
                title={languagesCms.CHECK_DELETE}
                okText={languagesCms.OK_TEXT}
                cancelText={languagesCms.CANCEL_TEXT}
                className="w-100 py-0"
              >
                <Button size="medium" danger className="w-100 btn btn-danger btn-sm py-0">
                  <DeleteOutlined />
                  {!isSmall && languagesCms.DELETE}
                </Button>
              </Popconfirm>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}
          </h1>
        </div>
        <div className="list-buttons"></div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder="Arama... ( RFID Numarası, Araç Plaka ile arama yapılabilir. Örnek Plaka Formatı 34-aa-34 )"
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default RfidBalance;
