import React, { useState } from 'react';
import { Form, Row, Select, Col, Input } from 'antd';
import { UserPicker } from '../index';
import { useGlobal } from 'reactn';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const contactUsRecordStepOne = ({ record, setRecord, errors }) => {
  const [languagesCms] = useGlobal('languagesCms');
  const [savedUser, setSavedUser] = useState(false);
  const [otherUser, setOtherUser] = useState(false);

  return (
    <div>
      <Form
        name="basic"
        layout="vertical"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <div className="d-flex justify-content-center mt-1 mb-3">
          <i className="fa-solid fa-person-circle-check fs-4">
            &nbsp;&nbsp;Kullanici tanimi
          </i>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button
            type="button"
            className="btn btn-outline-danger btn-sm me-1"
            onClick={() => {
              setSavedUser(savedUser ? false : true);
              setOtherUser(false);
            }}
          >
            Kayıtlı Kullanıcı{' '}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger btn-sm ms-1"
            onClick={() => {
              setOtherUser(otherUser ? false : true);
              setSavedUser(false);
            }}
          >
            Misafir Kullanıcı
          </button>
        </div>

        {savedUser && (
          <Form.Item
            label="Son Kullanıcı ( Sürücü )  Seçiniz"
            name="userId"
            style={{
              margin: '2rem 0',
            }}
            required
            help={errors.userId}
            validateStatus={errors.userId ? 'error' : 'success'}
          >
            <UserPicker
              name="userId"
              languagesCms={languagesCms}
              record={record}
              setRecord={setRecord}
            />
          </Form.Item>
        )}
        {otherUser && (
          <>
            <Row direction="row">
              <Col span={12}>
                <Form.Item
                  label="Ad"
                  name="name"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="name"
                    defaultValue={record.name}
                    onChange={(e) =>
                      setRecord({ ...record, name: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Soyad"
                  name="lastname"
                  required
                  help={errors.lastname}
                  validateStatus={errors.lastname ? 'error' : 'success'}
                >
                  <Input
                    name="lastname"
                    defaultValue={record.lastname}
                    onChange={(e) =>
                      setRecord({ ...record, lastname: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  required
                  help={errors.email}
                  validateStatus={errors.email ? 'error' : 'success'}
                >
                  <Input
                    name="email"
                    defaultValue={record.email}
                    onChange={(e) =>
                      setRecord({ ...record, email: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Phone"
                  name="phone"
                  style={{
                    margin: '0',
                    padding: '0',
                  }}
                  required
                  help={errors.phone}
                  validateStatus={errors.phone ? 'error' : 'success'}
                >
                  <PhoneInput
                    width={'100%'}
                    country={'tr'}
                    value={record.phone}
                    onChange={(e) => setRecord({ ...record, phone: e })}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        <Form.Item
          label="İletişim Türü"
          name="contactType"
          style={{
            margin: '2rem 0',
          }}
          required
          help={errors.contactType}
          validateStatus={errors.contactType ? 'error' : 'success'}
        >
          <Select
            name="contactType"
            defaultValue={record.contactType}
            onChange={(e) => setRecord({ ...record, contactType: e })}
          >
            <Select.Option value={'faultRecord'}>Arıza Kaydı</Select.Option>
            <Select.Option value={'proposal'}>Öneri</Select.Option>
            <Select.Option value={'other'}>Diğer</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
};

export default contactUsRecordStepOne;
