import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// Create Document Component
const MyDocument = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image
          style={{ width: '60%' }}
          src={data && data.qrMedia && data.qrMedia.url && data.qrMedia.url}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontWeight: '900',
              color: 'red',
              marginBottom: '21px',
              fontSize: '27px',
            }}
          >
            {data && data.devicePublicId && data.devicePublicId}{' '}
          </Text>
   
          <Text
            style={{
              fontWeight: '900',
              color: 'red',
              marginBottom: '21px',
              fontSize: '27px',
            }}
          >
           Soket: {data && data.connectorUniqueNumber && data.connectorUniqueNumber}{' '}
          </Text>
        </div>
      </View>
    </Page>
  </Document>
);

const PdfDownload = ({ record }) => (
  <div>
    <PDFDownloadLink
      document={<MyDocument data={record} />}
      fileName={
        record &&
        record.unique_id &&
        record.unique_id + '#' + record.connector_id + '.pdf'
      }
      className="btn btn-outline-danger"
    >
      {({ blob, url, loading, error }) =>
        loading ? 'Doküman Yükleniyor ...' : 'Pdf İndir'
      }
    </PDFDownloadLink>
  </div>
);

export default React.memo(PdfDownload);
