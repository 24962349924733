import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import { Modal, Form, Col, Row } from 'antd';
import DevicePicker from '../device/devicePicker';
import StationPicker from '../stationPicker';
import LoadingAnimation from '../LoadingAnimation';
import DatePicker from '../Date/DatePicker';
import moment from 'moment';
import GetReports from '../GetReports';

const ChargeReportModal = ({ stationId }) => {
  const [languagesCms] = useGlobal('languagesCms');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const firstData = {
    stationId: stationId ? stationId : '',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    deviceIds: [],
  };
  const [resData, setResData] = useState(firstData);

  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(false);
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setLoading(false);
    setResData(firstData);
    setIsModalOpen(false);
  };

  return (
    <>
      <button
        type="button"
        onClick={showModal}
        className="btn btn-light btn-md me-3"
      >
        <i className="fa-regular fa-file-excel"></i>{' '}
        {languagesCms.Device_Reports}
      </button>

      <Modal
        title={languagesCms.REPORT}
        visible={isModalOpen}
        onOk={handleCancel}
        onCancel={handleCancel}
        style={{
          borderRadius: '21px !important',
          maxHeight: '75vh',
          scrollBehavior: 'smooth',
        }}
        okText={languagesCms.Close}
        cancelText={languagesCms.Close}
        width={1000}
      >
        <>
          {loading ? (
            <LoadingAnimation />
          ) : (
            <>
              <Form
                form={form}
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                autoComplete="off"
              >
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label={languagesCms.Starting_date}
                      labelCol={{
                        span: 24,
                      }}
                      required
                    >
                      <DatePicker
                        record={resData}
                        setRecord={setResData}
                        name="startDate"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label={languagesCms.End_Date}
                      labelCol={{
                        span: 24,
                      }}
                      required
                    >
                      <DatePicker
                        record={resData}
                        setRecord={setResData}
                        name="endDate"
                      />
                    </Form.Item>
                  </Col>

                  <>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label={languagesCms.Charging_Stations}
                        required
                        labelCol={{
                          span: 24,
                        }}
                      >
                        <StationPicker
                          disabled={stationId ? true : false}
                          languagesCms={languagesCms}
                          record={resData}
                          setRecord={setResData}
                          name="stationId"
                          mode="single"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label={languagesCms.Select_Device}
                        labelCol={{
                          span: 24,
                        }}
                        required
                      >
                        <DevicePicker
                          record={resData}
                          setRecord={setResData}
                          mode="multiple"
                          name="deviceIds"
                          text={languagesCms.Select_Device}
                        />
                      </Form.Item>
                    </Col>
                  </>
                </Row>
                <Row direction="row">
                  <GetReports
                    fileName={
                      languagesCms.Charging_Stations + '_' + languagesCms.REPORT
                    }
                    url={'/rest/reports/charge-report'}
                    query={resData}
                    className="btn btn-outline-danger w-100"
                  />
                </Row>
              </Form>
            </>
          )}
        </>
      </Modal>
    </>
  );
};

export default ChargeReportModal;
