import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Button, Popconfirm } from 'antd';
import api from '../../service/index';
import { Link, useParams } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { LoadingAnimation } from '../../components';

const Price = (props) => {
  const params = useParams();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSmall] = useGlobal('isSmall');
  const [languagesCms] = useGlobal('languagesCms');
  let query = [];
  query['deleted'] = false;
  const [user] = useGlobal('user');
  const { auth } = useSelector((state) => state.auth);

  const { stationId } = params;

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };
  let get = async (page, filters, sorter) => {
    setLoading(true);

    console.log('Burada : ');

    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    let restData = await api
      .get(`/rest/price-station/${stationId}?${qs.stringify(_params)}`, {
        _params,
      })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.map((item, key) => {
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);

    setLoading(false);
  };

  useEffect(() => {
    get();
  }, []);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/prices/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };

  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/prices/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  function turkishSort(a, b) {
    var alfabe =
      'AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789';
    if (a.length === 0 || b.length === 0) {
      return a.length - b.length;
    }
    for (var i = 0; i < a.length && i < b.length; i++) {
      var ai = alfabe.indexOf(a[i]);
      var bi = alfabe.indexOf(b[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }

  let columns = [
    {
      title: languagesCms.NAME,
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.POWER,
      dataIndex: 'power',
      key: 'power',
      align: 'center',
      sorter: (a, b) => a.power - b.power,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (text ? text + ' kW' : ''),
    },

    {
      title: languagesCms.PRICE,
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      sorter: (a, b) => turkishSort(a.price, b.price),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => record && record.price + ' TL / kWh',
    },
    {
      title: 'Hizmet Bedeli',
      align: 'center',
      dataIndex: 'serviceFee',
      key: 'serviceFee',
      render: (text) => <span className="font-bold">{text ? text + ' %' : '0 %'} </span>,
    },
    {
      title: languagesCms.TYPE,
      width: '50px',
      dataIndex: 'type',
      align: 'center',
      key: 'type',
      sorter: (a, b) => a.type - b.type,
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'center',
      width: 150,
      render: (text, record) => (
        <>
          {!(
            auth &&
            auth
              .find((a) => a.number === user.role)
              .restraintList.includes('edit')
          ) && (
            <Link to={`/prices/${stationId}/edit/${record._id}`}>
              <Button
                size="medium"
                className="w-100 mb-1"
                icon={<EditOutlined />}
              >
                {!isSmall && languagesCms.EDIT}
              </Button>
            </Link>
          )}
          <Link to={`/price-history/${stationId}/${record._id}`}>
            <Button
              size="medium"
              danger
              className="w-100 mb-1"
              icon={<EditOutlined />}
            >
              {!isSmall && 'Fiyat Geçmişi'}
            </Button>
          </Link>
          {!(
            auth &&
            auth
              .find((a) => a.number === user.role)
              .restraintList.includes('delete')
          ) && (
            <Popconfirm
              onConfirm={() => deleteRow(record._id)}
              title={languagesCms.CHECK_DELETE}
              okText={languagesCms.OK_TEXT}
              cancelText={languagesCms.CANCEL_TEXT}
            >
              <Button
                type="danger"
                size="medium"
                className="w-100"
                icon={<DeleteOutlined />}
              >
                {!isSmall && languagesCms.DELETE}
              </Button>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            İstasyon Tarifeleri
          </h1>
        </div>
        <div className="list-buttons">
          {!isSmall &&
            !(
              auth &&
              auth
                .find((a) => a.number === user.role)
                .restraintList.includes('delete')
            ) && (
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                size="large"
                onClick={deleteSelectedRows}
                style={{
                  marginRight: '10px',
                  fontSize: '14px',
                  height: '100%',
                }}
              >
                {languagesCms.DELETE_SELECTED}
              </Button>
            )}

          {!(
            auth &&
            auth
              .find((a) => a.number === user.role)
              .restraintList.includes('add')
          ) && (
            <Link
              to={`/prices/${stationId}/add`}
              style={{ marginRight: '5px' }}
            >
              <Button
                type="light"
                icon={<PlusOutlined />}
                size={'large'}
                style={{ fontSize: '14px', height: '100% ' }}
              >
                {!isSmall && 'Tarife Ekle'}
              </Button>
            </Link>
          )}
          <Link to={`/prices`} style={{ marginRight: '5px' }}>
            <Button
              type="light"
              icon={<LeftOutlined />}
              size={'large'}
              style={{ fontSize: '14px', height: '100% ' }}
            >
              {!isSmall && 'Geri'}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          size="medium"
          loading={{
            spinning: loading,
            indicator: <LoadingAnimation />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default Price;
