import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { Table, Space, Button, Input } from 'antd';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { Loading3QuartersOutlined } from '@ant-design/icons';

const UserChargeInformation = (props) => {
  const { Search } = Input;
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  let [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSmall] = useGlobal('isSmall');
  const [languagesCms] = useGlobal('languagesCms');
  const [modules] = useGlobal('modules');
  const path = props.location.pathname.split('/')[1];
  const [module] = modules.filter((el) => el._id === path);
  let query = [];
  query['deleted'] = false;

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name, lastname, email, phone';
    }

    let restData = await api
      .get(`/rest/attendees?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setTotalCount(data.result.total);
          return data.result.map((item, key) => {
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
    setLoading(false);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  function turkishSort(a, b) {
    var alfabe =
      'AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789';
    if (a.length === 0 || b.length === 0) {
      return a.length - b.length;
    }
    for (var i = 0; i < a.length && i < b.length; i++) {
      var ai = alfabe.indexOf(a[i]);
      var bi = alfabe.indexOf(b[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }

  let columns = [
    {
      title: languagesCms.NAME,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => turkishSort(a.name, b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.LASTNAME,
      dataIndex: 'lastname',
      key: 'lastname',
      sorter: (a, b) => turkishSort(a.lastname, b.lastname),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.EMAIL,
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email - b.email,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.PHONE,
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a.phone - b.phone,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'right',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={'/user-charge-information/list/' + record._id}>
            <Button
              style={{
                backgroundColor: '#f5f7f6',
                color: '#000',
                border: 'solid 1px #00BD6D',
                borderRadius: '7px',
              }}
              icon={<i className="fa-solid fa-clipboard-list"></i>}
            >
              {!isSmall && 'Şarj Geçmişi'}
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}{' '}
          </h1>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder="Arama..."
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default UserChargeInformation;
