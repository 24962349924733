import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Popconfirm, message } from 'antd';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

const ServiceProviders = (props) => {
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let [module] = modules.filter((el) => el._id === path);
  let [user] = useGlobal('user');
  const { auth } = useSelector((state) => state.auth);

  let query = [];
  query['deleted'] = "false";
  query['$or'] = [
    { role: 21 },
    { role: 22 },
    { role: 23 },
    { role: 24 },
    { role: 25 },
  ];

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    setLoading(true);
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    await api
      .get(`/rest/attendees/list?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result && data.result.rows && data.result.rows.length > 0) {
          setTotalCount(data.result.total);
          setData(data.result.rows);
        }
      })
      .catch((err) => {
        message.error('Kullanıcı Verileri Getiriemedi');
      });
    setLoading(false);
  };
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  let deleteRow = async (item_id) => {
    api.delete(`/rest/attendees/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };

  function turkishSort(a, b) {
    var alfabe =
      'AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789';
    if (a.length === 0 || b.length === 0) {
      return a.length - b.length;
    }
    for (var i = 0; i < a.length && i < b.length; i++) {
      var ai = alfabe.indexOf(a[i]);
      var bi = alfabe.indexOf(b[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }

  let columns = [
    {
      title: 'Unvan',
      dataIndex: 'superscription',
      key: 'superscription',
      sorter: (a, b) => turkishSort(a.superscription, b.superscription),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.EMAIL,
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email - b.email,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.PHONE,
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a.phone - b.phone,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'center',
      width: 150,

      render: (text, record) => (
        <Space size="small">
          {!(
            auth &&
            auth
              .find((a) => a.number === user.role)
              .restraintList.includes('edit')
          ) && (
            <Link to={'/service-providers/edit/' + record._id}>
              <Button icon={<EditOutlined />}>
                {!isSmall && languagesCms.EDIT}
              </Button>
            </Link>
          )}
          {!(
            auth &&
            auth
              .find((a) => a.number === user.role)
              .restraintList.includes('delete')
          ) && (
            <Popconfirm
              onConfirm={() => deleteRow(record._id)}
              title={languagesCms.CHECK_DELETE}
              okText={languagesCms.OK_TEXT}
              cancelText={languagesCms.CANCEL_TEXT}
            >
              <Button type="danger" icon={<DeleteOutlined />}>
                {!isSmall && languagesCms.DELETE}
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}{' '}
          </h1>
        </div>
        <div className="list-buttons">
          {!(
            auth &&
            auth
              .find((a) => a.number === user.role)
              .restraintList.includes('add')
          ) && (
            <Link to="/service-providers/add" style={{ marginRight: '5px' }}>
              <Button
                type="light"
                style={{ fontSize: '14px', height: '100%' }}
                icon={<PlusOutlined />}
                size="large"
              >
                {!isSmall && 'Servis Sağlayıcı Ekle'}
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default ServiceProviders;
