import React, { useEffect, useState, useGlobal } from 'reactn';
import { useRef } from 'react';
import { Table, Button, Space, Input } from 'antd';
import api from '../service/index';
import { useParams, useHistory } from 'react-router-dom';
import qs from 'qs';
import {
  Loading3QuartersOutlined,
  LeftOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { HistoryModal } from '../components';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import MeterValue from '../components/charts/meterValue';
import ChargeReportModal from '../components/reports/ChargeReportModal';

const HistoryView = (props) => {
  let params = useParams();
  let history = useHistory();
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let stationId = params.stationId || null;
  let uniqueId = params.uniqueId || null;
  let connectorId = params.connectorId || null;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  let query = [];
  if (stationId) {
    query['stationId'] = stationId;
  }
  if (uniqueId) {
    query['unique_id'] = uniqueId;
  }
  if (connectorId) {
    query['connector_id'] = connectorId;
  }

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = '-createdAt';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }
    let _params = { sort: shortString, ...page, query };
    let restData = await api
      .get(`/rest/chargeHistory?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data && data.result) {
          setTotalCount(data && data.result && data.result.total);
          return data.result.rows.map((item, key) => {
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
    setLoading(false);
  };
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Ara
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Temizle
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrele
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    // onFilterDropdownOpenChange: (visible) => {
    //   if (visible) {
    //     setTimeout(() => searchInput.current?.select(), 100);
    //   }
    // },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  let columns = [
    {
      title: 'Son Kullanıcı ( Sürücü )',
      dataIndex: 'userName',
      align: 'center',
      key: 'userName',
      sorter: (a, b) => a.userName - b.userName,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('userName'),
      render: (text, record) => (record.userName ? record.userName : ''),
    },
    {
      title: 'Şarj İstasyonu',
      dataIndex: 'stationName',
      align: 'center',
      key: 'stationName',
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (record.stationName ? record.stationName : ''),
      ...getColumnSearchProps('stationName'),
    },
    {
      title: 'Tesis',
      dataIndex: 'dealerName',
      align: 'center',
      key: 'dealerName',
      sorter: (a, b) => a.dealerName - b.dealerName,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('dealerName'),
    },
    {
      title: 'Cihaz ID',
      dataIndex: 'unique_id',
      align: 'center',
      key: 'unique_id',
      sorter: (a, b) => a.unique_id - b.unique_id,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('unique_id'),
    },
    {
      title: languagesCms.CONNECTOR_COUNT,
      dataIndex: 'connector_id',
      align: 'center',
      key: 'connector_id',
      sorter: (a, b) => a.connector_id - b.connector_id,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('connector_id'),
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      align: 'center',
      key: 'transactionId',
      sorter: (a, b) => a.transactionId - b.transactionId,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('transactionId'),
    },
    {
      title: languagesCms.DATE,
      dataIndex: 'createdAt',
      align: 'center',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('createdAt'),
      render: (text, record) =>
        moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'center',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <MeterValue chargeId={record.chargeId} />
          <Button
            style={{
              borderRadius: '9px',
              padding: '0 15px',
              backgroundColor: '#f5f7f6',
              color: '#000',
              border: 'solid 1px #00BD6D',
            }}
            onClick={() => {
              setModalData(record);
              setIsModalVisible(true);
            }}
          >
            Log
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {languagesCms.CHARGING_HISTORY_REPORT}
          </h1>
        </div>
        <div className="list-buttons">
          <ChargeReportModal stationId={params.stationId} />
          <Button
            type="light"
            onClick={() => history.goBack()}
            icon={<LeftOutlined />}
            style={{ marginLeft: '5px' }}
            size={'large'}
          >
            {!isSmall && languagesCms.BACK}
          </Button>
        </div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
      {isModalVisible && (
        <>
          <HistoryModal
            setIsModalVisible={setIsModalVisible}
            isModalVisible={isModalVisible}
            languagesCms={languagesCms}
            record={modalData}
          />
        </>
      )}
    </div>
  );
};

export default HistoryView;
