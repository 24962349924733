import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Button, message, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import api from '../../../service';
import moment from 'moment';

const HistoryCall = () => {
  const params = useParams();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const get = () => {
    setLoading(true);
    api
      .get('/rest/contact-us/history-calls/' + params.company)
      .then(({ data: { result, result_message } }) => {
        if (result) {
          setData(result);
          setTotalCount(result && result.length && result.length);
        } else message.error('Arama Bilgisi Alınamadı');
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setLoading(false);
  };

  useEffect(() => {
    get();
  }, []);

  const columns = [
    {
      title: 'Arayan',
      dataIndex: 'callingUser',
      key: 'callingUser',
      align: 'center',
      responsive: ['xs', 'sm', 'md'],
    },
    {
      title: 'Aranan',
      dataIndex: 'searchingUser',
      key: 'searchingUser',
      align: 'center',
      responsive: ['xs', 'sm', 'md'],
    },
    {
      title: 'Durum',
      dataIndex: 'durum',
      key: 'durum',
      align: 'center',
      responsive: ['xs', 'sm', 'md'],
      render: (r) =>
        r === 'ANSWERED' ? (
          <span style={{ color: 'green', fontWeight: 'bold' }}>Cevaplandı</span>
        ) : r === 'NO ANSWER' ? (
          <span style={{ color: 'red', fontWeight: 'bold' }}>Cevaplanmadı</span>
        ) : (
          '-'
        ),
    },
    {
      title: 'Süre ( Saniye )',
      dataIndex: 'sure',
      key: 'sure',
      align: 'center',
      responsive: ['xs', 'sm', 'md'],
    },
    {
      title: 'Arama Tipi',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      responsive: ['xs', 'sm', 'md'],
      render: (r) =>
        r === 'incomingCall' ? (
          <span style={{ color: '#FFB84C', fontWeight: 'bold' }}>
            Gelen Arama Bilgi
          </span>
        ) : r === 'callResult' ? (
          <span style={{ color: 'green', fontWeight: 'bold' }}>
            Tamamlanan Çağrı Bilgi
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'Tarih',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      responsive: ['xs', 'sm', 'md'],
      render: (r) => moment(r).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Aksiyonlar',
      dataIndex: 'action',
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <Space size="small">
          <Link
            to={`/contact-us/history-calls/${params.company}/${record._id}`}
          >
            <Button
              style={{
                backgroundColor: '#f5f7f6',
                color: '#000',
                border: 'solid 1px #00BD6D',
                borderRadius: '7px',
              }}
              icon={<i className="fa-solid fa-phone-volume"></i>}
            >
              Detay
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            Geçmiş Çağrılar
          </h1>
        </div>
        <div className="list-buttons"></div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default HistoryCall;
