import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm } from 'antd';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

const Rfid = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  const [module] = modules.filter((el) => el._id === path);
  let query = [];

  const [user] = useGlobal('user');
  const { auth } = useSelector((state) => state.auth);

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'rfid, carPlate';
    }

    let restData = await api
      .get(`/rest/rfid?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return (
            data &&
            data.result &&
            data.result.rows.map((item, key) => {
              item.key = key;
              return item;
            })
          );
        }
      });
    setData(restData);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let deleteRow = async (item_id) => {
    api.delete(`/rest/rfid/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  const columns = [
    {
      title: 'Rfid Numarası',
      dataIndex: 'rfid',
      key: 'rfid',
      width: '18%',
      align: 'center',
    },
    {
      title: 'Kullanıcı',
      dataIndex: 'userName',
      key: 'userName',
      width: '18%',
      align: 'center',
    },
    {
      title: 'Durumu',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '18%',
      render: (text) =>
        text === 'active' ? (
          <span style={{ color: 'green', fontWeight: 'bold' }}>Aktif</span>
        ) : (
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            {text === 'passive' ? 'Pasif' : text}
          </span>
        ),
    },
    {
      title: 'Plaka',
      dataIndex: 'carPlate',
      key: 'carPlate',
      width: '18%',
      align: 'center',
      ellipsis: true,
      render: (text) => (text ? text && text.replaceAll('-', ' ') : ''),
    },
    {
      title: 'Aksiyonlar',
      dataIndex: 'description',
      key: 'description',
      width: '28%',
      align: 'center',
      render: (text, record) => (
        <>
          <Space size="small">
            <Link to={'/rfid-management/edit/' + record._id}>
              <Button size="medium" icon={<EditOutlined />}>
                {!isSmall && languagesCms.EDIT}
              </Button>
            </Link>
            <Popconfirm
              onConfirm={() => deleteRow(record._id)}
              title={languagesCms.CHECK_DELETE}
              okText={languagesCms.OK_TEXT}
              cancelText={languagesCms.CANCEL_TEXT}
              className=""
            >
              <Button type="danger" size="medium" icon={<DeleteOutlined />}>
                {languagesCms.DELETE}
              </Button>
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}
          </h1>
        </div>
        <div className="list-buttons">
          {!(
            auth &&
            auth
              .find((a) => a.number === user.role)
              .restraintList.includes('delete')
          ) && (
            <Link to="/rfid-management/add" style={{ marginRight: '5px' }}>
              <Button
                type="light"
                icon={<PlusOutlined />}
                size={'large'}
                style={{ fontSize: '14px', height: '100% ' }}
              >
                {!isSmall && 'Rfid Ekle'}
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder="Arama... ( RFID Numarası, Araç Plaka ile arama yapılabilir. Örnek Plaka Formatı 34-aa-34 )"
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default Rfid;
