import React, { useState, useEffect } from 'react';
import { Row, Col, Space, Card, Statistic } from 'antd';
import { Link } from 'react-router-dom';
import api from '../service/index';

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const get = async (page, filters, sorter) => {
    await api.get('/rest/dashboard').then(({ data }) => {
      if (data && data.result) {
        setData(data.result);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <div style={{ marginTop: '4.1rem', marginLeft: '1.5rem' }}>
      <div className="table-wrap">
        <Row direction="row" style={{ fontFamily: 'Ariel', fontWeight: '700' }}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Link to="/active-devices">
              <Card
                loading={loading}
                style={{
                  width: '100%',
                  maxHeight: '245px',
                  borderRadius: '11px',
                  textAlign: 'center',
                }}
              >
                <h6>{'Aktif Cihazlar'}</h6>
                <Space
                  wrap
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '1rem',
                  }}
                >
                  <Statistic
                    value={data && data.activeDevice}
                    valueStyle={{
                      color:
                        data && data.activeDevice > 0 ? '#3f8600' : '#f5222d',
                      fontWeight: 'medium',
                      fontSize: '3.9rem',
                    }}
                    prefix={<i className="fa-solid fa-arrow-up-wide-short"></i>}
                  />
                </Space>
              </Card>
            </Link>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Link to="/active-charge">
              <Card
                loading={loading}
                style={{
                  width: '100%',
                  maxHeight: '245px',
                  borderRadius: '11px',
                  textAlign: 'center',
                }}
              >
                <h6>Aktif Şarj</h6>
                <Space
                  wrap
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '1rem',
                  }}
                >
                  <Statistic
                    value={data && data.activeCharge}
                    valueStyle={{
                      color:
                        data && data.activeCharge > 0 ? '#3f8600' : '#f5222d',
                      fontWeight: 'medium',
                      fontSize: '3.9rem',
                    }}
                    prefix={<i className="fa-solid fa-arrow-up-wide-short"></i>}
                  />
                </Space>
              </Card>
            </Link>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8 }}
            style={{ marginBottom: '5px' }}
          >
            <Card
              loading={loading}
              style={{
                width: '100%',
                maxHeight: '265px',
                borderRadius: '11px',
                textAlign: 'center',
              }}
            >
              <h6 style={{ fontWeight: '500' }}>Toplam Kullanıcı Sayısı</h6>
              <Space
                wrap
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '1rem',
                }}
              >
                <Statistic
                  value={data && data.user && data.user.totalUser}
                  valueStyle={{
                    color:
                      data && data.user && data.user.totalUser > 0
                        ? '#3f8600'
                        : '#f5222d',
                    fontWeight: 'medium',
                    fontSize: '3.9rem',
                  }}
                  prefix={<i className="fa-solid fa-arrow-up-wide-short"></i>}
                />
              </Space>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
