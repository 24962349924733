import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import api from '../../service';
import qs from 'qs';

const DevicePicker = (props) => {
  const { record, setRecord, name, mode, disabled, text } = props;
  const [device, setDevice] = useState([]);

  const _params = {
    sort: '-createdAt',
    page: 1,
    pageSize: 100000,
    query: {
      stationId: record && record.stationId,
    },
  };

  useEffect(() => {

    console.log(record)

    if (record && record.stationId) {
      api
        .get(`/rest/chargepoints/list?${qs.stringify(_params)}`)
        .then(async ({ data }) => {
          if (data && data.result) {
            const newData =
              (await data) &&
              data.result.map((item) => {
                return {
                  label: `${item.name}`,
                  value: item.unique_id,
                };
              });

            const dbDevices = newData.sort((a, b) => {
              return a.value.localeCompare(b.value);
            });

            setDevice(dbDevices);
          }
        });
    }
  }, [record]);


  const defaultValues =
    device && device.filter((d) => record && record[name].includes(d.value));

  return (
    <Select
      defaultValue={defaultValues}
      value={defaultValues}
      isMulti={mode === 'multiple' ? true : false}
      name={name}
      options={device}
      disabled={disabled}
      className="basic-multi-select"
      placeholder={text ? text : ''}
      classNamePrefix="select"
      onChange={(selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setRecord((prevRecord) => ({ ...prevRecord, [name]: selectedValues }));
      }}
    />
  );
};

export default DevicePicker;
