import React, { useState } from 'react';
import { Upload, message, Progress, Button } from 'antd';
import {
  PlusOutlined,
  LoadingOutlined,
  FilePdfOutlined,
  DeleteOutlined,
  EyeOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import api from '../../service/index';

const UploadFile = (props) => {
  let { record, setRecord, name, disabled, resize } = props;
  let [, setFile] = useState([]);
  let [imageUrl, setImageUrl] = useState(false);
  let [setPdfUrl] = useState(false);
  let [loading, setLoading] = useState(false);
  let [uploadStart, setUploadStart] = useState(false);
  let languagesCms = props.languagesCms;
  const [progress, setProgress] = useState(0);
  let [set_Download] = useState(false);

  let handleChange = (info) => {
    if (!info.event && uploadStart === false) {
      setUploadStart(true);
      const formData = new FormData();

      formData.append('files', info.file.originFileObj);
      formData.append('key', name);
      if (info.file.originFileObj.size > 56844918)
        message.warning(languagesCms.WARNING_FILE_SIZE);
      else {
        formData.append('resize', resize);
        api
          .post('/rest/upload-file-group', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (event) => {
              const percent = Math.floor((event.loaded / event.total) * 100);
              setProgress(percent - 1);
            },
          })
          .then(({ data: { result, result_message } }) => {
            set_Download(result[0] ? result[0] : null);
            setTimeout(() => setProgress(100), 1000);
            setTimeout(() => setProgress(0), 2000);
            if (result_message.type === 'success') {
              setPdfUrl(result[0].type === 'document' ? result[0].url : null);
              if (result[0].duration) {
                setTimeout(() => {
                  setImageUrl(
                    result[0].type === 'video'
                      ? result[0].thumb
                      : result[0].url,
                  );
                }, 1000);
              } else setImageUrl(result[0] ? result[0].thumb : result[0].url);
              setFile(result[0]);
              setRecord(result[0]);
              setUploadStart(false);
            }
            setLoading(false);
          });
      }
    }
  };

  let beforeUpload = () => {
    setImageUrl(false);
    setPdfUrl(false);
    setLoading(true);
  };
  let remove = () => {
    setFile([]);
    setImageUrl(false);
    setRecord({ ...record, [name]: null });
  };

  const updateFirmware = async (chargeBoxId, location) => {
    await api
      .post('/rest/update-firmware', {
        chargeBoxId,
        location,
      })
      .then(({ data: { result, result_message } }) => {
        if (result) message.success('Güncelleme Başlatıldı');
        else message.error('Güncelleme Sırasında Hata Çıktı !');
      })
      .catch((err) => message.error('Güncelleme Sırasında Hata Çıktı ! ', err));
  };

  const uploadButton = (
    <div
      icon={
        loading ? (
          <LoadingOutlined onClick={() => handleChange()} />
        ) : (
          <PlusOutlined />
        )
      }
    >
      {' Upload'}
    </div>
  );

  return (
    <>
      <Upload
        name={name}
        listType="picture-card"
        icon={<UploadOutlined />}
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        disabled={disabled}
      >
        {imageUrl ? (
          imageUrl.endsWith('.pdf') ? (
            <>
              {' '}
              <FilePdfOutlined size="large" />{' '}
            </>
          ) : (
            <div>
              <i
                style={{ fontSize: 45 }}
                className="fa-solid fa-folder-open"
              ></i>
            </div>
          )
        ) : (
          uploadButton
        )}
      </Upload>
      {imageUrl ? (
        <div>
          <div>
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => remove()}
              style={{ marginRight: '3px' }}
            ></Button>
            <a target="_blank" rel="noopener noreferrer" href={imageUrl}>
              <Button
                icon={<EyeOutlined />}
                size="medium"
                style={{ marginRight: '3px' }}
                type="primary"
              ></Button>
            </a>
            <Button
              type="primary"
              icon={<UploadOutlined />}
              onClick={() => updateFirmware(record.fileFolder, record.url)}
              size="medium"
              style={{ margin: 'auto' }}
            />
          </div>
        </div>
      ) : null}
      {progress > 0 ? (
        <Progress style={{ width: '60%' }} percent={progress} />
      ) : null}
    </>
  );
};

export default React.memo(UploadFile);
