import React, { useState } from 'react';
import api from '../../service/index';
import { Button, message, Card, Form, Row, Col, Input } from 'antd';

const decryptText = ({ setDecrypt }) => {
  const [loading, setLoading] = useState(false);

  // data çözümlemek için input içerik tutma
  const [decryptData, setDecryptData] = useState([]);

  // çözülen datayı göstermek için
  const [decryptText, setDecryptText] = useState([]);

  // mesaj çözümleme fonksiyonu
  const decryptFunction = async () => {
    setLoading(true);

    if (decryptData === [] || !decryptData.encryptText || !decryptData) {
      message.error("Lütfen Çözülmesini istediğiniz Key Girin !")
      setLoading(false);
      return false
    }

    await api
      .post('/rest/decrypt', decryptData)
      .then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          setDecryptText(result);
          message.success('Şifre Başarıyla Çözüldü', 2);
        } else {
          message.error(result_message.message, 2);
        }
      });
    setLoading(false);
  };

  return (
    <div>
      <Card
        style={{ height: '265px', maxHeight: '300px' }}
        title={'QR Çözümle'}
        loading={loading}
      >
        <Form
          layout="vertical"
          className="w-100"
          name="basic"
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={decryptFunction}
          autoComplete="on"
          size="large"
        >
          <Row direction="row">
            <Col span={24} md={24} className="gutter-row">
              <Form.Item
                label="Şifreli Cihaz Bilgi Metni"
                name="encryptText"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen İçerik Giriniz !',
                  },
                ]}
              >
                <Input
                  name="encryptText"
                  value={decryptData && decryptData.encryptText}
                  onChange={(e) =>
                    setDecryptData({
                      encryptText: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          {decryptText && decryptText.unique_id && decryptText.connector_id && (
            <>
              <Row direction="row">
                <div
                  style={{
                    textAlign: 'center',
                    margin: 'auto',
                    fontSize: '1rem',
                    padding: '.7rem',
                  }}
                >
                  <span style={{ textAlign: 'center' }}>
                    <b>Cihaz Id : </b>
                    {decryptText.unique_id}
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style={{ textAlign: 'center' }}>
                    <b>Konnektör Id : </b>
                    {decryptText.connector_id}
                  </span>
                </div>
              </Row>
            </>
          )}

          <Row justify="space-between">
            <Col span={3}>
              {' '}
              <Button
                style={{ borderRadius: '7px' }}
                type="danger"
                className='customize-botton'
                onClick={decryptFunction}
              >
                Çözümle
              </Button>
            </Col>
            <Col span={3}>
              {' '}
              <Button
                style={{ borderRadius: '7px' }}
                type="danger"
                onClick={() => setDecrypt(false)}
              >
                Vazgeç
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default React.memo(decryptText);
