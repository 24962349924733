import React, { useEffect, useState, useCallback } from 'react';
import { useGlobal } from 'reactn';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Form,
  Row,
  Col,
  message,
  Input,
  Select,
  Modal,
} from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import 'react-phone-input-2/lib/style.css';
import {
  FileInput,
  ContactUsRecordStepOne,
  ContactUsHistoryModal,
  CommentArea,
  ServiceProvidesPicker,
} from '../../components';
import { ExclamationCircleFilled } from '@ant-design/icons';

const { confirm } = Modal;
const showCloseConfirm = (setConfirm) => {
  confirm({
    title: 'Form Verileri Temizlensin Mi?',
    icon: <ExclamationCircleFilled />,
    content: '',
    okText: 'Evet',
    okType: 'danger',
    cancelText: 'Hayır',
    onOk() {
      setConfirm(true);
    },
    onCancel() {},
  });
};

const ContactUsDetail = (props) => {
  const params = useParams();
  const history = useHistory();
  const id = params.id !== 'add' ? params.id : false;
  const recordType = window.location.pathname.split('/')[2];
  const [languagesCms] = useGlobal('languagesCms');
  const [publicId, setPublicId] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const newRecord = {
    qrCode: {
      unique_id: '',
      connector_id: '',
    },
    description: '',
    deviceMedia: [],
    status: 'open',
    responsible: '',
    userId: '',
    priority: 'medium',
  };

  const [data, setData] = useState(id ? [] : newRecord);
  const [loading, setLoading] = useState(id ? true : false);
  const [isSmall] = useGlobal('isSmall');
  const [validationCheck, setValidationCheck] = useState(false);
  const [errors, setErrors] = useState([]);
  const [refreshDeepLink, setRefreshDeepLink] = useState(false);

  const [modules] = useGlobal('modules');
  const path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }
  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/contact-us/' + id)
        .then(({ data: { result, result_message } }) => {
          if (result) {
            setData(result);
            if (!result.status) result.status = 'open';
            setLoading(false);
          } else {
            message.error(result_message.message);
            setLoading(false);
          }
        });
    }
  }, [id]);

  const validate = useCallback(() => {
    let errors = {};

    if (
      data.contactType === 'faultRecord' &&
      data.qrCode &&
      (data.qrCode.unique_id === null || data.qrCode.unique_id === undefined)
    )
      errors.unique_id = languagesCms.ENFORCED;

    if (
      data.contactType === 'faultRecord' &&
      data.qrCode &&
      (data.qrCode.connector_id === null ||
        data.qrCode.connector_id === undefined)
    )
      errors.connector_id = languagesCms.ENFORCED;

    if (
      data.status === null ||
      data.status === undefined ||
      data.status === '' ||
      ['open', 'resolved'].includes(data.status) === false
    )
      errors.status = languagesCms.ENFORCED;

    if (
      data.responsible === null ||
      data.responsible === undefined ||
      data.responsible === ''
    )
      errors.responsible = languagesCms.ENFORCED;

    if (
      data.priority === null ||
      data.priority === undefined ||
      data.priority === '' ||
      ['low', 'medium', 'high'].includes(data.priority) === false
    )
      errors.priority = languagesCms.ENFORCED;

    if (
      !data.name &&
      !data.lastname &&
      !data.email &&
      !data.phone &&
      (data.userId === null || data.userId === undefined || data.userId === '')
    )
      errors.userId = languagesCms.ENFORCED;

    if (
      data.contactType === null ||
      data.contactType === undefined ||
      data.contactType === ''
    )
      errors.contactType = languagesCms.ENFORCED;

    if (
      !data.userId &&
      (data.name === null || data.name === undefined || data.name === '')
    )
      errors.name = languagesCms.ENFORCED;

    if (
      !data.userId &&
      (data.lastname === null ||
        data.lastname === undefined ||
        data.lastname === '')
    )
      errors.lastname = languagesCms.ENFORCED;

    if (
      !data.userId &&
      (data.email === null || data.email === undefined || data.email === '')
    )
      errors.email = languagesCms.ENFORCED;

    if (
      !data.userId &&
      (data.phone === null || data.phone === undefined || data.phone === '')
    )
      errors.phone = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  const save = async () => {
    console.log('save içerisinde');
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      console.log(errors);

      if (data.phone && !data.phone.startsWith('+'))
        data.phone = '+' + data.phone;
      if (id) {
        if (data.devicePublicId) delete data.devicePublicId;
        api
          .put('/rest/contact-us/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/contact-us');
            } else message.error(result_message.message, 2);
          });
      } else {
        if (data.devicePublicId) delete data.devicePublicId;
        api
          .post('/rest/contact-us', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/contact-us');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck]);

  useEffect(() => {
    if (confirm === true && recordType === 'add') {
      api
        .delete('/rest/contact-us/' + id)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'error')
            message.error(result_message.message, 2);
          else {
            message.success('Form Verileri Temizlendi ...', 2);
            history.push('/contact-us');
          }
        })
        .catch((err) => {
          message.error(
            'Form Verileri Silinirken Hata Oluştu Lütfen Tekrar Deneyiniz !',
            2,
          );
          window.location.reload();
        });
    }
  }, [confirm]);

  // qr okutarak cihaz bilgilerini getirme
  const findDeviceByScanningQr = async () => {
    if (data && data.userId) {
      api
        .post('/rest/notification-deepLink', {
          returnId: data._id,
          userId: data.userId,
          deepLink: 'qrCode',
          description: 'Lütfen Cihazınızın QR Kodunu Okutunuz',
        })
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(
              'Bildirim Gönderildi, Kullanıcıdan Gelecek olan Bilgileri Bekleyiniz ...',
              2,
            );
            setRefreshDeepLink(true);
          } else {
            message.error(result_message.message, 2);
          }
        })
        .catch((err) => {
          message.error(err, 2);
          message.error('Bildirim Gönderilemedi !', 2);
        });
    } else {
      message.error('Bu Kayıt İçin Kullanıcı Tanımı Yapılmamıştır. !', 3);
      message.error('Bildirim Gönderemezsiniz !', 2.5);
    }
  };

  const refresh = (type) => {
    setLoading(true);
    api
      .get('/rest/contact-us/' + id)
      .then(({ data: { result, result_message } }) => {
        if (result) {
          if (type === 'qrCode') setData({ ...data, qrCode: result.qrCode });
          if (type === 'deviceMedia') setData({ ...data, deviceMedia: result.deviceMedia });
          setLoading(false);
        } else {
          message.error(result_message.message);
          setLoading(false);
        }
      });
  };

  // qr okutarak cihaz bilgilerini getirme
  const getMediaWithNotification = async () => {
    if (data && data.userId) {
      api
        .post('/rest/notification-deepLink', {
          returnId: data._id,
          userId: data.userId,
          deepLink: 'deviceMedia',
          description: 'Cihazın Fotoğrafını Çekiniz',
        })
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(
              'Bildirim Gönderildi, Kullanıcıdan Gelecek olan Medya Bilgileri Bekleyiniz ...',
              2,
            );
          } else {
            message.error(result_message.message, 2);
          }
        })
        .catch((err) => {
          message.error(err, 2);
          message.error('Bildirim Gönderilemedi !', 2);
        });
    } else {
      message.error('Bu Kayıt İçin Kullanıcı Tanımı Yapılmamıştır. !', 3);
      message.error('Bildirim Gönderemezsiniz !', 2.5);
    }
  };

  const deviceFind = async () => {
    setData({
      ...data,
      qrCode: { ...data.qrCode, unique_id: null, connector_id: null },
    });

    if (data.devicePublicId) {
      api
        .get('/rest/device-public-id/' + data.devicePublicId)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            setData({
              ...data,
              qrCode: {
                ...data.qrCode,
                unique_id: result.unique_id,
                connector_id: result.connector_id,
              },
            });
            message.success('Cihaz Bilgileri Gerekli Alanlara Eklendi ...');
          } else message.error(result_message.message, 2);
        });
    } else {
      message.error('Lütfen Cihaz Public Id Giriniz !');
    }
  };

  // useEffect(() => {
  //   window.addEventListener('beforeunload', function (e) {
  //     if (recordType === 'add') {
  //       api
  //         .delete('/rest/contact-us/' + id)
  //         .then(({ data: { result, result_message } }) => {
  //           if (result_message.type === 'error')
  //             message.error(result_message.message, 2);
  //           else {
  //             message.success('Form Verileri Temizlendi ...', 2);
  //             history.push('/contact-us');
  //           }
  //         })
  //         .catch((err) => {
  //           message.error(
  //             'Form Verileri Silinirken Hata Oluştu Lütfen Tekrar Deneyiniz !',
  //             2,
  //           );
  //           window.location.reload();
  //         });
  //     }
  //   });
  // }, []);

  console.log(data);
  console.log(errors);

  return (
    <div>
      <>
        <div className="list-head">
          <div className="list-title">
            <h1>{module ? module.name : ''}</h1>
          </div>
          <div className="list-buttons">
            <div className="me-2">
              {recordType === 'edit' && (
                <ContactUsHistoryModal recordId={data._id} />
              )}
            </div>

            <Button
              type="light"
              icon={<LeftOutlined />}
              onClick={() =>
                recordType === 'add'
                  ? showCloseConfirm(setConfirm)
                  : history.push('/contact-us')
              }
              size={'large'}
            >
              {!isSmall && languagesCms.BACK}
            </Button>
          </div>
        </div>

        <div className="form-wrap">
          <Card
            title={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span>Bize Ulaşın</span>
                <div>
                  <span className="ms-3">{data.name}</span>
                  <span className="ms-3">{data.lastname}</span>
                  <span className="ms-3">{data.email}</span>
                  <span className="ms-3 me-1">{data.phone}</span>
                </div>
              </div>
            }
            loading={loading}
          >
            <Form layout="horizontal" size={'large'} onFinish={save}>
              {recordType !== 'edit' && (
                <>
                  <div
                    style={{
                      overflowX: 'hidden',
                    }}
                  >
                    <ContactUsRecordStepOne
                      record={data}
                      setRecord={setData}
                      errors={errors}
                    />
                  </div>
                </>
              )}

              <Row direction="row">
                {data && data.contactType === 'faultRecord' && (
                  <>
                    <h4
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        marginBottom: '20px',
                        marginTop: '20px',
                      }}
                    >
                      <i className="fa-solid fa-fingerprint">
                        {' '}
                        &nbsp; Cihaz Bul
                      </i>
                    </h4>
                    <div className="d-grid gap-2 d-md-block text-center mx-auto mb-3 mt-3">
                      <button
                        type="button"
                        onClick={() => {
                          setPublicId(publicId === false ? true : false);
                        }}
                        className={`btn btn-outline-danger btn-sm me-1 ${
                          data &&
                          data.qrCode &&
                          data.qrCode.unique_id !== '' &&
                          data.qrCode.connector_id !== ''
                            ? ' disabled'
                            : ''
                        }`}
                      >
                        Public İd ile Cihaz Bul
                      </button>{' '}
                      <button
                        type="button"
                        onClick={() => findDeviceByScanningQr()}
                        className={`btn btn-outline-danger btn-sm me-1 ${
                          data &&
                          data.qrCode &&
                          data.qrCode.unique_id !== '' &&
                          data.qrCode.connector_id !== ''
                            ? ' disabled'
                            : ''
                        }`}
                      >
                        Bildirim'den QR Okuma İle Cihaz Bul
                      </button>
                      {refreshDeepLink && (
                        <>
                          <button
                            type="button"
                            className="btn btn-outline-danger btn-sm"
                            onClick={() => refresh('qrCode')}
                          >
                            <i className="fa-solid fa-rotate"></i>
                          </button>
                        </>
                      )}
                    </div>
                  </>
                )}

                {publicId && (
                  <>
                    <Col xs={{ span: 24 }} md={{ span: 20 }}>
                      <Form.Item
                        label={'Cihaz Public Id'}
                        help={errors.devicePublicId}
                        validateStatus={
                          errors.devicePublicId ? 'error' : 'success'
                        }
                      >
                        <Input
                          name="devicePublicId"
                          value={data.devicePublicId}
                          onChange={(e) => {
                            setData({
                              ...data,
                              devicePublicId: e.target.value,
                            });
                          }}
                        />
                        <small>
                          Buradan Cihaz Public Id Yardımıyla Cihaz Bilgilerine
                          Erişim Sağlanabilir ...
                        </small>
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 4 }}>
                      <button
                        className="btn btn-outline-danger"
                        type="button"
                        onClick={deviceFind}
                      >
                        Cihaz Bul
                      </button>
                    </Col>
                  </>
                )}
                <Row direction="row">
                  {data && data.contactType === 'faultRecord' && (
                    <>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item
                          label={'Cihaz Id'}
                          required
                          help={errors.unique_id}
                          validateStatus={
                            errors.unique_id ? 'error' : 'success'
                          }
                        >
                          <Input
                            name="unique_id"
                            value={data && data.qrCode && data.qrCode.unique_id}
                            disabled
                            onChange={(e) =>
                              setData({
                                ...data,
                                qrCode: {
                                  ...data.qrCode,
                                  unique_id: e.target.value,
                                },
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item
                          label={'Konnektör Id'}
                          required
                          help={errors.connector_id}
                          validateStatus={
                            errors.connector_id ? 'error' : 'success'
                          }
                        >
                          <Input
                            name="connector_id"
                            disabled
                            value={
                              data && data.qrCode && data.qrCode.connector_id
                            }
                            onChange={(e) =>
                              setData({
                                ...data,
                                qrCode: {
                                  ...data.qrCode,
                                  connector_id: e.target.value,
                                },
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}

                  {recordType === 'edit' && (
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label={'Durum'}
                        labelCol={{ span: 24 }}
                        required
                        help={errors.status}
                        validateStatus={errors.status ? 'error' : 'success'}
                      >
                        <Select
                          name="status"
                          defaultValue={data.status}
                          onChange={(e) => setData({ ...data, status: e })}
                        >
                          <Select.Option value={'open'}>Aktif</Select.Option>
                          <Select.Option value={'resolved'}>
                            Çözüldü
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label={'Öncelik'}
                      labelCol={{ span: 24 }}
                      required
                      help={errors.priority}
                      validateStatus={errors.priority ? 'error' : 'success'}
                    >
                      <Select
                        name="priority"
                        defaultValue={data.priority}
                        onChange={(e) => setData({ ...data, priority: e })}
                      >
                        <Select.Option value={'low'}>Düşük</Select.Option>
                        <Select.Option value={'medium'}>Orta</Select.Option>
                        <Select.Option value={'high'}>Yüksek</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label={'Sorumlu'}
                      labelCol={{ span: 24 }}
                      required
                      help={errors.responsible}
                      validateStatus={errors.responsible ? 'error' : 'success'}
                    >
                      <ServiceProvidesPicker
                        record={data}
                        setRecord={setData}
                        name="responsible"
                        languagesCms={languagesCms}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    {recordType === 'edit' && <CommentArea id={data._id} />}
                    <Form.Item
                      label={'Açıklama'}
                      labelCol={{ span: 24 }}
                      required
                      help={errors.description}
                      validateStatus={errors.description ? 'error' : 'success'}
                    >
                      <Input.TextArea
                        name="description"
                        rows={4}
                        value={data.description}
                        onChange={(e) =>
                          setData({
                            ...data,
                            description: e.target.value,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item label={'Fotoğraflar'} labelCol={{ span: 24 }}>
                      <FileInput
                        languagesCms={languagesCms}
                        name="deviceMedia"
                        title="Medyalar"
                        type="file"
                        multi={false}
                        ext={['image']}
                        record={data}
                        setRecord={setData}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item>
                      <button
                        type="button"
                        onClick={() => getMediaWithNotification()}
                        className="btn btn-outline-danger btn-sm"
                      >
                        Bildirim İle Fotoğraf Al
                      </button>
                    </Form.Item>
                  </Col>
                </Row>
              </Row>
              <Row direction="row">
                <Col span={24}>
                  <Form.Item>
                    <Button
                      type="primary"
                      disabled={loading}
                      htmlType="submit"
                      size="large"
                      block
                    >
                      {' '}
                      {languagesCms.SAVE}{' '}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </>
    </div>
  );
};
export default ContactUsDetail;
