import React from 'react';
import { useGlobal } from 'reactn';
import { Button } from 'antd';
import api from '../service';
import qs from 'qs';
import { saveAs } from 'file-saver';


const GetReports = ({ fileName, url, query, className, buttonName, size }) => {

  const [isSmall] = useGlobal('isSmall');
  const [languagesCms] = useGlobal('languagesCms');

  console.log("query ", query)

  const getReport = async () => {
    const reportUrl = url;
    try {
      const options = {
        method: 'GET',
        decompress: true,
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

        },
      };
      const result = await api.get(`${reportUrl}?${qs.stringify(query)}`, {
        ...options,
      });

      const blob = new Blob([result.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      saveAs(blob, `${fileName}.xlsx`);
    } catch (error) {
      console.error('Rapor indirme hatası:', error);
    }
  };
  return (
    <div className="w-100">
      {' '}
      <Button
        type="light"
        className={`me-3 ${className} `}
        onClick={() => {
          getReport();
        }}
        icon={<i className="fa-regular fa-file-excel"></i>}
        size={ size || 'large'}
      >
        {!isSmall && `${buttonName || languagesCms.REPORT}`}
      </Button>
    </div>
  );
};

export default React.memo(GetReports);
