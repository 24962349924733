import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Breadcrumb } from 'antd';
import api from '../service/index';
import { Link, useParams } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  Loading3QuartersOutlined,
  LeftOutlined,
} from '@ant-design/icons';

const HistoryConnector = (props) => {
  const { Search } = Input;
  let params = useParams();
  let [data, setData] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let stationId = params.stationId || false;
  let uniqueId = params.uniqueId || false;

  let query = [];
  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'unique_id,name,chargepointStatus';
    }

    let restData = await api
      .get(`/rest/connectors/${uniqueId}?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          return data.result[0].connector.map((item, key) => {
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let columns = [
    {
      title: languagesCms.connector_id,
      dataIndex: 'connector_id',
      key: 'connector_id',
      sorter: (a, b) => a.connector_id - b.connector_id,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.NAME,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.DEVICE_NAME,
      dataIndex: 'unique_id',
      key: 'unique_id',
      sorter: (a, b) => a.unique_id - b.unique_id,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: "Şarj İstasyonu",
      dataIndex: 'stationName',
      key: 'stationName',
      sorter: (a, b) => a.stationName - b.stationName,
      sortDirections: ['descend', 'ascend'],

    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'right',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link
            to={
              '/historyView/' +
              stationId +
              '/' +
              uniqueId +
              '/' +
              record.connector_id
            }
          >
            <Button icon={<EditOutlined />}> {languagesCms.CHARGING_HISTORY} </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h4
            style={{
              display: 'inline-block',
              marginRight: '2rem',
              marginBottom: '1rem',
              color: '#fff',
            }}
          >
            {languagesCms.CONNECTORS}
          </h4>
          {data.length > 0 && (
            <Breadcrumb>
              <Breadcrumb.Item>
                <a href="/history">{languagesCms.CHARGING_HISTORY} </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href={'/history/' + data[0].stationId}>
                  {data[0].stationName ? data[0].stationName : ''}
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a
                  href={
                    '/history/' + data[0].stationId + '/' + data[0].unique_id
                  }
                >
                  {data[0].chargepointName ? data[0].chargepointName : ''}
                </a>
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
        </div>
        <div className="list-buttons">
          <Link to={'/history/' + stationId}>
            <Button
              type="light"
              icon={<LeftOutlined />}
              style={{ marginLeft: '5px' }}
              size={'large'}
            >
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder={languagesCms.SEARCH_NAME_LASTNAME_PHONE}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default HistoryConnector;
