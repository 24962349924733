import { useEffect, useState } from 'react';
import { Button, message, Modal, Popconfirm, Table, Space } from 'antd';
import React from 'react';
import api from '../../service';
import { useGlobal } from 'reactn';
import { DeleteOutlined } from '@ant-design/icons';
import RfidModal from './rfidModal';

const rfidListModal = ({ userId, facilityId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [languagesCms] = useGlobal('languagesCms');

  console.log('isModalOpen : ', isModalOpen);

  const get = () => {
    api
      .get('/rest/user-rfids/' + userId)
      .then(({ data: { result, result_message } }) => {
        if (result) {
          setData(result);
        } else {
          message.error(
            'Rfid verileri getirilemedi lütfen çağrı merkezinden destek alınız.',
          );
        }
      })
      .catch((err) => {
        message.error(
          'Rfid verileri getirilemedi lütfen çağrı merkezinden destek alınız.',
        );
      });
  };

  const deleteRecord = (id) => {
    api
      .delete('/rest/rfid/' + id)
      .then(({ data: { result, result_message } }) => {
        if (result) {
          console.log('result : ', result);
          message.success('Kayıt Silindi.');
        } else {
          message.error('Kayıt Silinemedi');
        }
      })
      .catch((err) => {
        message.error('Kayıt Silinemedi');
      });
    handleOk();
  };

  useEffect(() => {
    if (isModalOpen) {
      get();
    }
  }, [isModalOpen]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: 'Rfid Numarası',
      dataIndex: 'rfid',
      key: 'rfid',
      width: '18%',
      align: 'center',
    },
    {
      title: 'Kullanıcı',
      dataIndex: 'userName',
      key: 'userName',
      width: '18%',
      align: 'center',
    },
    {
      title: 'Durumu',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '18%',
      render: (text) =>
        text === 'active' ? (
          <span style={{ color: 'green', fontWeight: 'bold' }}>Aktif</span>
        ) : (
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            {text === 'passive' ? 'Pasif' : text}
          </span>
        ),
    },
    {
      title: 'Bakiye',
      dataIndex: 'price',
      key: 'price',
      width: '18%',
      align: 'center',
      ellipsis: true,
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
      width: '18%',
      align: 'center',
      ellipsis: true,
    },
    {
      title: 'Aksiyonlar',
      dataIndex: 'description',
      key: 'description',
      width: '28%',
      align: 'center',
      render: (text, record) => (
        <>
          <Space size="small">
            <button
              type="button"
              style={{
                border: 'none',
                background: 'none',
              }}
              onClick={handleOk}
            >
              <RfidModal id={record._id} record={record} add={true} />
            </button>
            <Popconfirm
              onConfirm={() => deleteRecord(record._id)}
              title={languagesCms.CHECK_DELETE}
              okText={languagesCms.OK_TEXT}
              cancelText={languagesCms.CANCEL_TEXT}
              className=""
            >
              <Button type="danger" size="middle" icon={<DeleteOutlined />}>
                {languagesCms.DELETE}
              </Button>
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];
  return (
    <>
      <button
        type="button"
        className="btn btn-outline-primary w-50 btn-sm"
        onClick={showModal}
      >
        <i className="fa-solid fa-list-ol"></i> RFID Kartları
      </button>
      <Modal
        title="RFID Yönetimi"
        visible={isModalOpen}
        okText="Kapat"
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ borderRadius: '21px !important' }}
      >
        <div>
          <button
            type="button"
            style={{
              border: 'none',
              background: 'none',
            }}
            onClick={handleOk}
          >
            <RfidModal userId={userId} facilityId={facilityId} add={true} />
          </button>
        </div>
        <Table dataSource={data} columns={columns} />;
      </Modal>
    </>
  );
};

export default rfidListModal;
