import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm, Breadcrumb } from 'antd';
import api from '../../service/index';
import { Link, useParams } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  Loading3QuartersOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

const Connector = (props) => {
  const { Search } = Input;
  let params = useParams();
  let [data, setData] = useState([]);
  let [search, setSearch] = useState(false);
  let [totalCount, setTotalCount] = useState(0);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let stationId = params.stationId || false;
  let uniqueId = params.uniqueId || false;
  const [user] = useGlobal('user');
  const { auth } = useSelector((state) => state.auth);

  let query = [];
  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }
    let _params = { sort: shortString, ...page, query };
    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'unique_id, name';
    }

    let restData = await api
      .get(`/rest/connectors/${uniqueId}?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setTotalCount(data.result[0].connector.total);
          setLoading(false);
          return data.result[0].connector.map((item, key) => {
            item.stationName =
              data &&
              data.result[0] &&
              data.result[0].stationId &&
              data.result[0].stationId.name;
            item.chargepointName =
              data && data.result[0] && data.result[0].name;
            return item;
          });
        }
      });
    setData(restData);
  };
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let deleteRow = async (item_id) => {
    api.delete(`/rest/connectors/${uniqueId}/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el.connector_id !== item_id);
    setData(newData);
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let columns = [
    {
      title: languagesCms.DEVICE_ID,
      dataIndex: 'unique_id',
      align: 'center',
      key: 'unique_id',
      sorter: (a, b) => a.unique_id - b.unique_id,
    },
    {
      title: 'Konnektör No',
      dataIndex: 'connector_id',
      align: 'center',
      key: 'connector_id',
      sorter: (a, b) => a.connector_id - b.connector_id,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Şarj İstasyonu',
      dataIndex: 'stationName',
      align: 'center',
      key: 'stationName',
      sorter: (a, b) => a.stationName - b.stationName,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'right',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          {!(
            auth &&
            auth
              .find((a) => a.number === user.role)
              .restraintList.includes('edit')
          ) && (
            <Link
              to={
                '/stations/detail/' +
                stationId +
                '/' +
                uniqueId +
                '/edit/' +
                record.connector_id
              }
            >
              <Button icon={<EditOutlined />}>
                {!isSmall && languagesCms.EDIT}
              </Button>
            </Link>
          )}
          {((user && user.role && user.role === 51) ||
            !(
              auth &&
              auth
                .find((a) => a.number === user.role)
                .restraintList.includes('delete')
            )) && (
            <Popconfirm
              onConfirm={() => deleteRow(record.connector_id)}
              title={languagesCms.CHECK_DELETE}
              okText={languagesCms.OK_TEXT}
              cancelText={languagesCms.CANCEL_TEXT}
            >
              <Button type="danger" icon={<DeleteOutlined />}>
                {!isSmall && languagesCms.DELETE}
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h4
            style={{
              display: 'inline-block',
              marginRight: '2rem',
              color: '#fff',
              marginBottom: '.1rem',
            }}
          >
            {languagesCms.CONNECTORS}
          </h4>{' '}
          {data.length > 0 && (
            <Breadcrumb>
              <Breadcrumb.Item>
                <a
                  style={{
                    color: '#fff',
                  }}
                  href="/stations"
                >
                  {' '}
                  {'Şarj İstasyonu'}{' '}
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a
                  style={{
                    color: '#fff',
                  }}
                  href={'/stations/detail/' + data[0].stationId}
                >
                  {data[0].stationName ? data[0].stationName : ''}
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a
                  style={{
                    color: '#fff',
                  }}
                  href={
                    '/stations/detail/' +
                    data[0].stationId +
                    '/' +
                    data[0].unique_id
                  }
                >
                  {data[0].unique_id ? data[0].unique_id : ''}
                </a>
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
        </div>
        <div className="list-buttons">
          {((user && user.role && user.role === 51) ||
            !(
              auth &&
              auth
                .find((a) => a.number === user.role)
                .restraintList.includes('add')
            )) && (
            <Link
              to={'/stations/detail/' + stationId + '/' + uniqueId + '/add'}
              style={{ marginRight: '5px' }}
            >
              <Button type="light" icon={<PlusOutlined />} size={'large'}>
                {!isSmall && 'Konnektör Ekle'}
              </Button>
            </Link>
          )}
          <Link to={'/stations/detail/' + stationId}>
            <Button
              type="light"
              icon={<LeftOutlined />}
              style={{ marginLeft: '5px' }}
              size={'large'}
            >
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder={languagesCms.SEARCH_NAME_LASTNAME_PHONE}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default Connector;
