import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm } from 'antd';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  CarOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { StationModal } from '../../components';

const Stations = (props) => {
  const [isSmall] = useGlobal('isSmall');
  const [languagesCms] = useGlobal('languagesCms');
  const [modules] = useGlobal('modules');
  const [user] = useGlobal('user');
  const [auth] = useGlobal('auth');

  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  const [cpoInfo] = useState([]);
  const [nameInfo] = useState([]);
  const [cityInfo] = useState([]);
  const [bayiInfo] = useState([]);
  const cpoSet = [...new Set(cpoInfo.map(JSON.stringify))].map(JSON.parse);
  const nameSet = [...new Set(nameInfo.map(JSON.stringify))].map(JSON.parse);
  const citySet = [...new Set(cityInfo.map(JSON.stringify))].map(JSON.parse);
  const bayiSet = [...new Set(bayiInfo.map(JSON.stringify))].map(JSON.parse);

  let query = [];
  query['deleted'] = false;

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query, cmsQuery: {} };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'unique_id, name, city';
    }
    let restData = await api
      .get(`/rest/station-get?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data && data.result && data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.map((item, key) => {
            if (item.bayi)
              bayiInfo.push({
                text: item.bayi && item.bayi.bayiName,
                value: item.bayi && item.bayi.bayiName,
              });
            if (item.cpoUserName) {
              cpoInfo.push({ text: item.cpoUserName, value: item.cpoUserName });
            }
            if (item.name) {
              nameInfo.push({ text: item.name, value: item.name });
            }
            if (item.city) {
              cityInfo.push({ text: item.city, value: item.city });
            }
            if (item.active) item.active = <div className="true-circle"></div>;
            else item.active = <div className="false-circle"></div>;

            item.key = key;
            return item;
          });
        } else setLoading(false);
      });
    setData(restData);
    setLoading(false);
  };
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let deleteRow = async (item_id) => {
    api.delete(`/rest/stations/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'unique_id',
      key: 'name',
      sorter: (a, b) => a.unique_id - b.unique_id,
    },
    {
      title: languagesCms.NAME,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['descend', 'ascend'],
      filters: nameSet,
      onFilter: (value, record) =>
        record.name && record.name.indexOf(value) === 0,
    },
    {
      title: languagesCms.CITY,
      dataIndex: 'city',
      key: 'city',
      sorter: (a, b) => a.city - b.city,
      sortDirections: ['descend', 'ascend'],
      filters: citySet,
      onFilter: (value, record) =>
        record.city && record.city.indexOf(value) === 0,
    },
    {
      title: languagesCms.Dealer,
      dataIndex: 'dealerId',
      key: 'dealerId',
      render: (text, record) =>
        record.bayi && record.bayi.bayiName && record.bayi.bayiName.length > 0
          ? record.bayi.bayiName
          : '-',
      filters: bayiSet,
      onFilter: (value, record) =>
        record.bayi &&
        record.bayi.bayiName &&
        record.bayi.bayiName.indexOf(value) === 0,
    },
    {
      title: 'CPO',
      dataIndex: 'cpoUserName',
      key: 'cpoUserName',
      filters: cpoSet,
      onFilter: (value, record) =>
        record.cpoUserName && record.cpoUserName.indexOf(value) === 0,
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'center',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <StationModal languagesCms={languagesCms} record={record} />

          <Link to={'/stations/detail/' + record._id}>
            <Button className="cihazbtn" icon={<CarOutlined />}>
              {!isSmall && languagesCms.DEVİCES}
            </Button>
          </Link>
          {!(
            auth &&
            auth
              .find((a) => a.number === user.role)
              .restraintList.includes('edit')
          ) && (
            <Link to={'/stations/edit/' + record._id}>
              <Button icon={<EditOutlined />}>
                {!isSmall && languagesCms.EDIT}
              </Button>
            </Link>
          )}
          {!(
            auth &&
            auth
              .find((a) => a.number === user.role)
              .restraintList.includes('delete')
          ) && (
            <Popconfirm
              onConfirm={() => deleteRow(record._id)}
              title={languagesCms.CHECK_DELETE}
              okText={languagesCms.OK_TEXT}
              cancelText={languagesCms.CANCEL_TEXT}
            >
              <Button type="danger" icon={<DeleteOutlined />}>
                {!isSmall && languagesCms.DELETE}
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h4
            style={{
              display: 'inline-block',
              marginRight: '2rem',
              marginBottom: '1rem',
              color: '#fff',
            }}
          >
            Şarj İstasyonları
          </h4>
        </div>
        <div className="list-buttons">
          {!(
            auth &&
            auth.find((a) => a.number === user.role) &&
            auth.find((a) => a.number === user.role).restraintList &&
            auth
              .find((a) => a.number === user.role)
              .restraintList.includes('add')
          ) && (
            <>
              <Link to="/stations/add" style={{ marginRight: '5px' }}>
                <Button
                  type="light"
                  style={{ fontSize: '14px', height: '100%' }}
                  icon={<PlusOutlined />}
                  size="large"
                >
                  {!isSmall && "Şarj İstasyonu Ekle"}
                </Button>
              </Link>
            </>
          )}
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder="Arama..."
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
            showSizeChanger: true,
          }}
        />
      </div>
    </div>
  );
};

export default Stations;
