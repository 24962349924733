import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import api from '../../service';
import qs from 'qs';

const RfidPicker = (props) => {
  const { record, setRecord, name, mode, disabled, error } = props;
  const [user, setUser] = useState([]);

  const _params = {
    sort: '-createdAt',
    page: 1,
    pageSize: 100000,
  };

  useEffect(() => {
    console.log("UseEffect İçerisinde ")
    api
      .get(`${'/rest/rfid'}?${qs.stringify(_params)}`)
      .then(async ({ data }) => {
        if (data && data.result) {
          console.log("then içerisinde")
          console.log(data.result);
          const newData =
            (await data) &&
            data.result &&
            data.result.rows.map((item) => {
              return {
                label: `${item.userName} - Rfid: ${item.rfid} - Bakiye: ${
                  item.price ? item.price : 0
                } TL`,
                value: item.rfid,
              };
            });


          const dbUser = newData.sort((a, b) => {
            return (
              a && a.value && a.value.localeCompare(b && b.value && b.value)
            );
          });

          setUser(dbUser);
        }
      });
  }, []);

  const handleChange = (e) => {
    if (mode === 'multiple') {
      const selectedValues = e.map((option) => option.value);
      setRecord((prevRecord) => ({
        ...prevRecord,
        [name]: selectedValues,
      }));
    } else setRecord({ ...record, [name]: e.value });
  };

  return (
    <>
      <Select
        defaultValue={record && record[name]}
        isMulti={mode === 'multiple' ? true : false}
        name={name}
        options={user}
        disabled={disabled}
        className="basic-multi-select"
        placeholder="Kullanıcılar"
        classNamePrefix="select"
        onChange={handleChange}
      />
      {error && <span style={{ color: 'red' }}>Lütfen Kullanıcı Seçiniz.</span>}
    </>
  );
};

export default RfidPicker;
