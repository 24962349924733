import React, { useEffect } from 'react';
import { Comment, message } from 'antd';
import api from '../../service';
import moment from 'moment';

const CommentArea = ({ id, firstData }) => {
  const [data, setData] = React.useState([]);
  const get = async () => {
    if (id) {
      api
        .get('/rest/contact-us-history/' + id)
        .then(({ data: { result, result_message } }) => {
          if (result) {
            setData(result);
          } else {
            message.error(result_message.message);
          }
        })
        .catch((err) => {
          message.error('Bir hata oluştu !');
        });
    }
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <div className="comment-area">
      <Comment
        author={<>{data && data[0] && data[0].username && data[0].username}</>}
        datetime={moment(data && data[0] && data[0].createdAt).format('DD-MM-YYYY HH:mm:ss')}
        avatar={
          <i
            style={{
              fontSize: '2.5rem',
            }}
            className="fa-solid fa-message"
          ></i>
        }
        content={
          <p>{data && data[0] && data[0].description && data[0].description}</p>
        }
      >
        {data.map((item, key) => {
          if (key !== 0) {
            return (
              <Comment
                author={<a href="/">{item.username}</a>}
                avatar={
                  <i
                    style={{
                      fontSize: '2.5rem',
                    }}
                    className="fa-solid fa-user"
                  ></i>
                }
                datetime={moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                content={
                  <>
                    <p>{item.description}</p>
                    <p className="comment-area-responsible">
                      {item.responsible}
                    </p>
                  </>
                }
                key={key}
              ></Comment>
            );
          } else return true;
        })}
      </Comment>
    </div>
  );
};

export default React.memo(CommentArea);
