import React, { useEffect, useState, useGlobal } from 'reactn';
import {
  Table,
  Space,
  Button,
  Input,
  Popconfirm,
  Breadcrumb,
  Modal,
  Tabs,
} from 'antd';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  FileTextOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

const PrivatePrice = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let [module] = modules.filter((el) => el._id === path);
  const [user] = useGlobal('user');
  const {auth} = useSelector(state => state.auth)

  let query = [];
  query['deleted'] = false;

  const { TabPane } = Tabs;

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };


  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name, power, price';
    }

    let restData = await api
      .get(`/rest/private-prices?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.map((item, key) => {
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/prices/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };

  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/prices/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  function turkishSort(a, b) {
    var alfabe =
      'AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789';
    if (a.length === 0 || b.length === 0) {
      return a.length - b.length;
    }
    for (var i = 0; i < a.length && i < b.length; i++) {
      var ai = alfabe.indexOf(a[i]);
      var bi = alfabe.indexOf(b[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }

  let columns = [
    {
      title: languagesCms.NAME,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.POWER,
      dataIndex: 'power',
      key: 'power',
      sorter: (a, b) => a.power - b.power,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => text ? text + ' kW' : '',
    },
    {
      title: 'Şarj İstasyonu',
      dataIndex: 'stationName',
      key: 'stationName',
      sorter: (a, b) => turkishSort(a.stationName, b.stationName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.PRICE,
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => turkishSort(a.price, b.price),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => record && record.price  + " TL / kWh"
    },
    {
      title: languagesCms.TYPE,
      width: '50px',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type - b.type,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'right',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          {!(auth && auth.find(a => a.number === user.role).restraintList.includes( "edit" )) && (
            <Link to={'/private-prices/edit/' + record._id}>
              <Button icon={<EditOutlined />}>
                {!isSmall && languagesCms.EDIT}
              </Button>
            </Link>
          )}
          
          {!(auth && auth.find(a => a.number === user.role).restraintList.includes( "delete" )) && (
            <Popconfirm
              onConfirm={() => deleteRow(record._id)}
              title={languagesCms.CHECK_DELETE}
              okText={languagesCms.OK_TEXT}
              cancelText={languagesCms.CANCEL_TEXT}
            >
              <Button 
                type="danger" icon={<DeleteOutlined />}>
                {!isSmall && languagesCms.DELETE}
              </Button>
            </Popconfirm>
          )}
          
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}{' '}
          </h1>
          <Breadcrumb style={{fontSize: "1.3rem"}}>
            <Breadcrumb.Item style={{color: "#fff"}}> {languagesCms.SPECIAL_TARIFFS} </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="list-buttons">
          <Button
            style={{
              height: '100%',
              fontSize: '13px',
              margin: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px',
              borderRadius: '4px',
            }}
            onClick={showModal}
          >
            <FileTextOutlined />
            {languagesCms.SPECIAL_TARIFFS}
          </Button>
          <div className="pricemodal">
            <Modal
              title={languagesCms.SPECIAL_TARIFFS}
              visible={isModalVisible}
              onOk={handleOk}
              footer={null}
              width={550}
              onCancel={handleOk}
            >
              <div className="modal-background-second">
                <img src="/images/chargecar.png" alt="chargerbackground" />
              </div>

              <Tabs defaultActiveKey="1" centered>
                {data.map((veri, index) => (
                  <TabPane
                    className="tabpanecontent"
                    tab={veri.name}
                    key={index + 1}
                  >
                    <div className="row tabpane">
                      <div className="stationinfo stationinfo__">
                        <li>
                          <b> {languagesCms.NAME} : </b> &nbsp; {veri.name}
                        </li>
                        <li>
                          <b> {languagesCms.POWER} : </b> &nbsp; {veri.power} ( kW )
                        </li>
                        <li>
                        <b>{languagesCms.PRICE} : &nbsp;</b> {veri.price}
                          <span> &nbsp; {veri.price_type}</span> &nbsp; /&nbsp; kWh 
                        </li>
                        <li>
                          <b>Şarj İstasyonu : </b>  &nbsp;{veri.stationName}
                        </li>
                        <li>
                          <b>{languagesCms.TYPE}</b> :  &nbsp;{veri.type}
                        </li>
                      </div>
                    </div>
                  </TabPane>
                ))}
              </Tabs>
            </Modal>
          </div>
          {(!isSmall && !(auth && auth.find(a => a.number === user.role).restraintList.includes( "delete" ))) && (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="large"
              onClick={deleteSelectedRows}
              style={{
                marginRight: '10px',
                fontSize: '14px',
                height: '35px',
              }}
            >
              {languagesCms.DELETE_SELECTED}
            </Button>
          )}
          {!(auth && auth.find(a => a.number === user.role).restraintList.includes( "add" )) && (
            <Link to="/private-prices/add" style={{ marginRight: '5px' }}>
              <Button
                type="light"
                icon={<PlusOutlined />}
                size={'medium'}
              >
                {!isSmall && "Özel Tarife Ekle"}
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder="Arama..."
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default PrivatePrice;
