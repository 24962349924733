import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, Button, Card, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { LoadingAnimation } from '../../components';
import RestraintList from '../../constants/RestraintList';

const AuthorizationDetail = (props) => {
  const params = useParams();
  const history = useHistory();
  const { id } = params;

  const [languagesCms] = useGlobal('languagesCms');
  const [isSmall] = useGlobal('isSmall');
  let [modules] = useGlobal('modules');
  const [user] = useGlobal('user');

  const [selectModuleData, setSelectModuleData] = useState([]);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(id ? true : false);
  const [validationCheck, setValidationCheck] = useState(false);

  const { auth } = useSelector((state) => state.auth);

  useEffect(() => {
    setLoading(true);
    if (id) {
      api
        .get('/rest/authorizations/' + id)
        .then(({ data }) => {
          if (data && data.result) {
            setData(data.result);
            setLoading(false);
          } else {
            message.error('Veriler getirilemedi !!!');
          }
        })
        .catch((err) => {
          console.log(err);
        });

      if (modules) {
        const selectModule =
          modules &&
          modules
            .filter((m) => m.group !== 'app-management')
            .map((m) => {
              return {
                label: m.subName || m.name,
                value: m._id,
              };
            });
        setSelectModuleData(selectModule);
      }
    }
    setLoading(false);
  }, []);

  let validate = useCallback(() => {
    let errors = {};

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setLoading(true);
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      setLoading(false);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api.put('/rest/authorizations/' + id, data).then(({ data }) => {
          setLoading(false);

          if (
            data &&
            data.result_message &&
            data.result_message.type === 'success'
          ) {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/authorization');
          } else
            message.error(
              data && data.result_message && data.result_message.message,
              2,
            );
        });
      }
    }

    setLoading(false);
  };

  const defaultRestraintList =
    RestraintList &&
    RestraintList.filter(
      (r) =>
        r &&
        r.value &&
        data &&
        data.restraintList &&
        data.restraintList.includes(r.value),
    );

  const defaultModuleList =
    selectModuleData &&
    selectModuleData.filter(
      (m) =>
        m && m.value && data && data.modules && data.modules.includes(m.value),
    );

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h5 style={{ color: 'white' }}>Yetkilendirme {id ? 'Düzenle' : 'Ekle'}</h5>
        </div>
        <div className="list-buttons">
          <Link to={'/authorization'}>
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        {loading ? (
          <LoadingAnimation />
        ) : (
          <>
            <Card
              title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
              loading={loading}
            >
              <Form
                disabled={
                  auth &&
                  auth
                    .find((a) => a.number === user.role)
                    .restraintList.includes('edit')
                }
                layout="horizontal"
                size={'large'}
                onFinish={save}
                labelCol={{ span: 24 }}
              >
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label={'Yetki Kısıtlama'}
                      labelCol={{
                        span: 24,
                      }}
                      required
                      help={errors.restraintList}
                      validateStatus={
                        errors.restraintList ? 'error' : 'success'
                      }
                    >
                      <ReactSelect
                        value={defaultRestraintList}
                        isMulti
                        name="restraintList"
                        options={RestraintList}
                        className="basic-multi-select"
                        placeholder="Kısıtlamalar"
                        classNamePrefix="select"
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions.map(
                            (option) => option.value,
                          );
                          setData((prevRecord) => ({
                            ...prevRecord,
                            restraintList: selectedValues,
                          }));
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label={'Görebileceği Modüller'}
                      labelCol={{
                        span: 24,
                      }}
                      help={errors.modules}
                      validateStatus={errors.modules ? 'error' : 'success'}
                    >
                      <ReactSelect
                        value={defaultModuleList}
                        isMulti
                        name="modules"
                        options={selectModuleData}
                        className="basic-multi-select"
                        placeholder="Modül Kısıtlamaları"
                        classNamePrefix="select"
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions.map(
                            (option) => option.value,
                          );
                          setData((prevRecord) => ({
                            ...prevRecord,
                            modules: selectedValues,
                          }));
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row direction="row">
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        type="primary"
                        disabled={loading}
                        htmlType="submit"
                        size="large"
                        block
                      >
                        {' '}
                        {languagesCms.SAVE}{' '}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </>
        )}
      </div>
    </div>
  );
};
export default AuthorizationDetail;
