import React, { useState } from 'react';
import api from '../../service/index';
import { Button, message, Card, Form, Row, Col, Input } from 'antd';

const EncryptTextAndQrcodeGenerator = ({ setEncrypt }) => {
  const [loading, setLoading] = useState(false);

  // data çözümlemek için input içerik tutma
  const [data, setData] = useState([]);

  // şifreleme fonksiyonu
  const encryptFunction = async () => {
    setLoading(true);
    await api
      .post('/rest/qrcode', data)
      .then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          setData(result);
          message.success('Şifre Başarıyla Çözüldü', 2);
        } else {
          message.error(result_message.message, 2);
        }
      });
    setLoading(false);
  };

  const download = (e) => {
    fetch(e.target.href, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'image.png'); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Card
        style={{ height: '295px', maxHeight: '325px' }}
        title={'Şifreleme ve QR Oluşturma'}
        loading={loading}
      >
        <Form
          layout="vertical"
          className="w-100"
          name="basic"
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={encryptFunction}
          autoComplete="on"
          size="large"
        >
          <Row>
            <Col span={12} className="gutter-row">
              <Form.Item
                label="Cihaz Id"
                name="unique_id"
                rules={[
                  {
                    required: true,
                    message: 'Cihaz Id Giriniz !',
                  },
                ]}
              >
                <Input
                  name="unique_id"
                  value={data && data.unique_id}
                  onChange={(e) =>
                    setData({ ...data, unique_id: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12} className="gutter-row">
              <Form.Item
                label="Konnektör Id"
                name="connector_id"
                rules={[
                  {
                    required: true,
                    message: 'Konnektör Id Giriniz !',
                  },
                ]}
              >
                <Input
                  name="connector_id"
                  value={data && data.connector_id}
                  onChange={(e) =>
                    setData({ ...data, connector_id: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          {data && data.media && data.encryptText && (
            <>
              <Row direction="row">
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    margin: 'auto',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '50%',
                      marginBottom: '.7rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <img src={data && data.media && data.media.url} alt="" />
                    <a
                      href={data && data.media && data.media.url}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => download(e)}
                    >
                      <i
                        style={{ fontSize: '17px' }}
                        className="fa-solid fa-download"
                      ></i>
                    </a>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      height: '50%',
                      marginBottom: '1rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <h5 style={{ marginBottom: '11px' }}>Şifreli Key : </h5> &nbsp;&nbsp;&nbsp;&nbsp;
                    <b>{data.encryptText}</b>
                  </div>
                </div>
              </Row>
            </>
          )}

          <Row justify="space-between">
            <Col span={3}>
              {' '}
              <Button
                style={{ borderRadius: '7px' }}
                type="danger"
                className='customize-botton'
                onClick={encryptFunction}
              >
                Oluştur
              </Button>
            </Col>
            <Col span={3}>
              {' '}
              <Button
                style={{ borderRadius: '7px' }}
                type="danger"
                onClick={() => setEncrypt(false)}
              >
                Vazgeç
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default React.memo(EncryptTextAndQrcodeGenerator);
