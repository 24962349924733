import { Col, message, Modal, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import api from '../../service';

const ActiveCallUserDetail = ({ user, phone }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(null);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getUser = () => {
    api
      .get('/rest/call-center/user-get/+' + phone)
      .then(({ data: { result, result_message } }) => {
        if (result) {
          setData(result);
        } else message.error('Kullanıcı Bulunamadı !');
      })
      .catch((err) => {
        message.error('Kullanıcı Bulunamadı !', err);
      });
  };

  useEffect(() => {
    if (phone && phone.length && phone.length > 0) {
      getUser();
    }
  }, []);

  const style = {
    boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px',
    padding: '1rem',
    margin: '.1rem 0',
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-outline-danger btn-sm"
        onClick={showModal}
      >
        {user}&nbsp; &nbsp;
        <i className="fa-solid fa-up-right-from-square"></i>
      </button>
      <Modal
        title="Kullanıcı Detay"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Kapat"
        cancelText="Kapat"
      >
        <div>
          <Row>
            <Col style={style} xs={{ span: 24 }} md={{ span: 12 }}>
              <b>Adı</b>
              <p>{data && data.name && data.name}</p>
            </Col>
            <Col style={style} xs={{ span: 24 }} md={{ span: 12 }}>
              <b>Soyadı</b>
              <p>{data && data.lastname && data.lastname}</p>
            </Col>
            <Col style={style} xs={{ span: 24 }} md={{ span: 12 }}>
              <b>Email</b>
              <p>{data && data.email && data.email}</p>
            </Col>
            <Col style={style} xs={{ span: 24 }} md={{ span: 12 }}>
              <b>Telefon</b>
              <p>{data && data.phone && data.phone}</p>
            </Col>
            <Col style={style} xs={{ span: 24 }} md={{ span: 12 }}>
              <b>Adres</b>
              <p>{data && data.address && data.address}</p>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};
export default React.memo(ActiveCallUserDetail);
