const RestraintList = [
    { label: 'Ekleme', value: 'add' },
    { label: 'Silme', value: 'delete' },
    { label: 'Düzenleme', value: 'edit' },
    { label: 'Görüntüleme', value: 'view' },
    { label: 'Reset', value: 'reset' },
    { label: 'Şarj Başlatma', value: 'startCharge' },
    { label: 'Şarj Durdurma', value: 'stopCharge' },
  ];
  
  export default RestraintList;
  