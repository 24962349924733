import { Table } from 'antd';
import React from 'react';

const userTable = ({ record, size }) => {
  let columns = [
    {
      title: 'Kat Sahibi',
      dataIndex: 'homeowner',
      key: 'homeowner',
      align: 'homeowner',
    },
    {
      title: 'Daire',
      dataIndex: 'apartment',
      key: 'apartment',
      align: 'center',
    },
    {
      title: 'blok',
      dataIndex: 'blok',
      key: 'blok',
      align: 'center',
    },
    {
      title: 'Kat',
      dataIndex: 'floor',
      key: 'floor',
      align: 'center',
    },
    {
      title: 'Toplam Tüketim ( kWh )',
      dataIndex: 'consumption',
      key: 'consumption',
      align: 'center',
    },
    {
      title: 'Tüketim Bedeli ( TL )',
      dataIndex: 'consumptionCost',
      key: 'consumptionCost',
      align: 'center',
    },
    {
      title: 'Pay Ölçme Bedeli ( TL )',
      dataIndex: 'shareMeasurementCost',
      key: 'shareMeasurementCost',
      align: 'center',
    },
    {
      title: 'Toplam Elektrikli Araç Şarj Hizmeti Bedeli ( TL )',
      dataIndex: 'totalServiceCharge',
      key: 'totalServiceCharge',
      align: 'center',
    },
  ];

  return (
    <div>
      {' '}
      <Table size={size ? size : "middle"} dataSource={record} columns={columns} />
    </div>
  );
};

export default userTable;
