import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name, mode, disabled, dataLimit } = props;
  let [station, setStation] = useState([]);
  let _params = {
    sort: '-createdAt',
    page: 1,
    pageSize: 100000,
    fields: ['_id', 'name'],
  };

  useEffect(() => {
    api
      .get(
        `${
          dataLimit === 'allData'
            ? '/rest/dealers'
            : dataLimit === 'theirSites'
            ? '/rest/own-dealer-picker'
            : '/rest/dealer-picker'
        }?${qs.stringify(_params)}`,
      )
      .then(async ({ data }) => {
        if (data && data.result) {
          const dealers =
            data &&
            data.result &&
            data.result.sort((a, b) => {
              return a && a.dealers && a.dealers.localeCompare(b.name);
            });
          setStation(dealers);
        }
      });
  }, [record]);

  return (
    <Select
      value={record[name] || []}
      showSearch={true}
      mode={mode}
      placeholder={'Tesis Seçiniz'}
      onChange={(v) => setRecord({ ...record, [name]: v })}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled || false}
    >
      {station &&
        station.map((b) => (
          <Select.Option key={b._id} value={b._id}>
            {b.name}
          </Select.Option>
        ))}
    </Select>
  );
};
