import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Breadcrumb,
  Select,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';

const { Option } = Select;

const PriceDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  const { stationId } = params;
  let id = params.id !== 'add' ? params.id : false;
  let [languagesCms] = useGlobal('languagesCms');

  let newRecord = {
    name: '',
    power: '0.0',
    price: '0.0',
    type: 'AC',
    price_type: 'TL',
    serviceFee: '0',
    stationId: stationId ? stationId : '',
  };

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  const [user] = useGlobal('user');
  const { auth } = useSelector((state) => state.auth);

  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }
  //onetime run
  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/prices/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.name === null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED;

    if (data.power === null || data.power === '0.0')
      errors.power = languagesCms.ENFORCED;

    if (data.stationId === null || data.stationId === '')
      errors.stationId = languagesCms.ENFORCED;

    if (data && Number(data.serviceFee) > 100) {
      errors.serviceFee = 'Hizmet Bedeli 100 den büyük olmaz';
    }
    if (data && Number(data.serviceFee) < 0) {
      errors.serviceFee = 'Hizmet Bedeli 0 dan küçük olmaz';
    }

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setLoading(true);
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      setLoading(false);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/prices/' + id, data)
          .then(({ data: { result, result_message } }) => {
            setLoading(false);

            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/prices/' + stationId);
            } else message.error(result_message.message, 2);
          });
      } else {
        api
          .post('/rest/prices', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/prices/' + stationId);
            } else {
              message.error(result_message.message, 2);
            }
          });
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
          <Breadcrumb style={{ fontSize: '1.1rem' }}>
            <Breadcrumb.Item>
              <a style={{ color: '#fff' }} href="/prices">
                {' '}
                {languagesCms.GENERAL_TARIFFS}{' '}
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ color: '#fff' }}>
              {data && data.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="list-buttons">
          <Link to={`/prices/${stationId}`}>
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form
            disabled={
              auth &&
              auth
                .find((a) => a.number === user.role)
                .restraintList.includes('edit')
            }
            layout="horizontal"
            size={'large'}
            onFinish={save}
          >
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.NAME}
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="name"
                    value={data && data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.POWER + ' ( kW )'}
                  help={errors.power}
                  validateStatus={errors.power ? 'error' : 'success'}
                >
                  <Input
                    name="power"
                    value={data && data.power}
                    onChange={(e) =>
                      setData({ ...data, power: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.PRICE + ' ( kWh )'}
                  help={errors.price}
                  validateStatus={errors.price ? 'error' : 'success'}
                >
                  <Input
                    name="power"
                    value={data && data.price}
                    onChange={(e) =>
                      setData({ ...data, price: e.target.value })
                    }
                  />
                  <span className="text-danger">
                    Lütfen kuruşları nokta ile ayırın. Örneğin: (7.45)
                  </span>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.TYPE}
                  help={errors.type}
                  validateStatus={errors.type ? 'error' : 'success'}
                >
                  <Select
                    defaultValue={data.type}
                    style={{ width: 120 }}
                    onChange={(v) => {
                      setData({ ...data, type: v });
                    }}
                  >
                    <Option value={'AC'}>AC</Option>
                    <Option value={'DC'}>DC</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Para Birimi"
                  help={errors.price_type}
                  validateStatus={errors.price_type ? 'error' : 'success'}
                >
                  <Select
                    defaultValue={data.price_type}
                    style={{ width: 120 }}
                    onChange={(v) => {
                      setData({ ...data, price_type: v });
                    }}
                  >
                    <Option value={'TL'}>TL</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Hizmet Bedeli (%)'}
                  help={errors.serviceFee}
                  name={'serviceFee'}
                  validateStatus={errors.serviceFee ? 'error' : 'success'}
                >
                  <Input
                    name="serviceFee"
                    value={data && data.serviceFee}
                    addonAfter="%"
                    defaultValue={data && data.serviceFee}
                    onChange={(e) => {
                      setData({ ...data, serviceFee: e.target.value });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default PriceDetail;
