import React, { useEffect, useState } from 'react';
import { message, Row, Col } from 'antd';
import api from '../../service/index';

const contact = ({ id }) => {
  const [adminInfo, setAdminInfo] = useState(null);

  const stationAdminInfo = async () => {
    await api
      .get(`/rest/station-admin-info/${id}`)
      .then(({ data }) => {
        if (data && data.result) {
          setAdminInfo(data.result);
        }
      })
      .catch((err) => {
        message.error('İletişim Verileri Getirilemedi');
      });
  };
  useEffect(() => {
    stationAdminInfo();
  }, []);

  return (
    <div className="row tabpane">
      <div
        className="stationinfo"
        style={{
          overflowY: 'auto',
        }}
      >
        <Row>
          {adminInfo &&
            adminInfo.map((item, index) => (
              <>
                <Col
                  style={{
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    textAlign: 'center',
                    marginBottom: '1rem',
                    paddingBottom: '.7rem',
                  }}
                  span={12}
                >
                  <Col span={24}>
                    <h6 key={index} style={{ padding: '11px' }}>
                      <b>
                        {item.title ? (
                          <>{item && item.title}</>
                        ) : (
                          <>
                            {item.role === 2
                              ? 'e-MSP Admin'
                              : item.role === 6
                              ? 'ST Admin'
                              : 'Tesis'}
                          </>
                        )}
                        : <br /> {item.username}
                      </b>
                    </h6>
                  </Col>
                  <Col span={24}>
                    <p>
                      <span>
                        <b>Email Adresi : </b>
                      </span>
                      <span>{item.email ? item.email : '-'}</span>
                    </p>
                  </Col>
                  <Col span={24}>
                    <span>
                      <b>Telefon Numarası : </b>
                    </span>
                    <span>{item.phone ? item.phone : '-'}</span>
                  </Col>
                </Col>
              </>
            ))}
        </Row>
      </div>
    </div>
  );
};

export default React.memo(contact);
