import React, { useState, useEffect } from 'react';
import { Modal, message, Row, Col, Form, Spin } from 'antd';
import api from '../../service/index';
import moment from 'moment';
import MeterValueList from './meterValueList';

export default (props) => {
  const { record, isModalVisible, setIsModalVisible } = props;
  const [log, setLog] = useState([]);
  // const [meter, setMeter] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = async () => {
    setLoading(true);
    const data = await api
      .get(`/rest/charge-log-detail/${record.chargeId}`)
      .then(({ data }) => {
        if (data && data.result) {
          return data.result;
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
      });
    setLog(data);
    setLoading(false);
  };

  useEffect(() => {
    showModal();
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        className="modal__info"
        title="Log"
        width={850}
        visible={isModalVisible}
        footer={null}
        loading={loading}
        onOk={handleOk}
        onCancel={handleOk}
      >
        {loading ? (
          <Spin
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            size="large"
          />
        ) : (
          <>
            {log && log.completed === true ? (
              <div style={{ padding: '0px 7px', fontWeight: 'bold' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {log && log.duration && 'Süre: ' + log.duration + ' dakika'}
                </div>
                <Row justify="space-between">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      style={{ color: 'red' }}
                      label={'Kullanıcı Ad Soyad'}
                    >
                      {log.userName}
                    </Form.Item>
                  </Col>

                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item style={{ color: 'red' }} label={'Kart Numarası'}>
                      {log.idTag}
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
                <Row justify="space-between">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item style={{ color: 'red' }} label={'Tesis'}>
                      {log.dealerName}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      style={{ color: 'red' }}
                      label={'transaction Id'}
                    >
                      {log.transactionId}
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
                <Row justify="space-between">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item style={{ color: 'red' }} label={'Fiyat Adı'}>
                      {log.priceName}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      style={{ color: 'red' }}
                      label={'Fiyat Tarifesi'}
                    >
                      {log.priceAmount}
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      style={{ color: 'red' }}
                      label={'Cihaz Unique Id'}
                    >
                      {log.unique_id}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item style={{ color: 'red' }} label={'Konnektör Id'}>
                      {log.connectorId}
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
                <Row direction="row">
                  <Col xs={{ span: 12 }} md={{ span: 12 }}>
                    <Form.Item
                      style={{ color: 'red' }}
                      label={'Şarj Başlangıç Tarihi'}
                    >
                      {moment(log.chargeStart).format('DD-MM-YYYY HH:mm:ss')}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 12 }} md={{ span: 12 }}>
                    <Form.Item
                      style={{ color: 'red' }}
                      label={'Şarj Bitiş Tarihi'}
                    >
                      {moment(log.chargeStop).format('DD-MM-YYYY HH:mm:ss')}
                    </Form.Item>
                  </Col>
                </Row>
                <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      style={{ color: 'red' }}
                      label={'Ölçüm Başlangıç Değer'}
                    >
                      {log.meterStart} kWh
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      style={{ color: 'red' }}
                      label={'Ölçüm Bitiş Değer'}
                    >
                      {log.meterStop} kWh
                    </Form.Item>
                  </Col>
                </Row>
                {console.log(log)}
                {log.soc === -1 || !log.soc ? (
                  <></>
                ) : (
                  <>
                    <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
                    <Row direction="row">
                      <Col xs={{ span: 12 }} md={{ span: 12 }}>
                        <Form.Item style={{ color: 'red' }} label={'SoC Start'}>
                          {log.startSoC} %
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 12 }} md={{ span: 12 }}>
                        <Form.Item style={{ color: 'red' }} label={'SoC Stop'}>
                          {log.soc} %
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}

                <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      style={{ color: 'red' }}
                      label={'Yüklenen Değer'}
                    >
                      {log.loadedValue}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item style={{ color: 'red' }} label={'Ödeme Tutarı'}>
                      {log.price}
                    </Form.Item>
                  </Col>
                </Row>

                <MeterValueList chargeId={record.chargeId} />
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '11rem',
                    fontSize: '2rem',
                    color: 'red',
                    fontWeight: 'bold',
                  }}
                >
                  Şarj Devam Ediyor
                </div>
                <p
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '1.5rem',
                  }}
                >
                  {log && log.duration && 'Süre : ' + log.duration + ' dakika'}
                </p>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
};
