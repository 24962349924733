import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Select,
  Modal,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import { FileInput, CostDealerPicker } from '../../components';
import moment from 'moment';
import UserTable from '../../components/dealer/userTable';

const format = 'YYYY-MM-DD';

const DealerCostDetail = (props) => {
  const [form] = Form.useForm();

  const params = useParams();
  const history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  const [languagesCms] = useGlobal('languagesCms');
  const [isModalOpen, setIsModalOpen] = useState(false);

  let newRecord = {
    unitCost: 0,
    facilityId: '',
    costDocuments: [],
    shareMeasurementCostTotal: 0,
    shareReading: 0,
    month: moment().format('MMMM').toLocaleLowerCase(),
    startDate: moment(
      `${moment()
        .format('MMMM')
        .toLocaleLowerCase()} ${new Date().getFullYear()}`,
      'MMMM YYYY',
    )
      .startOf('month')
      .format(format),
    endDate: moment(
      `${moment()
        .format('MMMM')
        .toLocaleLowerCase()} ${new Date().getFullYear()}`,
      'MMMM YYYY',
    )
      .endOf('month')
      .format(format),
  };

  const [data, setData] = useState(id ? [] : newRecord);
  const [users, setUsers] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [isSmall] = useGlobal('isSmall');
  const [validationCheck, setValidationCheck] = useState(false);
  let [errors, setErrors] = useState([]);
  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/dealer-cost/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (
      !id &&
      data &&
      data.facilityId &&
      data.unitCost &&
      data.facilityId.length > 0
    ) {
      api
        .get(
          `/rest/dealer-cost-calculation/${data && data.facilityId}/${
            data && data.startDate
          }/${data && data.endDate}/${data.unitCost}/${data.shareReading}`,
        )
        .then(({ data }) => {
          if (data && data.result) {
            return data.result;
          }
        })
        .then((res) => {
          setUsers(res && res.users);
          setData({
            ...data,
            siteTotalConsumption: res.siteTotalConsumption,
            siteTotalServiceCharge: res.siteTotalServiceCharge,
            shareMeasurementCostTotal: res.shareMeasurementCostTotal,
          });
        });
    }
    if (
      id &&
      data &&
      data.facilityId &&
      data.unitCost &&
      data.facilityId.length > 0
    ) {
      api.get(`/rest/dealer-cost-calculation-get/${id}`).then(({ data }) => {
        if (data && data.result) {
          setUsers(data.result);
        }
      });
    }
  }, [data && data.facilityId, data.unitCost]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.unitCost === null || data.unitCost === 0 || data.unitCost < 0)
      errors.unitCost = languagesCms.ENFORCED;

    if (data.facilityId === null || data.facilityId === '')
      errors.facilityId = languagesCms.ENFORCED;

    if (
      data.shareMeasurementCostTotal === null ||
      data.shareMeasurementCostTotal === 0 ||
      data.shareMeasurementCostTotal < 0
    )
      errors.shareMeasurementCostTotal = languagesCms.ENFORCED;

    if (
      data.shareReading === null ||
      data.shareReading === 0 ||
      data.shareReading < 0
    )
      errors.shareReading = languagesCms.ENFORCED;

    if (data.startDate === null || data.startDate === '')
      errors.startDate = languagesCms.ENFORCED;

    if (data.endDate === null || data.endDate === '')
      errors.endDate = languagesCms.ENFORCED;

    if (
      data.costDocuments === null ||
      !Array.isArray(data.costDocuments) ||
      !data.costDocuments.length
    )
      errors.costDocuments = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  const onFinish = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/dealer-cost/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/dealer-costs');
            } else message.error(result_message.message, 2);
          });
      } else {
        data.users = users;
        api
          .post('/rest/dealer-cost', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/dealer-costs');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  const months = [
    { key: 'january', value: 'Ocak' },
    { key: 'february', value: 'Şubat' },
    { key: 'march', value: 'Mart' },
    { key: 'april', value: 'Nisan' },
    { key: 'may', value: 'Mayıs' },
    { key: 'june', value: 'Haziran' },
    { key: 'july', value: 'Temmuz' },
    { key: 'august', value: 'Ağustos' },
    { key: 'september', value: 'Eylül' },
    { key: 'october', value: 'Ekim' },
    { key: 'november', value: 'Kasım' },
    { key: 'december', value: 'Aralık' },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/dealer-costs">
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form
            form={form}
            layout="vertical"
            className="w-100"
            disabled={window.location.pathname.includes('view')}
            name="basic"
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="on"
            size="large"
          >
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 12 }} className="gutter-row">
                <Form.Item
                  label="Başlangıç Tarihi"
                  required
                  help={errors.startDate}
                  validateStatus={errors.startDate ? 'error' : 'success'}
                >
                  <span className="ms-3">{data && data.startDate} </span>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Bitiş Tarihi"
                  required
                  help={errors.endDate}
                  validateStatus={errors.endDate ? 'error' : 'success'}
                >
                  <span className="ms-3">{data && data.endDate} </span>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={'Aylar'}>
                  <Select
                    defaultValue={data && data.month}
                    onChange={(e) =>
                      setData({
                        ...data,
                        month: e,
                        startDate: moment(
                          `${e} ${new Date().getFullYear()}`,
                          'MMMM YYYY',
                        )
                          .startOf('month')
                          .format(format),
                        endDate: moment(
                          `${e} ${new Date().getFullYear()}`,
                          'MMMM YYYY',
                        )
                          .endOf('month')
                          .format(format),
                      })
                    }
                  >
                    {months.map((item) => (
                      <>
                        <Select.Option key={item.key} value={item.key}>
                          {item.value}
                        </Select.Option>
                      </>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} className="gutter-row">
                <Form.Item
                  label="Birim Maliyet ( TL / kWh )"
                  required
                  help={errors.unitCost}
                  validateStatus={errors.unitCost ? 'error' : 'success'}
                >
                  <Input
                    name="unitCost"
                    type="number"
                    value={data && data.unitCost}
                    onChange={(e) =>
                      setData({ ...data, unitCost: Number(e.target.value) })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12} className="gutter-row">
                <Form.Item
                  label="Pay Okuma ( % )"
                  required
                  help={errors.shareReading}
                  validateStatus={errors.shareReading ? 'error' : 'success'}
                >
                  <Input
                    name="shareReading"
                    type="number"
                    addonAfter={<i className="fa fa-percentage"></i>}
                    value={data && data.shareReading}
                    onChange={(e) =>
                      setData({
                        ...data,
                        shareReading: Number(e.target.value),
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12} className="gutter-row">
                <Form.Item
                  label="Tesis"
                  required
                  help={errors.dealerId}
                  validateStatus={errors.dealerId ? 'error' : 'success'}
                >
                  <CostDealerPicker
                    languagesCms={languagesCms}
                    disabled={window.location.pathname.includes('view')}
                    record={data}
                    setRecord={setData}
                    name="facilityId"
                    mode="single"
                  />
                </Form.Item>
              </Col>
              <Col span={12} className="gutter-row">
                <Form.Item
                  required
                  help={errors.costDocuments}
                  validateStatus={errors.costDocuments ? 'error' : 'success'}
                  label="Kontrat"
                  large
                >
                  <FileInput
                    languagesCms={languagesCms}
                    name="costDocuments"
                    title="Dokümanlar"
                    type="file"
                    multi={true}
                    ext={['image']}
                    record={data}
                    setRecord={setData}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <button
                type="button"
                onClick={() => setIsModalOpen(true)}
                className="btn btn-outline-danger"
              >
                <i className="fa-solid fa-file-waveform"></i>
                Döküman Ön İzleme
              </button>
              <Modal
                title="Doküman"
                open={isModalOpen}
                okText="Kapat"
                onOk={() => setIsModalOpen(false)}
                onCancel={() => setIsModalOpen(false)}
              >
                <UserTable size="small" record={users} />
              </Modal>
            </div>
            <br />
            <Row>
              <Col span={24}>
                <Form.Item
                  wrapperCol={{
                    span: 24,
                  }}
                >
                  <Button
                    className="save-button"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    block
                  >
                    {id ? 'Düzenle' : 'Kaydet'}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default DealerCostDetail;
