import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Card, message } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from '../../components/Date/DatePicker';

const PriceListDetail = (props) => {
  const params = useParams();
  const history = useHistory();
  const id = params.id;
  const [languagesCms] = useGlobal('languagesCms');
  const pageType =
    window &&
    window.location &&
    window.location.pathname &&
    window.location.pathname.split('/')[2];

  const newRecord = {
    startDate: '',
    endDate: '',
    price: '0.00',
    serviceFee: '0',
  };

  const [data, setData] = useState(pageType === 'edit' ? [] : newRecord);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(pageType === 'edit' ? true : false);
  const [validationCheck, setValidationCheck] = useState(false);
  const [isSmall] = useGlobal('isSmall');
  const [user] = useGlobal('user');
  const { auth } = useSelector((state) => state.auth);

  //onetime run
  useEffect(() => {
    if (id && pageType === 'edit') {
      setLoading(true);
      api
        .get('/rest/priceDetail/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });

      setLoading(false);
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    console.log('data : ', data);

    if (
      data.startDate === null ||
      data.startDate === undefined ||
      data.startDate === ''
    )
      errors.startDate = languagesCms.ENFORCED;

    if (
      data.endDate === null ||
      data.endDate === undefined ||
      data.endDate === ''
    ) {
      errors.endDate = languagesCms.ENFORCED;
    }

    if (data.price === null || data.price === '0.00')
      errors.price = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setLoading(true);
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      setLoading(false);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (pageType === 'edit') {
        api
          .put('/rest/update-price/' + id, data)
          .then(({ data: { result, result_message } }) => {
            setLoading(false);

            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/price-list/' + params.pirceId);
            } else message.error(result_message.message, 2);
          });
      } else {
        data.priceId = id;
        api
          .post('/rest/add-price', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/price-list/' + id);
            } else {
              message.error(result_message.message, 2);
            }
          });
        setLoading(false);
      }
    }
  };

  console.log(data);
  console.log(errors);

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h5 style={{ color: 'white' }}>
            Fiyat {pageType === 'edit' ? 'Düzenle' : 'Ekle'}
          </h5>
        </div>
        <div className="list-buttons">
          <Link to={`/price-list/${pageType === 'edit' ? params.pirceId : id}`}>
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={pageType === 'edit' ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form
            disabled={
              auth &&
              auth
                .find((a) => a.number === user.role)
                .restraintList.includes('edit')
            }
            layout="horizontal"
            size={'large'}
            onFinish={save}
          >
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Başlangıç Tarihi'}
                  labelCol={{
                    span: 24,
                  }}
                  required
                  help={errors.startDate}
                  validateStatus={errors.startDate ? 'error' : 'success'}
                >
                  <DatePicker
                    record={data}
                    setRecord={setData}
                    name="startDate"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Bitiş Tarihi'}
                  labelCol={{
                    span: 24,
                  }}
                  required
                  help={errors.endDate}
                  validateStatus={errors.endDate ? 'error' : 'success'}
                >
                  <DatePicker
                    record={data}
                    setRecord={setData}
                    name="endDate"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.PRICE + ' kW/h'}
                  labelCol={{
                    span: 24,
                  }}
                  required
                  help={errors.price}
                  validateStatus={errors.price ? 'error' : 'success'}
                >
                  <Input
                    name="price"
                    value={data && data.price}
                    onChange={(e) =>
                      setData({ ...data, price: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Hizmet Bedeli (%)'}
                  labelCol={{
                    span: 24,
                  }}
                  required
                  help={errors.serviceFee}
                  validateStatus={errors.serviceFee ? 'error' : 'success'}
                >
                  <Input
                    name="serviceFee"
                    value={data && data.serviceFee}
                    addonAfter="%"
                    onChange={(e) =>
                      setData({ ...data, serviceFee: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default PriceListDetail;
