import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Select,
  Breadcrumb,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import 'react-phone-input-2/lib/style.css';

const ChargepointDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let stationId = params.stationId;
  let [languagesCms] = useGlobal('languagesCms');
  let [langCms] = useGlobal('langCms');
  let [langCode] = useGlobal('langCode');
  let newRecord = {
    name: '',
    unique_id: '',
    lang: langCode ? langCode : langCms ? langCms[0].code : '',
    station_model: '',
    floor_location: 0,
    countryId: '43',
    cityId: '',
    park_area: 0,
    free_parking: '1',
    address: '',
    stationId: stationId,
  };
  const [user] = useGlobal('user');
  const [authorizations] = useGlobal('authorizations');

  let [data, setData] = useState(id ? [] : newRecord);
  let [station, setStation] = useState(null);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [isSmall] = useGlobal('isSmall');

  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  if (modules !== null) {
    [modules] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    setLoading(true);
    api
      .get('/rest/stations/' + stationId)
      .then(({ data: { result, result_message } }) => {
        setStation(result);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/chargepoints/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (
      data.unique_id === null ||
      data.unique_id === undefined ||
      data.unique_id === ''
    )
      errors.unique_id = languagesCms.ENFORCED;

    if (data.name === null || data.name === undefined || data.name === '')
      errors.name = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/chargepoints/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/stations/detail/' + stationId);
            } else message.error(result_message.message, 2);
          });
      } else {
        api
          .post('/rest/chargepoints', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/stations/detail/' + stationId);
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h4 style={{ color: '#fff', marginBottom: '.1rem' }}>
            {' '}
            {languagesCms.DEVİCES}{' '}
          </h4>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a
                style={{
                  color: '#fff',
                }}
                href="/stations"
              >
                {' '}
                {languagesCms.STATIONS}{' '}
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a
                style={{
                  color: '#fff',
                }}
                href={'/stations/detail/' + data.stationId}
              >
                {station ? station.name : ''}
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{data.name ? data.name : ''}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="list-buttons">
          <Link to={'/stations/detail/' + data.stationId}>
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form
            disabled={
              authorizations &&
              authorizations
                .find((a) => a.number === user.role)
                .restraintList.includes('edit')
            }
            layout="horizontal"
            size={'large'}
            onFinish={save}
          >
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.UNIQUE_ID}
                  help={errors.unique_id}
                  required
                  validateStatus={errors.unique_id ? 'error' : 'success'}
                >
                  <Input
                    name="unique_id"
                    value={data.unique_id}
                    disabled={id ? true : false}
                    onChange={(e) =>
                      setData({ ...data, unique_id: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Cihaz Adı'}
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Cihaz Modeli'}
                  help={errors.station_model}
                  validateStatus={errors.station_model ? 'error' : 'success'}
                >
                  <Input
                    name="station_model"
                    value={data.station_model}
                    onChange={(e) =>
                      setData({ ...data, station_model: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.FLOOR_LOCATION}
                  help={errors.floor_location}
                  validateStatus={errors.floor_location ? 'error' : 'success'}
                >
                  <Select
                    value={data.floor_location}
                    onChange={(e) => setData({ ...data, floor_location: e })}
                  >
                    <Select.Option key={'0'} value={0}>
                      {'0'}
                    </Select.Option>
                    <Select.Option key={'1'} value={1}>
                      {'1'}
                    </Select.Option>
                    <Select.Option key={'2'} value={2}>
                      {'2'}
                    </Select.Option>
                    <Select.Option key={'3'} value={3}>
                      {'3'}
                    </Select.Option>
                    <Select.Option key={'4'} value={4}>
                      {'4'}
                    </Select.Option>
                    <Select.Option key={'5+'} value={5}>
                      {'5'}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={'Yer Tarifi'}
                  help={errors.address}
                  validateStatus={errors.address ? 'error' : 'success'}
                >
                  <Input.TextArea
                    name="address"
                    value={data.address}
                    onChange={(e) =>
                      setData({ ...data, address: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default ChargepointDetail;
