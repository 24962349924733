import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import { Modal, Button, Popconfirm, Tabs, message, Row, Col, Spin } from 'antd';
import api from '../../service/index';
import { RedoOutlined } from '@ant-design/icons';
import Contact from './contact';
import FirmwareUpdate from './firmwareUpdateModal';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import RfidPicker from './rfidPicker';

const StationModal = (props) => {
  const { languagesCms, stationId } = props;
  let { record } = props;
  let [summary, setSummary] = useState([]);
  let [user] = useGlobal('user');
  let [auth] = useGlobal('auth');
  const { TabPane } = Tabs;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userSelectError, setUserSelectError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = async () => {
    setLoading(true);
    let stationInfo = {};

    if (!record && stationId) {
      const res = await api
        .get(`/rest/stations/${stationId}`)
        .then(({ data }) => {
          if (data && data.result) {
            return data.result;
          }
        });

      record = res;
      stationInfo = res;
    } else {
      stationInfo = record;
    }

    if (record)
      if (!record && !stationId) {
        message.error('İstasyon Verileri Alınamadı !');
        setIsModalVisible(false);
      }

    let restData = await api
      .get(`/rest/chargepoints?stationId=${record._id}`)
      .then(({ data }) => {
        if (data && data.result) {
          return data.result.map((item, key) => {
            item.key = key;
            return item;
          });
        }
      });

    setSummary({ ...summary, ...stationInfo, connectors: restData });
    setLoading(false);
  };

  console.log(data);

  useEffect(() => {
    let timeout;

    if (isModalVisible) {
      timeout = setTimeout(() => {
        if (isModalVisible === false) setSummary({});
        if ((record && record._id) || stationId) showModal();
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  });
  useEffect(() => {
    setLoading(true);
    if (isModalVisible === false) setSummary({});
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const resetDevice = async (item_id) => {
    await api
      .get(`/rest/chargepoints/resetdevice/${item_id}`)
      .then(({ data }) => {});
  };

  const startCharge = async (unique_id, connector_id, rfid) => {
    if (rfid === '' || rfid === undefined || rfid === null) {
      console.log("if içeriside")
      setUserSelectError(true);
      return message.error('Kullanıcı RFID Numarası Alınamadı !');
    }

    const bearer_token = 'Bearer ' + Cookies.get('token');
    await api
      .post('/rest/startCharge', {
        unique_id,
        connector_id,
        user_token: bearer_token,
        rfid: rfid,
      })
      .then(({ data }) => {
        if (data) {
          setUserSelectError(false);
          message
            .loading('Şarj Başlatılıyor ...', 2.5)
            .then(() => message.warning(data.result_message.message, 2.5))
            .catch((err) => message.error(err));
        }
      })
      .catch((err) => {
        message.error('test ' + err.message);
      });
  };

  const stopCharge = async (devicesId, connector_id) => {
    const bearer_token = 'Bearer ' + Cookies.get('token');

    await api
      .post('/rest/stopCharge', {
        unique_id: devicesId,
        connector_id,
        user_token: bearer_token,
      })
      .then(({ data }) => {
        if (data) {
          message
            .loading('Şarj Durduruluyor ...', 2.5)
            .then(() => message.warning(data.result_message.message, 2.5));
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  return (
    <div className="istasyonmodal">
      <button
        className="summaryButton summaryButton2"
        style={{ color: '#000' }}
        onClick={() => {
          setIsModalVisible(true);
        }}
      >
        İzleme
      </button>

      {setIsModalVisible && (
        <Modal
          className="modal__info"
          title="Şarj İstasyonu Detayları"
          width={1050}
          visible={isModalVisible}
          footer={null}
          onOk={handleOk}
          onCancel={handleOk}
        >
          {loading && <Spin className="loading" />}
          <Tabs defaultActiveKey="1" centered>
            <TabPane tab="Şarj İstasyonu Bilgileri" key="1">
              <div className="row tabpane">
                <div style={{ marginLeft: '5rem' }} className="stationinfo">
                  <ul>
                    <li>
                      <p>
                        <b>Şarj İstasyonu Adı : </b>
                        {summary && summary.name}
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Şarj İstasyonu Konumu : Lat </b>{' '}
                        {summary &&
                          summary.coordinate &&
                          summary.coordinate.position &&
                          summary.coordinate.position.lat}{' '}
                        <b> Lng </b>{' '}
                        {summary &&
                          summary.coordinate &&
                          summary.coordinate.position &&
                          summary.coordinate.position.lng}{' '}
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>{languagesCms.ADRESS} : </b>{' '}
                        {summary &&
                          summary.coordinate &&
                          summary.coordinate.address}{' '}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Cihaz Bilgileri" key="2">
              <div style={{ margin: '5px' }}>
                {user && [1, 2, 3, 5, 51].includes(user.role) && (
                  <RfidPicker
                    name="rfid"
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    error={userSelectError}
                    tcView={true}
                  />
                )}
              </div>
              <div style={{ textAlign: 'center' }}>
                <div>
                  <Tabs defaultActiveKey="1" centered>
                    {summary &&
                      summary.connectors &&
                      summary.connectors.map((item, index) => {
                        return (
                          <TabPane
                            tab={item && item.name && item.name}
                            key={index + 1}
                          >
                            <div>
                              <Row
                                gutter={{
                                  xs: 8,
                                  sm: 16,
                                  md: 24,
                                  lg: 32,
                                }}
                              >
                                <Col span={6}>
                                  <div>
                                    <b>Cihaz Unique ID : </b>
                                    {item.unique_id}
                                  </div>
                                </Col>
                                <Col span={6}>
                                  <p>
                                    <b>Cihaz Adı : </b>
                                    {item.name}
                                  </p>
                                </Col>
                                <Col span={6}>
                                  <p>
                                    <b>Cihaz Konum Türü : </b>
                                    {item.location.location_type === 'Outdoor'
                                      ? 'Dışarıda'
                                      : 'İçeride'}
                                  </p>
                                </Col>

                                <Col span={6}>
                                  {!(
                                    auth &&
                                    auth
                                      .find((a) => a.number === user.role)
                                      .restraintList.includes('reset')
                                  ) && (
                                    <FirmwareUpdate
                                      name={
                                        record &&
                                        record.bayi &&
                                        record.bayi.bayiName
                                      }
                                      unique_id={item && item.unique_id}
                                    />
                                  )}
                                </Col>
                                <Col span={6}>
                                  <div>
                                    <b>Konnektör Tipi AC : </b>
                                    {item.typeAc ? item.typeAc : '0'}
                                  </div>
                                </Col>
                                <Col span={6}>
                                  <div>
                                    <b>Konnektör Tipi DC : </b>
                                    {item.typeDc ? item.typeDc : '0'}
                                  </div>
                                </Col>

                                <Col span={6}>
                                  <Link
                                    to={
                                      '/historyView/' +
                                      (item &&
                                        item.stationId &&
                                        item.stationId._id &&
                                        item.stationId._id)
                                    }
                                  >
                                    <Button
                                      type="dashed"
                                      danger
                                      style={{ borderRadius: '7px' }}
                                      icon={
                                        <i className="fa-solid fa-location-arrow"></i>
                                      }
                                    >
                                      {'Cihaz Raporları'}
                                    </Button>
                                  </Link>
                                </Col>
                                <Col span={6}>
                                  {!(
                                    auth &&
                                    auth
                                      .find((a) => a.number === user.role)
                                      .restraintList.includes('reset')
                                  ) && (
                                    <Popconfirm
                                      onConfirm={() =>
                                        resetDevice(item.unique_id)
                                      }
                                      title={
                                        languagesCms.THE_DEVICE_WILL_BE_RESET
                                      }
                                      okText={'Reset'}
                                      cancelText={'Cancel'}
                                    >
                                      <Button
                                        type="dashed"
                                        danger
                                        style={{ borderRadius: '7px' }}
                                        icon={<RedoOutlined />}
                                      >
                                        {'Reset'}
                                      </Button>
                                    </Popconfirm>
                                  )}
                                </Col>
                              </Row>

                              {/* -------------------------------------------------------------------------------------------------- */}
                              <Row direction="row">
                                {item.connector.map((element, index) => {
                                  return (
                                    <Col
                                      style={{
                                        marginBottom: '11px',
                                        boxShadow:
                                          'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
                                        padding: '3px',
                                      }}
                                      span={12}
                                    >
                                      <b>
                                        Konnektör No : {element.connector_id}{' '}
                                      </b>{' '}
                                      <br />
                                      <span style={{ color: 'red' }}>
                                        Cihaz Public Id :{' '}
                                        {element.devicePublicId}{' '}
                                      </span>{' '}
                                      <br />
                                      {element.type
                                        ? element.type
                                        : ' Type Bilinmiyor'}
                                      <b>{' - '}</b>
                                      {element.power
                                        ? `${element.power}  kW`
                                        : ' Güç Bilinmiyor'}{' '}
                                      <br />
                                      <div
                                        style={{
                                          fontWeight: 'bold',
                                          marginTop: '5px',
                                        }}
                                      >
                                        {!(
                                          auth &&
                                          auth
                                            .find((a) => a.number === user.role)
                                            .restraintList.includes(
                                              'startCharge',
                                            )
                                        ) && (
                                          <button
                                            type="button"
                                            disabled={
                                              ![
                                                'AVAILABLE',
                                                'PREPARING',
                                              ].includes(
                                                element.status.toLocaleUpperCase(
                                                  'en-US',
                                                ),
                                              )
                                            }
                                            className="btn btn-outline-success btn-sm me-2"
                                            onClick={() =>
                                              startCharge(
                                                item.unique_id,
                                                element.connector_id,
                                                data.rfid,
                                              )
                                            }
                                          >
                                            Start Şarj
                                          </button>
                                        )}
                                        {!(
                                          auth &&
                                          auth
                                            .find((a) => a.number === user.role)
                                            .restraintList.includes(
                                              'stopCharge',
                                            )
                                        ) && (
                                          <button
                                            disabled={
                                              element.status.toLocaleUpperCase(
                                                'en-US',
                                              ) === 'AVAILABLE'
                                            }
                                            type="button"
                                            className="btn btn-outline-danger btn-sm ms-2"
                                            onClick={() =>
                                              stopCharge(
                                                item.unique_id,
                                                element.connector_id,
                                              )
                                            }
                                          >
                                            Stop Şarj
                                          </button>
                                        )}
                                      </div>
                                      <span
                                        style={{
                                          fontWeight: '500',
                                          margin: 'auto',
                                        }}
                                      >
                                        {element &&
                                          element.status &&
                                          element.status.toLocaleUpperCase(
                                            'en-US',
                                          )}
                                      </span>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </div>
                          </TabPane>
                        );
                      })}
                  </Tabs>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Hizmetler" key="3">
              <div className="row tabpane">
                <div style={{ marginLeft: '5rem' }} className="stationinfo">
                  <Row
                    gutter={{
                      xs: 12,
                      sm: 12,
                      md: 12,
                    }}
                  >
                    <Col span={24}>
                      <div>
                        <h3
                          style={{
                            fontWeight: '900',
                          }}
                        >
                          Konum Hizmetleri
                        </h3>
                        <br />
                        <span style={{ fontWeight: '600' }}>
                          {summary &&
                            summary.locationServices &&
                            summary.locationServices.map((item, index) => {
                              return `${
                                languagesCms[item.toLocaleUpperCase()]
                              } ${
                                index === summary.locationServices.length - 1
                                  ? ' '
                                  : ' , '
                              }`;
                            })}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </TabPane>
            <TabPane tab="İletişim" key="4">
              <Contact id={record && record._id} />
            </TabPane>
          </Tabs>
        </Modal>
      )}
    </div>
  );
};

export default React.memo(StationModal);
