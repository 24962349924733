import React, { useEffect, useState, useGlobal } from 'reactn';
import { Button, Breadcrumb, message } from 'antd';
import api from '../../service/index';
import qs from 'qs';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Link, useHistory, useParams } from 'react-router-dom';
import { PlusOutlined, LeftOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/common/main.css";

const Reservation = (props) => {
  const params = useParams();
  let history = useHistory();
  let newRecord = {};
  let [data, setData] = useState(newRecord === {} ? [] : newRecord);
  let [isSmall] = useGlobal('isSmall');
  let [modules] = useGlobal('modules');
  let query = [];
  const [user] = useGlobal('user');  
  const [languagesCms] = useGlobal('languagesCms');
  const {auth} = useSelector(state => state.auth)
  const [times, setTimes] = useState(null)

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 1000 };
    }
    let shortString = 'name';
    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }
    let _params = { sort: shortString, ...page, query, 
      reservationList: { stationId: params && params.chargepoint } 
    };
    let restData = await api
      .get(`/rest/reservation?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          api.get("/rest/times")
            .then(({ data}) => {
              if (data.result) {
                setTimes(data.result)
              } else {
                message.error(data.result_message.message)
              }
            })
            .catch(err => {
              message.error(err.result_message.message ? err.result_message.message : "Rezervasyon Saatleri Okunamadı !" )
            })

          return data.result
        }
      });
    setData(restData);
  };

  useEffect(() => {
    if (modules) {
      get();
    }
  }, []);

  const arr = []
  if (data && data.length > 0) {
      data.map(async (item) => {
        if (item.timeId) {
          await item.timeId.forEach(s => {
            const time = times && times.find(t => t.timeId === s)
            let obj = {}
            obj.id = item._id 
            obj.title = item && item.chargePoint && item.chargePoint.name + ' - ' + item.connectorId
            obj.date = new Date(item.date + ' ' + (time && time.time))
            arr.push(obj)
          })
        }
      })
  }

  const events = arr;

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h3 style={{ display: 'inline-block', marginRight: '2rem' }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a style={{ color: '#fff', fontSize: '18px' }} href="/reservation">
                  {"Şarj İstasyonları"}
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item style={{ color: '#fff', fontSize: '18px' }}>
                Rezervasyonlar
              </Breadcrumb.Item>
            </Breadcrumb>
          </h3>
        </div>
        <div className="list-buttons">
          {!(auth && auth.find(a => a.number === user.role).restraintList.includes( "add" )) && (
            <Link to={`/reservation/${params.chargepoint}/add`} style={{ marginRight: '5px' }}>
              <Button
                type="light"
                style={{ fontSize: '14px', height: '100%' }}
                icon={<PlusOutlined />}
                size="large"
              >
                {!isSmall && "Rezervasyon Yap"}
              </Button>
            </Link>
          )}
         
          <Link to="/reservation">
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>

      <div className="calendar">
        <FullCalendar
          code={'tr-tr'}
          defaultView="dayGridMonth"
          weekends={true}
          headerToolbar={{
            center: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          buttonText={{
            today: 'Bugün',
            month: 'Ay',
            week: 'Hafta',
            day: 'Gün',
            list: 'Liste',
            prev: 'Önceki',
            next: 'Sonraki',
            prevYear: 'Önceki Yıl',
            nextYear: 'Sonraki Yıl',
            nextMonth: 'Sonraki Ay',
            prevMonth: 'Önceki Ay',
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          // ilerleyen aşamada yeni buton eklemek istenirse
          // customButtons={{
          //   new: {
          //     text: 'new',
          //     click: () => console.log('new event'),
          //   },
          // }}
          nowIndicator={true}
          eventColor="red"
          locale={"tr"}
          eventClick={(e) => history.push(`/reservation/${params.chargepoint}/edit/${e.event.id}`)}
        />
      </div>
    </div>
  );
};

export default Reservation;
