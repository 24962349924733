import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Popconfirm, Breadcrumb, message } from 'antd';
import api from '../../service/index';
import { Link, useParams } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

const PriceList = (props) => {
  const params = useParams();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isSmall] = useGlobal('isSmall');
  const [languagesCms] = useGlobal('languagesCms');
  let query = [];
  const [user] = useGlobal('user');
  const { auth } = useSelector((state) => state.auth);

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'startDate';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }
    let _params = { sort: shortString, ...page, query };

    let restData = await api
      .get(`/rest/price-list/${params.id}?${qs.stringify(_params)}`, {
        _params,
      })
      .then(({ data }) => {
        if (data && data.result) {
          console.log(data.result);
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            item.key = key;
            return item;
          });
        } else
          message.error(
            'Fiyat listesi getirilemedi. Lütfen kısa bir süre sonra tekrar deneyiniz.',
          );
      });
    setData(restData);
  };

  useEffect(() => {
    setLoading(true);
    get();
  }, []);

  const deleteRow = async (item_id) => {
    api.delete(`/rest/prices/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };

  function turkishSort(a, b) {
    var alfabe =
      'AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789';
    if (a.length === 0 || b.length === 0) {
      return a.length - b.length;
    }
    for (var i = 0; i < a.length && i < b.length; i++) {
      var ai = alfabe.indexOf(a[i]);
      var bi = alfabe.indexOf(b[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }

  console.log(data);

  const columns = [
    {
      title: 'Başlangıç Tarihi',
      dataIndex: 'startDate',
      align: 'center',
      key: 'key',
      sorter: (a, b) => a.startDate - b.startDate,
    },
    {
      title: 'Bitiş Tarihi',
      dataIndex: 'endDate',
      align: 'center',
      key: 'key',
      sorter: (a, b) => a.endDate - b.endDate,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.PRICE,
      dataIndex: 'price',
      align: 'center',
      key: 'key',
      sorter: (a, b) => turkishSort(a.price, b.price),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) =>
        text && (
          <span style={{ color: 'green', fontWeight: '700' }}>
            {text} TL / kWh
          </span>
        ),
    },
    {
      title: languagesCms.ACTIONS,
      align: 'center',
      width: 150,
      key: 'key',
      render: (text, record) => (
        <Space size="small">
          {!(
            auth &&
            auth
              .find((a) => a.number === user.role)
              .restraintList.includes('edit')
          ) && (
            <Link to={`/price-list/edit/${params.id}/${record._id}`}>
              <Button icon={<EditOutlined />}>
                {!isSmall && languagesCms.EDIT}
              </Button>
            </Link>
          )}
          {!(
            (auth &&
              auth
                .find((a) => a.number === user.role)
                .restraintList.includes('delete')) ||
            record.delete === false
          ) && (
            <Popconfirm
              onConfirm={() => deleteRow(record._id)}
              title={languagesCms.CHECK_DELETE}
              okText={languagesCms.OK_TEXT}
              cancelText={languagesCms.CANCEL_TEXT}
            >
              <Button type="danger" icon={<DeleteOutlined />}>
                {!isSmall && languagesCms.DELETE}
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <Breadcrumb style={{ fontSize: '1.3rem' }}>
            <Breadcrumb.Item style={{ color: '#fff' }}>
              Fiyat Listesi
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="list-buttons">
          <Link to="/prices" style={{ marginRight: '5px' }}>
            <button
              type="button"
              className="btn btn-danger btn-lg"
              style={{ fontSize: '14px', height: '100% ' }}
            >
              <i class="fa-solid fa-arrow-left-long"></i>
              {!isSmall && ' Tarifeler'}
            </button>
          </Link>
          {!(
            auth &&
            auth
              .find((a) => a.number === user.role)
              .restraintList.includes('add')
          ) && (
            <Link
              to={`/price-list/add/${params.id}`}
              style={{ marginRight: '5px' }}
            >
              <Button
                type="light"
                icon={<PlusOutlined />}
                size={'large'}
                style={{
                  fontSize: '14px',
                  height: '100%',
                  borderRadius: '7px',
                }}
              >
                {!isSmall && 'Fiyat Ekle'}
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default PriceList;
