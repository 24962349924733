import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Select,
  Alert,
  Breadcrumb,
  DatePicker,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { FileInputSingle, DealerPicker, StationPicker } from '../../components';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/tr_TR';

const { Option } = Select;

const ManagerDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let [languagesCms] = useGlobal('languagesCms');
  let [langCms] = useGlobal('langCms');
  let [langCode] = useGlobal('langCode');
  let [user] = useGlobal('user');
  let [authorizations] = useGlobal('authorizations');

  let newRecord = {
    name: '',
    lastname: '',
    role: 5,
    phone: '',
    email: '',
    tc: '',
    lang: langCode ? langCode : langCms ? langCms[0].code : '',
    cardNumber: '',
    credit: '0.0',
    stationId: [],
    dealerId: [],
    cpoAdminId: [],
    mspAdminId: [],
    postCode: '',
    car: '',
    dateOfBirth: '',
    userGroup: 'admin',
    userType: 'admin',
  };
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [isSmall] = useGlobal('isSmall');

  let [modules] = useGlobal('modules');
  //onetime run
  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/managers/' + id)
        .then(({ data: { result, result_message } }) => {
          delete result.password;
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.name === null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED;

    if (
      data.lastname === null ||
      data.lastname === undefined ||
      data.lastname === ''
    )
      errors.lastname = languagesCms.ENFORCED;

    if (
      data.phone === '' ||
      data.phone === null ||
      (data && data.phone && data.phone.length === 0)
    )
      errors.phone = languagesCms.ENFORCED;

    if (
      data.role === null ||
      data.role === '' ||
      [7].includes(data.role) === true
    )
      errors.role = languagesCms.ENFORCED;

    if (!id && (data.tc === null || data.tc === '' || data.tc === undefined))
      errors.tc = languagesCms.ENFORCED;

    if (
      data.dateOfBirth === null ||
      data.dateOfBirth === '' ||
      data.dateOfBirth === undefined
    )
      errors.dateOfBirth = languagesCms.ENFORCED;

    if (
      data.email === null ||
      data.email.length === undefined ||
      data.email === ''
    )
      errors.email = languagesCms.ENFORCED;

    if (
      data.role === 6 &&
      data &&
      data.stationId &&
      data.stationId.length === 0
    )
      errors.stationId = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  console.log(data)

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        if (data.phone && !data.phone.startsWith('+')) {
          data.phone = '+' + data.phone;
        }
        api
          .put('/rest/attendees/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/managers');
            } else message.error(result_message.message, 2);
          });
      } else {
        if (data.phone && !data.phone.startsWith('+')) {
          data.phone = '+' + data.phone;
        }
        api
          .post('/rest/attendees', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/managers');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h3 style={{ marginBottom: '8px' }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a style={{ color: '#fff', fontSize: '18px' }} href="/managers">
                  Yöneticiler
                </a>
              </Breadcrumb.Item>
              {id && (
                <Breadcrumb.Item style={{ color: '#fff', fontSize: '18px' }}>
                  {data.name + ' ' + data.lastname}
                </Breadcrumb.Item>
              )}
            </Breadcrumb>
          </h3>
        </div>
        <div className="list-buttons">
          <Link to="/managers">
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form
            layout="horizontal"
            disabled={
              authorizations &&
              authorizations
                .find((a) => a.number === user.role)
                .restraintList.includes('edit')
            }
            size={'large'}
            onFinish={save}
          >
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Avatar"
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <FileInputSingle
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="avatar"
                  />
                  <Alert
                    message="Avatar 1024 x 1024 çözünürlüğünde olmalıdır."
                    banner
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Rol"
                  required
                  help={errors.role}
                  validateStatus={errors.role ? 'error' : 'success'}
                >
                  <Select
                    defaultValue={data.role ? data.role : 7}
                    style={{ width: 120 }}
                    onChange={(v) => {
                      setData({ ...data, role: v });
                    }}
                  >
                    {(user.role === 3 || user.role === 5) && (
                      <>
                        <Option value={5}>Tesis Admini</Option>
                        <Option value={51}>Arge Admin</Option>
                      </>
                    )}
                    {user.role === 2 && (
                      <>
                        <Option value={5}>Tesis Admini</Option>
                        <Option value={51}>Arge Admin</Option>
                        <Option value={4}>Çağrı Merkezi Admin</Option>
                        <Option value={3}>CPO Admin</Option>
                      </>
                    )}
                    {user.role === 1 && (
                      <>
                        <Option value={5}>Tesis Admini</Option>
                        <Option value={51}>Arge Admin</Option>
                        <Option value={4}>Çağrı Merkezi Admin</Option>
                        <Option value={3}>CPO Admin</Option>
                        <Option value={2}>MSP Admin</Option>
                      </>
                    )}
                  </Select>
                  <br />
                  <small> {languagesCms.ADMIN_INFO_MESSAGE}</small>
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.PHONE}
                  required
                  help={errors.phone}
                  validateStatus={errors.phone ? 'error' : 'success'}
                >
                  <PhoneInput
                    width={'100%'}
                    country={'tr'}
                    value={data.phone}
                    onChange={(e) => setData({ ...data, phone: e })}
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Doğum Tarihiniz'}
                  required
                  help={errors.dateOfBirth}
                  validateStatus={errors.dateOfBirth ? 'error' : 'success'}
                >
                  <DatePicker
                    locale={locale}
                    format="DD-MM-YYYY"
                    onChange={(date, dateString) => {
                      setData({ ...data, dateOfBirth: dateString });
                    }}
                    placeholder="Tarih"
                    defaultValue={
                      data &&
                      (data.dateOfBirth
                        ? moment(data.dateOfBirth, 'DD-MM-YYYY')
                        : moment('01-01-2000', 'DD-MM-YYYY'))
                    }
                  />
                </Form.Item>
              </Col>

              {data.role && [3, 5, 6].includes(data.role) === true && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={'Tesis Seçiniz'}
                    // required
                    // help={errors.dealerId}
                    // validateStatus={errors.dealerId ? 'error' : 'success'}
                  >
                    <DealerPicker
                      languagesCms={languagesCms}
                      record={data}
                      setRecord={setData}
                      name="dealerId"
                      mode="multiple"
                      dataLimit={data && data.role === 3 ? "allData" : ""}
                    />
                  </Form.Item>
                </Col>
              )}
              {data.role === 6 && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={'Şarj İstasyonu'}
                    help={errors.stationId}
                    required
                    validateStatus={errors.stationId ? 'error' : 'success'}
                  >
                    <StationPicker
                      languagesCms={languagesCms}
                      record={data}
                      setRecord={setData}
                      name="stationId"
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
              )}

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.NAME}
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.LASTNAME}
                  required
                  help={errors.lastname}
                  validateStatus={errors.lastname ? 'error' : 'success'}
                >
                  <Input
                    name="lastname"
                    value={data.lastname}
                    onChange={(e) =>
                      setData({ ...data, lastname: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.EMAIL}
                  required
                  help={errors.email}
                  validateStatus={errors.email ? 'error' : 'success'}
                >
                  <Input
                    name="email"
                    value={data.email}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              {!id && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={languagesCms.TC}
                    required
                    help={errors.tc}
                    name="tc"
                    rules={[
                      {
                        min: 11,
                        message: 'Bu alan 11 karakter olmalıdır !',
                      },
                      {
                        max: 11,
                        message: 'Bu alan 11 karakter olmalıdır !',
                      },
                    ]}
                    validateStatus={errors.tc ? 'error' : 'success'}
                  >
                    <Input
                      name="tc"
                      type="number"
                      value={data.tc}
                      onChange={(e) => setData({ ...data, tc: e.target.value })}
                    />
                  </Form.Item>
                </Col>
              )}

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.CREATE_NEW_PASSWORD}
                  help={errors.password}
                  validateStatus={errors.password ? 'error' : 'success'}
                >
                  <Input
                    name="password"
                    onChange={(e) =>
                      setData({ ...data, password: e.target.value })
                    }
                  />
                  <br />
                  <small>{languagesCms.PASSWORD_LEAVE_BLANK}</small>
                  <br />
                  <small>{languagesCms.PASSMESSAGE}</small>
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row" className="manager-btn">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default ManagerDetail;
