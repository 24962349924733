import React, { useEffect , useState} from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { Button, message, Modal } from 'antd';
import api from '../../service';

// const CustomizedLabel = ({ x, y, stroke, value }) => {
//   const { x, y, stroke, value } = props;

//   return (
//     <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
//       {value}
//     </text>
//   );
// };

const CustomizedAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const meterValue = ({chargeId}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (isModalOpen === true) get();
  }, [isModalOpen]);

  const get = () => {
    api
      .get('/rest/temperature-charts/' + chargeId)
      .then(({ data }) => {
        console.log(data);
        if (data && data.result) {
          setData(data.result);
        } else message.error('Grafik Oluşturulamadı Lütfen Tekrar Deneyiniz !');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Button
        style={{
          borderRadius: '9px',
          padding: '0 15px',
          backgroundColor: '#f5f7f6',
          color: '#000',
          border: 'solid 1px red',
        }}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        Grafikleri Görüntüle
      </Button>
      <Modal
        title="Grafikler"
        okText="Kapat"
        open={isModalOpen}
        style={{
          width: "65vh !important"
        }}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <LineChart
          width={720}
          height={450}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Time" height={60} tick={<CustomizedAxisTick />} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="VoltageL1" stroke="#3b82f6">
            {/* <LabelList content={<CustomizedLabel />} /> */}
          </Line>
          <Line type="monotone" dataKey="VoltageL2" stroke="#497174" />
          <Line type="monotone" dataKey="VoltageL3" stroke="#000000" />
          <Line type="monotone" dataKey="Temperature" stroke="red" />
          <Line type="monotone" dataKey="AmperL1" stroke="#FF9F29" />
          <Line type="monotone" dataKey="AmperL2" stroke="#78350f" />
          <Line type="monotone" dataKey="AmperL3" stroke="#285430" />
        </LineChart>
      </Modal>
    </>
  );
};

export default React.memo(meterValue);
