import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm, message } from 'antd';
import moment from 'moment';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

const Attendee = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [excelData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let [module] = modules.filter((el) => el._id === path);
  let [user] = useGlobal('user');
  const {auth} = useSelector(state => state.auth)

  let query = [];
  query['deleted'] = false;

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name, email, phone';
    }

    let restData = await api
      .get(`/rest/attendees?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.map((item, key) => {
            if (item.isLogin)
              item.isLogin = <div className="true-circle"></div>;
            else item.isLogin = <div className="false-circle"></div>;

            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/attendees/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };

  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/attendees/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  function turkishSort(a, b) {
    var alfabe =
      'AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789';
    if (a.length === 0 || b.length === 0) {
      return a.length - b.length;
    }
    for (var i = 0; i < a.length && i < b.length; i++) {
      var ai = alfabe.indexOf(a[i]);
      var bi = alfabe.indexOf(b[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }

  let columns = [
    {
      title: languagesCms.NAME,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => turkishSort(a.name, b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.LASTNAME,
      dataIndex: 'lastname',
      key: 'lastname',
      sorter: (a, b) => turkishSort(a.lastname, b.lastname),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.EMAIL,
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email - b.email,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.PHONE,
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a.phone - b.phone,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'center',
      width: 150,

      render: (text, record) => (
        <Space size="small">
          { !(auth && auth.find(a => a.number === user.role).restraintList.includes( "edit" )) && (
            <Link to={'/attendees/edit/' + record._id}>
              <Button 
                icon={<EditOutlined />}>
                  {!isSmall && languagesCms.EDIT}
              </Button>
            </Link>
          )}
          {!(auth && auth.find(a => a.number === user.role).restraintList.includes( "delete" )) && (
            <Popconfirm
              onConfirm={() => deleteRow(record._id)}
              title={languagesCms.CHECK_DELETE}
              okText={languagesCms.OK_TEXT}
              cancelText={languagesCms.CANCEL_TEXT}
            >
            <Button 
              type="danger" icon={<DeleteOutlined />}>
              {!isSmall && languagesCms.DELETE}
            </Button>
          </Popconfirm>
          )}
          
        </Space>
      ),
    },
  ];

  let parseDateExcel = (excelTimestamp) => {
    const secondsInDay = 24 * 60 * 60;
    const excelEpoch = new Date(1899, 11, 31);
    const excelEpochAsUnixTimestamp = excelEpoch.getTime();
    const missingLeapYearDay = secondsInDay * 1000;
    const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
    const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
    const parsed = excelTimestampAsUnixTimestamp + delta;
    return isNaN(parsed) ? null : parsed;
  };

  let uploadExcel = async () => {
    let attendee_id;
    let userData;
    let updateCount = 0;
    let insertCount = 0;
    if (excelData) {
      for (let i in excelData) {
        attendee_id = excelData[i].id;

        if (typeof excelData[i].birthday === 'number') {
          excelData[i].birthday = moment(parseDateExcel(excelData[i].birthday))
            .add(12, 'hours')
            .format();
        }

        userData = {
          name: excelData[i].name,
          lastname: excelData[i].lastname || '',
          email: excelData[i].email || '',
          phone: excelData[i].phone || '',
          location: excelData[i].city || '',
          birthday: excelData[i].birthday || '',
        };

        if (attendee_id) {
          // update
          await api
            .put('/rest/attendees/' + attendee_id, userData)
            .then(({ data: { result, result_message } }) => {});
          updateCount++;
        } else {
          // insert
          userData['role'] = 7;
          userData['password'] = 'passVeli';

          await api
            .post('/rest/attendees', userData)
            .then(({ data: { result, result_message } }) => {});
          insertCount++;
        }
      }
      if (updateCount || insertCount) {
        message.error(
          'Excel Yüklendi. ' +
            updateCount +
            ' Güncellendi, ' +
            insertCount +
            ' Kayıt Eklendi.',
          2,
        );
      }
    }
  };

  useEffect(() => {
    uploadExcel();
  }, [excelData]);

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}{' '}
          </h1>
        </div>
        <div className="list-buttons">
          {(!(auth && auth.find(a => a.number === user.role).restraintList.includes( "delete" )) && !isSmall) && 
                <Button
                  type="danger"
                  icon={<DeleteOutlined />}
                  size="large"
                  onClick={deleteSelectedRows}
                  style={{ fontSize: '14px', height: '100%', marginRight: '10px' }}
                >
                  {languagesCms.DELETE_SELECTED}
                </Button>
           }
         
          {!(auth && auth.find(a => a.number === user.role).restraintList.includes( "add" )) && (
            <Link to="/attendees/add" style={{ marginRight: '5px' }}>
              <Button
                type="light"
                style={{ fontSize: '14px', height: '100%' }}
                icon={<PlusOutlined />}
                size="large"
              >
                {!isSmall && "Kullanıcı Ekle"}
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder="Arama..."
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default Attendee;
