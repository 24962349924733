import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm } from 'antd';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { LoadingAnimation, StationModal } from '../../components';
import { setAlert } from '../../stores/alert';

const Alert = (props) => {
  const { Search } = Input;
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(false);

  const [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  const [isSmall] = useGlobal('isSmall');
  const [user] = useGlobal('user');
  const [auth] = useGlobal('auth');

  const path = props.location.pathname.split('/')[1];
  let [module] = modules.filter((el) => el._id === path);

  let query = [];
  query['deleted'] = false;

  const handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'chargeBoxId';
    }

    const restData = await api
      .get(`/rest/alert?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data && data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return (
            data.result &&
            data.result.rows &&
            data.result.rows.map((item, key) => {
              item.key = key;
              return item;
            })
          );
        }
      });
    setData(restData);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  const onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  const columns = [
    {
      title: 'Şarj İstasyonu',
      dataIndex: 'alertLevel',
      key: 'alertLevel',
      align: 'center',
      sorter: (a, b) => a.alertLevel - b.alertLevel,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => record && record.stationName,
    },
    {
      title: 'Bayi',
      dataIndex: 'alertLevel',
      key: 'alertLevel',
      align: 'center',
      sorter: (a, b) => a.alertLevel - b.alertLevel,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => record && record.dealerName,
    },
    {
      title: 'Cihaz Id',
      dataIndex: 'chargeBoxId',
      key: 'chargeBoxId',
      align: 'center',
    },
    {
      title: 'Soket Id',
      dataIndex: 'connectorId',
      key: 'connectorId',
      align: 'center',
      render: (text, record) => (
        <span
          style={{
            color: 'red',
          }}
        >
          {record && record.msgContent && record.msgContent.connectorId}
        </span>
      ),
    },
    {
      title: 'Uyarı Türü',
      dataIndex: 'msgName',
      align: 'center',
      key: 'msgName',
      sorter: (a, b) => a.msgName - b.msgName,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Hata Türü',
      dataIndex: 'errorCode',
      key: 'errorCode',
      align: 'center',
      sorter: (a, b) => a.errorCode - b.errorCode,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <span style={{ color: 'red' }}>{text}</span>,
    },
    {
      title: 'Cihaz Durumu',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text, record) => (
        <span style={{ color: 'red' }}>
          {record && record.msgContent && record.msgContent.status}
        </span>
      ),
    },
    {
      title: 'Hata Zamanı',
      dataIndex: 'createdAt',
      align: 'center',
      key: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      sortDirections: ['descend', 'ascend'],
      render: (text) => moment(text).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Space size="small">
          <StationModal
            languagesCms={languagesCms}
            stationId={record && record.stationId}
          />

          <Link to={'/alert/' + record._id}>
            <Button
              style={{
                backgroundColor: '#f5f7f6',
                color: '#000',
                border: 'solid 1px #00BD6D',
                borderRadius: '7px',
              }}
              icon={<i className="fa-solid fa-charging-station"></i>}
            >
              Detay
            </Button>
          </Link>
          {!(
            auth &&
            auth
              .find((a) => a.number === user.role)
              .restraintList.includes('delete')
          ) && (
            <Popconfirm
              onConfirm={() => {
                deleteRow(record._id);
              }}
              title={languagesCms.CHECK_DELETE}
              okText={languagesCms.OK_TEXT}
              cancelText={languagesCms.CANCEL_TEXT}
            >
              <Button type="danger" icon={<DeleteOutlined />}>
                {!isSmall && languagesCms.DELETE}
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const deleteRow = async (item_id) => {
    await api.delete(`/rest/alert/delete/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };

  const deleteAllRecords = async () => {
    setLoading(true);

    await api
      .delete(`/rest/alert/delete-all`, ({ data }) => {})
      .then(async () => {
        await dispatch(setAlert(null));
      })
      .finally(() => {
        setLoading(false);
        get();
      });
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}{' '}
          </h1>
        </div>
        <div className="list-buttons">
          {!isSmall &&
            !(
              !user.role <= 2 &&
              auth &&
              auth
                .find((a) => a.number === user.role)
                .restraintList.includes('delete')
            ) && (
              <Popconfirm
                onConfirm={deleteAllRecords}
                style={{
                  marginRight: '10px',
                  fontSize: '14px',
                  height: '100%',
                }}
                title={'Tüm Veriler Silinsin mi ?'}
                okText={'Onayla'}
                cancelText={'Vazgeç'}
              >
                <Button type="danger" icon={<DeleteOutlined />}>
                  {!isSmall && 'Tüm Alertleri Sil'}
                </Button>
              </Popconfirm>
            )}
          <Button
            type="light"
            className="ms-3"
            onClick={() => {
              setData([]);
              setTimeout(() => {
                get();
              }, 50);
            }}
            icon={<i className="fa-solid fa-arrows-rotate"></i>}
          >
            Yenile
          </Button>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder="Arama..."
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <LoadingAnimation />,
            size: 'small',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          size="small"
        />
      </div>
    </div>
  );
};

export default Alert;
