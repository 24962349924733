import React, { useState } from 'react';
import { Modal, DatePicker } from 'antd';
import moment from 'moment';
import './userCharge.css';

const { confirm } = Modal;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const userChargeFilterModal = ({ setDateRange }) => {
  const [data, setData] = useState();
  const showDeleteConfirm = () => {
    confirm({
      title: 'Lütfen Filtrelemek İstediğiniz Tarihleri Seçin',
      icon: (
        <i
          style={{ fontSize: '2rem', color: 'red' }}
          className="fa-solid fa-list-check"
        ></i>
      ),
      content: (
        <>
          <RangePicker
            defaultValue={[
              moment(new Date(), dateFormat),
              moment(new Date(), dateFormat),
            ]}
            onChange={(date, dateString) => {
              setData(dateString);
            }}
            format={dateFormat}
          />
        </>
      ),
      okText: 'Filitrele',
      okType: 'danger',
      cancelText: 'Vazgeç',
      onOk() {
        setDateRange(data);
      },
      onCancel() {
      },
    });
  };

  return (
    <div>
      {' '}
      <button
        type="button"
        className="btn btn-danger me-3"
        onClick={showDeleteConfirm}
      >
        <i className="fa-solid fa-sliders"></i> &nbsp;Filtrele
      </button>
    </div>
  );
};

export default userChargeFilterModal;
