import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Button,
  Card,
  message,
  Space,
  DatePicker,
  Breadcrumb,
  Popconfirm,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined, CloseOutlined } from '@ant-design/icons';
import {
  ChargePointPicker,
  ConnectorPicker,
  TimePicker,
} from '../../components';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/tr_TR';

const ReservationDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let [languagesCms] = useGlobal('languagesCms');

  let newRecord = {
    date: '',
    timeId: '',
    connectorId: '',
    chargepointId: '',
  };
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  const [user, setUser] = useState([]);

  let [modules] = useGlobal('modules');

  //onetime run
  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/reservation/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          getUser(result.attendee._id);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.date === '' || (data && data.date && data.date.length === 0))
      errors.date = languagesCms.ENFORCED;

    if (data.timeId === [] || (data && data.timeId && data.timeId.length === 0))
      errors.timeId = languagesCms.ENFORCED;

    if (
      data.stationId === '' ||
      (data && data.stationId && data.stationId.length === 0)
    )
      errors.stationId = languagesCms.ENFORCED;

    if (
      data.connectorId === '' ||
      (data && data.connectorId && data.connectorId.length === 0)
    )
      errors.connectorId = languagesCms.ENFORCED;

    if (
      data.chargepointId === '' ||
      (data && data.chargepointId && data.chargepointId.length === 0)
    )
      errors.chargepointId = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  const getUser = useCallback(
    (userId) => {
      api
        .get(`/rest/managers/${userId}`)
        .then(({ data: { result, result_message } }) => {
          setUser(result);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [user],
  );

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/reservation/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success("Rezervasyon Güncellendi.", 2);
              history.push(`/reservation/${params.chargepoint}`);
            } else message.error(result_message.message, 2);
          });
      } else {
        api
          .post('/rest/reservation', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success("Rezervasyon Oluşturuldu.", 2);
              history.push(`/reservation/${params.chargepoint}`);
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  const cancelReservation = (reservationId) => {
    api
      .get('/rest/reservation/cancel/' + reservationId)
      .then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          message.success("Rezervasyon Silindi.", 2);
          history.push(`/reservation/${params.chargepoint}`);
        } else message.error(result_message.message, 2);
      });
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h2 style={{ color: '#000' }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a
                  style={{ color: '#fff', fontSize: '18px' }}
                  href="/reservation"
                >
                  {'Şarj İstasyonları'}
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a
                  style={{ color: '#fff', fontSize: '18px' }}
                  href={`/reservation/${params.chargepoint}`}
                >
                  {'Rezervasyonlar'}
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item style={{ color: '#fff', fontSize: '18px' }}>
                {id ? 'Rezervasyon Detay' : 'Rezervasyon Ekle'}
              </Breadcrumb.Item>
            </Breadcrumb>
          </h2>
        </div>
        <div className="list-buttons">
          {id && (
            <>
              <Popconfirm
                onConfirm={() => cancelReservation(params.id)}
                title={'Rezervasyonu İptal Et'}
                okText={languagesCms.OK_TEXT}
                cancelText={languagesCms.CANCEL_TEXT}
              >
                <Button
                  type="danger"
                  style={{ marginRight: '5px' }}
                  icon={<CloseOutlined />}
                  size={'large'}
                >
                  Rezervasyonu İptal Et
                </Button>
              </Popconfirm>
            </>
          )}

          <Link to={`/reservation/${params.chargepoint}`}>
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            {id ? (
              <>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <p
                      style={{
                        margin: '1rem',
                        fontSize: '1rem',
                        fontWeight: '500',
                      }}
                    >
                      Kullanıcı Adı:{' '}
                    </p>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <p
                      style={{
                        margin: '1rem',
                        fontSize: '1rem',
                        fontWeight: '500',
                      }}
                    >
                      {user.name + ' ' + user.lastname}
                    </p>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <p
                      style={{
                        margin: '1rem',
                        fontSize: '1rem',
                        fontWeight: '500',
                      }}
                    >
                      Rezervasyon Oluşturulma Tarihi:{' '}
                    </p>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <p
                      style={{
                        margin: '1rem',
                        fontSize: '1rem',
                        fontWeight: '500',
                      }}
                    >
                      {moment(data && data.createdAt).format(
                        'YYYY : MM : DD HH:mm:ss',
                      )}
                    </p>
                  </Col>
                </Row>
              </>
            ) : null}

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Space direction="vertical" size={12}>
                  <Form.Item
                    label="Tarih"
                    help={errors.date}
                    validateStatus={errors.date ? 'error' : 'success'}
                  >
                    <DatePicker
                      locale={locale}
                      disabledDate={(e) => e < moment().add(-1, 'days')}
                      format="YYYY-MM-DD"
                      onChange={(date, dateString) => {
                        setData({ ...data, date: dateString });
                      }}
                      placeholder="Tarih"
                      defaultValue={
                        data &&
                        (data.date
                          ? moment(data.date, 'YYYY-MM-DD')
                          : moment(new Date(), 'YYYY-MM-DD'))
                      }
                    />
                  </Form.Item>
                </Space>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Saat"
                  help={errors.time}
                  validateStatus={errors.time ? 'error' : 'success'}
                >
                  <TimePicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="timeId"
                    mode="multiple"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={'Cihaz'}
                  help={errors.chargepointId}
                  validateStatus={errors.chargepointId ? 'error' : 'success'}
                >
                  <ChargePointPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="chargepointId"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={'Konnektör'}
                  help={errors.connectorId}
                  validateStatus={errors.connectorId ? 'error' : 'success'}
                >
                  <ConnectorPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="connectorId"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row" className="manager-btn">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {id ? 'Güncelle' : languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default ReservationDetail;
