import React from 'react';

const CallCenter = () => {
  return (
    <div className="statistics">
      <div className="repair-page">
        <img src="/images/chargecar.png" alt="repair" />
      </div>
      <div className="repair-text">
        <h1>Bu sayfa yapım aşamasındadır... </h1>
      </div>
    </div>
  );
};

export default CallCenter;
