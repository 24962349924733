import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import { Modal, Form, Col, Row, Button } from 'antd';
import LoadingAnimation from '../LoadingAnimation';
import DatePicker from '../Date/DatePicker';
import moment from 'moment';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

const UserChargeHistory = ({ userId, iconClass, size, className }) => {
  const history = useHistory();
  const [isSmall] = useGlobal('isSmall');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const firstData = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };
  const [resData, setResData] = useState(firstData);

  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(false);
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setLoading(false);
    setResData(firstData);
    setIsModalOpen(false);

    history.push({
      pathname: `/facility-charge/${userId}`,
      search: qs.stringify(resData),
    });
  };
  const handleCancel = () => {
    setLoading(false);
    setResData(firstData);
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        type="button"
        danger
        className={className || "btn btn-danger btn-sm py-0 me-3"}
        size={size || 'large'}
        onClick={showModal}
      >
        <i className={iconClass || `fa-solid fa-clock-rotate-left`}></i>
        {!isSmall && 'Şarj Raporları'}
      </Button>

      <Modal
        title={'Şarj Rapor'}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ borderRadius: '21px !important' }}
        okText={'Filtrele'}
        cancelText={'Vazgeç'}
      >
        <>
          {loading ? (
            <LoadingAnimation />
          ) : (
            <>
              <Form
                form={form}
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                autoComplete="off"
              >
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label={'Başlangıç Tarihi'}
                      labelCol={{
                        span: 24,
                      }}
                      required
                    >
                      <DatePicker
                        record={resData}
                        setRecord={setResData}
                        name="startDate"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label={'Bitiş Tarihi'}
                      labelCol={{
                        span: 24,
                      }}
                      required
                    >
                      <DatePicker
                        record={resData}
                        setRecord={setResData}
                        name="endDate"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </>
      </Modal>
    </>
  );
};

export default React.memo(UserChargeHistory);
