import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import { Button, Card, Table } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import moment from 'moment';
import qs from 'qs';
import { LoadingAnimation } from '../../components';
import GetReports from '../../components/GetReports';

const RfidDetail = (props) => {
  const params = useParams();
  const id = params.id !== 'add' ? params.id : false;
  const [languagesCms] = useGlobal('languagesCms');

  const [data, setData] = useState([]);
  const [rfidInfo, setRfidInfo] = useState(null);
  const [query, setQuery] = useState({});
  const [loading, setLoading] = useState(id ? true : false);
  const [isSmall] = useGlobal('isSmall');
  const [modules] = useGlobal('modules');
  let [totalCount, setTotalCount] = useState(0);

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter, query) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    let _params = { sort: shortString, ...page, ...query };

    api
      .get(`/rest/rfid-transaction-log/${id}?${qs.stringify(_params)}`)
      .then(({ data }) => {
        if (data && data.result) {
          setData(data && data.result && data.result.rows);
          setTotalCount(data.result.total);
          setRfidInfo(data && data.result && data.result.rfid);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (modules && id) {
      const urlParams = new URLSearchParams(window.location.search);
      setLoading(true);

      const query = {
        startDate: urlParams.get('startDate'),
        endDate: urlParams.get('endDate'),
      };
      get(undefined, undefined, undefined, query);
      setQuery(query);
    }
  }, [id]);

  const columns = [
    {
      title: 'Kullanıcı',
      dataIndex: 'userName',
      key: 'userName',
      align: 'center',
    },
    {
      title: 'İşlem Tipi',
      dataIndex: 'transactionType',
      key: 'userName',
      align: 'center',
      render: (text) =>
        text === 'update'
          ? 'Bakiye Yükleme'
          : text === 'charge'
          ? 'Şarj İşlemi'
          : text === 'delete'
          ? 'Silme'
          : text === 'create'
          ? 'Ekleme'
          : text,
    },
    {
      title: 'İşlem Tutarı',
      dataIndex: 'transactionPrice',
      key: 'transactionPrice',
      align: 'center',
      render: (text) =>
        text ? <span className="text-primary">{text} TL</span> : '0',
    },
    {
      title: 'İşlem Öncesi Bakiye',
      dataIndex: 'oldPrice',
      key: 'oldPrice',
      align: 'center',
      render: (text) =>
        text ? <span className="text-danger">{text} TL</span> : '0',
    },
    {
      title: 'İşlem Sonrası Bakiye',
      dataIndex: 'newPrice',
      key: 'newPrice',
      align: 'center',
      render: (text) =>
        text ? <span className="text-success">{text} TL</span> : '0',
    },
    {
      title: 'İşlem Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text) => (text ? moment(text).format('DD/MM/YYYY HH:mm') : ''),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{rfidInfo ? rfidInfo.userName : ''}</h1>
        </div>
        <div className="list-buttons">
          <GetReports
            fileName={'RFID İşlemleri Listesi'}
            url={`/rest/reports/rfid-transaction-log/${id}`}
            query={query}
          />
          <Button
            type="light"
            onClick={() => props.history.goBack()}
            icon={<LeftOutlined />}
            size={'large'}
          >
            {!isSmall && languagesCms.BACK}
          </Button>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={
            <div className="d-flex justify-content-between ">
              <span>
                RFID: <span className="font-bold">{` ${id}`}</span>
              </span>
              <span>
                Mevcut Bakiye:{' '}
                <span className="font-bold text-success">{` ${
                  rfidInfo ? rfidInfo.price + ' TL' : ''
                }`}</span>
              </span>
            </div>
          }
          loading={loading}
        >
          <>
            <Table
              dataSource={data}
              columns={columns}
              loading={{
                spinning: loading,
                indicator: <LoadingAnimation />,
                size: 'small',
              }}
              onChange={handleTableChange}
              pagination={{
                total: totalCount,
              }}
            />
          </>
        </Card>
      </div>
    </div>
  );
};

export default RfidDetail;
