import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import api from '../../service/index';

const ActiveDevices = () => {
  const [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [loading, setLoading] = useState(false);
  const [modules] = useGlobal('modules');
  let query = [];
  query['deleted'] = false;
  const [languagesCms] = useGlobal('languagesCms');

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  const get = async () => {
    await api
      .get('/rest/get-charge-box-list')
      .then(({ data }) => {
        console.log(data)

        if (data && data.result && data.result.length > 0) {
          setLoading(false);
          setTotalCount(data.result.total);
          setData(data.result);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error('Cihaz Bilgileri Alınamadı ! ', err);
      });
  };

  const refresh = () => {
    setData([]);
    setTimeout(() => {
      return get();
    }, 50);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);
  

  const columns = [
    {
      title: 'Şarj İstasyonu',
      dataIndex: 'stationName',
      key: 'stationName',
      align: 'center',
      sorter: (a, b) =>
        a &&
        a.stationName &&
        a.stationName.localeCompare(b && b.stationName && b.stationName),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => record && record.stationName,
    },
    {
      title: 'Tesis',
      dataIndex: 'dealerName',
      key: 'dealerName',
      align: 'center',
      sorter: (a, b) =>
        a &&
        a.dealerName &&
        a.dealerName.localeCompare(b && b.dealerName && b.dealerName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Cihaz Id',
      dataIndex: 'deviceId',
      key: 'deviceId',
      align: 'center',
      sorter: (a, b) =>
        a &&
        a.deviceId &&
        a.deviceId.localeCompare(b && b.deviceId && b.deviceId),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'right',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={`/active-devices/${record.deviceId}`}>
            <Button
              style={{
                backgroundColor: '#f5f7f6',
                color: '#000',
                border: 'solid 1px #00BD6D',
                borderRadius: '7px',
              }}
              type="danger"
              icon={<i className="fa-solid fa-signal"></i>}
            >
              Cihaz Durumu
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            Aktif Cihazlar
          </h1>
        </div>
        <div className="list-buttons">
          <Button
            type="light"
            onClick={() => refresh()}
            icon={<i className="fa-solid fa-arrows-rotate"></i>}
          >
            Yenile
          </Button>
        </div>
      </div>

      <div key="table" className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          rowKey={data._id}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default ActiveDevices;
