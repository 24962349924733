import React, { useState } from 'react';
import { Modal, Input, Form, Row, Col } from 'antd';

const StationManagers = ({ id, record, setRecord, modalName, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [manager, setManager] = useState(
    record && record.stationManagers && record.stationManagers[name]
      ? record.stationManagers[name]
      : {},
  );

  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setRecord({
      ...record,
      stationManagers: {
        ...record.stationManagers,
        [name]: manager,
      },
    });
    setTimeout(() => {
      setIsModalOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  console.log(manager.phone);

  return (
    <>
      <div className="row mt-3">
        <div className="col-6 font-bold">{manager && manager.name}</div>
        <div className="col-6 font-bold">{manager && manager.lastname}</div>
        <div className="col-6 font-bold">{manager && manager.email}</div>
        <div className="col-6 font-bold">{manager && manager.phone}</div>
      </div>
      <button
        onClick={showModal}
        className="btn btn-danger w-50 btn-sm float-end"
        type="button"
      >
        {manager &&
        (manager.name || manager.lastname || manager.email || manager.phone)
          ? 'Düzenle'
          : 'Ekle'}
      </button>
      <br />

      <Modal
        title={modalName && modalName}
        visible={isModalOpen}
        okText="Kaydet"
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        style={{ borderRadius: '21px !important' }}
      >
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Form
              form={form}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
            >
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Adı"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 23,
                    }}
                    name="name"
                  >
                    <Input
                      placeholder="Adınız"
                      value={manager && manager.name && manager.name}
                      defaultValue={manager && manager.name && manager.name}
                      onChange={(e) =>
                        setManager({ ...manager, name: e.target.value })
                      }
                      name={name}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Soyadı"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 23,
                    }}
                    name="lastname"
                  >
                    <Input
                      placeholder="Soyadınız"
                      value={manager && manager.lastname && manager.lastname}
                      defaultValue={
                        manager && manager.lastname && manager.lastname
                      }
                      onChange={(e) =>
                        setManager({ ...manager, lastname: e.target.value })
                      }
                      name={name}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Telefon"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 23,
                    }}
                    name="phone"
                  >
                    <Input
                      placeholder="+905001112233"
                      value={manager && manager.phone && manager.phone}
                      defaultValue={manager && manager.phone && manager.phone}
                      onChange={(e) =>
                        setManager({ ...manager, phone: e.target.value })
                      }
                      name={name}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label="Email"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 23,
                    }}
                    name="email"
                  >
                    <Input
                      value={manager && manager.email && manager.email}
                      placeholder="email@email.com"
                      defaultValue={manager && manager.email && manager.email}
                      onChange={(e) =>
                        setManager({ ...manager, email: e.target.value })
                      }
                      name={name}
                    />
                  </Form.Item>
                </Col>
                <Row>
                  <b style={{ fontSize: '13px', color: '#ea580c' }}>
                    <i className="fa-solid fa-info fs-6 me-2"></i>
                    {`Bu alanlarda validasyon bulunmamaktadır. İçerikleri doğru girdiğinizden emin olunuz !!!`}
                  </b>
                </Row>
              </Row>
            </Form>
          </div>
        </>
      </Modal>
    </>
  );
};

export default React.memo(StationManagers);
