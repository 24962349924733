import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Card, message, Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';

const LoyaltyPriceDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let [languagesCms] = useGlobal('languagesCms');

  let [data, setData] = useState([]);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  const [user] = useGlobal('user');
  const { auth } = useSelector((state) => state.auth);

  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/prices/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (
      data.loyaltyPriceDiscountRate === null ||
      data.loyaltyPriceDiscountRate.length === 0
    )
      errors.loyaltyPriceDiscountRate = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      api
        .put('/rest/prices/' + id, data)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/loyalty-prices');
          } else message.error(result_message.message, 2);
        });
    }
  };
  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
          <Breadcrumb style={{ fontSize: '1.1rem' }}>
            <Breadcrumb.Item style={{ color: '#fff' }}>
              {data && data.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="list-buttons">
          <Link to="/loyalty-prices">
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card title={languagesCms.EDIT} loading={loading}>
          <Form
            disabled={
              auth &&
              auth
                .find((a) => a.number === user.role)
                .restraintList.includes('edit')
            }
            layout="horizontal"
            size={'large'}
            onFinish={save}
          >
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={'Yüzde Oran'}
                  help={errors.loyaltyPriceDiscountRate}
                  validateStatus={
                    errors.loyaltyPriceDiscountRate ? 'error' : 'success'
                  }
                >
                  <Input
                    name="loyaltyPriceDiscountRate"
                    value={data && data.loyaltyPriceDiscountRate}
                    addonBefore={'%'}
                    onChange={(e) =>
                      setData({
                        ...data,
                        loyaltyPriceDiscountRate: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {languagesCms.EDIT}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default LoyaltyPriceDetail;
