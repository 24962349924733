import React, { useState, useEffect } from 'react';
import api from '../../service';
import { message, Table, Tag } from 'antd';
import moment from 'moment';
import qs from 'qs';
import { Loading3QuartersOutlined } from '@ant-design/icons';

const meterValueList = ({ chargeId }) => {
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  let query = [];

  // üst tablo
  const columns = [
    {
      key: 'key',
    },
    {
      title: 'Başlangıç Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => (
        <span style={{ color: 'red' }}>
          {moment(text).format('DD-MM-YYYY HH:mm:ss')}
        </span>
      ),
    },
  ];

  // alt tablo
  const columns2 = [
    {
      title: 'Measurand',
      dataIndex: 'measurand',
      key: 'measurand',
    },
    {
      title: 'Phase',
      dataIndex: 'phase',
      key: 'phase',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
    },
    {
      title: 'Context',
      dataIndex: 'context',
      key: 'context',
    },
    {
      title: 'Format',
      dataIndex: 'format',
      key: 'format',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text) => (
        <Tag style={{ padding: '3px 7px' }} color="green">
          {text}
        </Tag>
      ),
    },
  ];

  const handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  const get = (page, filters, sorter) => {
    setLoading(true);
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    const _params = { sort: shortString, ...page, query };

    api
      .get(`/rest/get-meter-values/${chargeId}?${qs.stringify(_params)}`, {
        _params,
      })
      .then(({ data }) => {
        console.log(data);
        if (data && data.result) {
          data &&
            data.result &&
            data.result.rows.forEach((item, index) => {
              item.key = index;
              // setMeterValuek(...meterValue, ...item.meterValue);
            });

          setData(data && data.result && data.result.rows);
          setTotalCount(data.result.total);
          setLoading(false);
        } else {
          message.error('Veriler Getirilemedi !');
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setLoading(false);
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <div>
      <div>
        <hr style={{ marginTop: '-1.5rem', marginBottom: '0' }} />
        <br />
        <br />
        <h5 style={{ textAlign: 'center' }}>Ölçüm Değerleri</h5>
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: (record) => (
              <Table
                columns={columns2}
                dataSource={record.meterValue}
                pagination={false}
              />
            ),
            defaultExpandedRowKeys: ['0'],
          }}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          dataSource={data}
          size="small"
        />
      </div>
    </div>
  );
};

export default React.memo(meterValueList);
