import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name, mode, disabled } = props;
  let [station, setStation] = useState([]);
  let _params = {
    sort: '-createdAt',
    page: 1,
    pageSize: 100000,
    cmsQuery: { unique_id: record && record.chargepointId },
  };

  useEffect(() => {
    api
      .get(`/rest/connectors/cms-query?${qs.stringify(_params)}`)
      .then(async ({ data }) => {
        if (data && data.result) {
          const dbConnector =
            data &&
            data.result[0] &&
            data.result[0].connector &&
            data.result[0].connector.sort((a, b) => {
              return a && a.name && a.name.localeCompare(b.name);
            });

          setStation(dbConnector);
        }
      });
  }, [record]);

  return (
    <Select
      value={record[name] || []}
      showSearch={true}
      mode={mode}
      placeholder={'Konnektör Seçiniz'}
      onChange={(v) => setRecord({ ...record, [name]: v })}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled || false}
    >
      {station &&
        station.map((b) => (
          <Select.Option key={b.name} value={b.connector_id}>
            Konnektör No: {b.connector_id} - {b.name}
          </Select.Option>
        ))}
    </Select>
  );
};
