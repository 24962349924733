import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Select,
  InputNumber,
  Breadcrumb,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import 'react-phone-input-2/lib/style.css';
import { PricePicker, QrCodeImage, PdfDownload } from '../../components';

const ConnectorDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let unique_id = params.uniqueId || false;
  let stationId = params.stationId || false;
  let [languagesCms] = useGlobal('languagesCms');
  let newRecord = {
    connector_id: '',
    type: 'AC Type 2',
    voltage: '',
    amper: '',
    power: '',
    epdkSocketNumber: '',
    stationId: stationId,
    unique_id: unique_id,
    started: '0',
    price: '',
    devicePublicId: '',
  };

  let [data, setData] = useState(id ? [] : newRecord);
  let [station, setStation] = useState(null);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  const [user] = useGlobal('user');
  const [authorizations] = useGlobal('authorizations');

  let [modules] = useGlobal('modules');

  useEffect(() => {
    setLoading(true);
    api
      .get('/rest/stations/' + stationId)
      .then(({ data: { result, result_message } }) => {
        setStation(result);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/connectors/' + unique_id + '/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (
      data.connector_id === '' ||
      data.connector_id === null ||
      data.connector_id === undefined
    )
      errors.connector_id = languagesCms.ENFORCED;

    if (
      data.voltage === '' ||
      data.voltage === null ||
      data.voltage === undefined
    )
      errors.voltage = languagesCms.ENFORCED;

    if (data.amper === '' || data.amper === null || data.amper === undefined)
      errors.amper = languagesCms.ENFORCED;

    if (data.power === '' || data.power === null || data.power === undefined)
      errors.power = languagesCms.ENFORCED;

    if (data.price === '' || data.price === null || data.price === undefined)
      errors.price = languagesCms.ENFORCED;


    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {

      if (id) {
        api
          .put('/rest/connectors/' + unique_id + '/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/stations/detail/' + stationId + '/' + unique_id);
            } else message.error(result_message.message, 2);
          });
      } else {
    
        if (data.type) {
          data.connectorType = data.type === 'AC Type 2' ? 'AC' : 'DC';
        }
        data.connector_id = String(data.connector_id);

        console.log('giden data: ', data);

        api
          .post('/rest/connectors/' + unique_id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/stations/detail/' + stationId + '/' + unique_id);
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  const qrImageCreate = async (unique_id, connector_id) => {
    if (!(unique_id && connector_id))
      return message.error('Cihaz Bilgisi Alınamadı');
    if (data.qrMedia) delete data.qrMedia;

    connector_id = String(connector_id);

    await api
      .post('/rest/qrcode', {
        unique_id,
        connector_id,
      })
      .then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          setData({ ...data, qrMedia: result.media });
        } else {
          message.error(result_message.message, 2);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ color: '#fff', marginBottom: '.1rem' }}>
            {languagesCms.CONNECTORS}
          </h1>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a
                style={{
                  color: '#fff',
                }}
                href="/stations"
              >
                {' '}
                {languagesCms.STATIONS}{' '}
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a
                style={{
                  color: '#fff',
                }}
                href={'/stations/detail/' + data.stationId}
              >
                {station ? station.name : ''}
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a
                style={{
                  color: '#fff',
                }}
                href={
                  '/stations/detail/' + data.stationId + '/' + data.unique_id
                }
              >
                {unique_id}
              </a>
            </Breadcrumb.Item>
            {data.connector_id && (
              <Breadcrumb.Item
                style={{
                  color: '#fff',
                }}
              >
                {languagesCms.CONNECTOR_COUNT + data.connector_id}
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
        </div>
        <div className="list-buttons">
          <Link to={'/stations/detail/' + stationId + '/' + unique_id}>
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form
            disabled={
              authorizations &&
              authorizations
                .find((a) => a.number === user.role)
                .restraintList.includes('edit')
            }
            layout="horizontal"
            size={'large'}
            onFinish={save}
          >
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Konnektör No"
                  help={errors.connector_id}
                  required
                  validateStatus={errors.connector_id ? 'error' : 'success'}
                >
                  <InputNumber
                    name="connector_id"
                    value={data && data.connector_id}
                    disabled={id ? true : false}
                    onChange={(e) => setData({ ...data, connector_id: e })}
                  />
                </Form.Item>
              </Col>
              {!(data && data.qrMedia) && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Button
                    type="danger"
                    size="medium"
                    onClick={() =>
                      qrImageCreate(data.unique_id, data.connector_id)
                    }
                    style={{ borderRadius: '7px' }}
                  >
                    Qr Oluştur
                  </Button>
                </Col>
              )}
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.TYPE + ' ( AC / DC )'}
                  help={errors.type}
                  validateStatus={errors.type ? 'error' : 'success'}
                >
                  <Select
                    value={data.type}
                    onChange={(e) => setData({ ...data, type: e })}
                  >
                    <Select.Option key={'AC Type 2'} value={'AC Type 2'}>
                      {'AC Type 2'}
                    </Select.Option>
                    <Select.Option key={'CCS2'} value={'CCS2'}>
                      {'CCS2'}
                    </Select.Option>
                    <Select.Option key={'CHAdeMO'} value={'CHAdeMO'}>
                      {'CHAdeMO'}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Voltage'}
                  help={errors.voltage}
                  validateStatus={errors.voltage ? 'error' : 'success'}
                >
                  <Input
                    name="voltage"
                    value={data.voltage}
                    onChange={(e) =>
                      setData({ ...data, voltage: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Amper'}
                  help={errors.amper}
                  validateStatus={errors.amper ? 'error' : 'success'}
                >
                  <Input
                    name="amper"
                    value={data.amper}
                    onChange={(e) =>
                      setData({ ...data, amper: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Power'}
                  help={errors.power}
                  validateStatus={errors.power ? 'error' : 'success'}
                >
                  <Input
                    name="power"
                    value={data.power}
                    onChange={(e) =>
                      setData({ ...data, power: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.PRICE}
                  help={errors.price}
                  required
                  validateStatus={errors.price ? 'error' : 'success'}
                >
                  <PricePicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="price"
                    mode="single"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Epdk Socket Numarası">
                  <Input
                    value={data.epdkSocketNumber}
                    onChange={(e) =>
                      setData({
                        ...data,
                        epdkSocketNumber: e.target.value,
                      })
                    }
                  />
                  <b style={{ fontSize: '11px', color: '#ea580c' }}>
                    {`-> Lütfen Epdk sisteminden soketiniz için verilen id numarasını
                    giriniz !!! `}
                  </b>
                  <br />
                  <b style={{ fontSize: '11px', color: '#ea580c' }}>
                    {`-> Epdk sistemine hezüz eklememişseniz yada test için açtığınız
                    kayıtlarda bu alanı boş bırakınız !!!`}
                  </b>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item label={'Cihaz Public Id'}>
                  {id ? (
                    <>
                      <h3>{data.devicePublicId}</h3>
                    </>
                  ) : (
                    <>
                      {' '}
                      <Input.Group size="large">
                        <Row gutter={24}>
                          <Col span={6}>
                            <Form.Item
                              name="data.devicePublicId"
                              rules={[
                                {
                                  required: true,
                                  message: 'Zorunlu Alan',
                                },
                              ]}
                            >
                              <Input
                                name="data.devicePublicId"
                                disabled={id ? true : false}
                                placeholder="Cihaz Halka Açık İd"
                                value={data && data.devicePublicId}
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    devicePublicId: e.target.value,
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Input.Group>
                    </>
                  )}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={'Bilgileri İndir'}>
                  <PdfDownload record={data} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              {data.qrMedia && <QrCodeImage record={data} />}
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default ConnectorDetail;
