import { useGlobal } from 'reactn';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { Row, Col, Form, Button, Card, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { Link } from 'react-router-dom';

const RfidBalanceDetail = (props) => {
  const [form] = Form.useForm();

  const params = useParams();
  const history = useHistory();
  const id = params.id !== 'add' ? params.id : false;
  const [languagesCms] = useGlobal('languagesCms');

  const newRecord = {
    status: 'active',
  };

  const [data, setData] = useState(id ? {} : newRecord);
  const [loading, setLoading] = useState(id ? true : false);
  const [isSmall] = useGlobal('isSmall');
  const [validationCheck, setValidationCheck] = useState(false);
  const [errors, setErrors] = useState([]);
  const [modules] = useGlobal('modules');

  const path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/rfid/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.price === null || data.price === undefined || data.price === '')
      errors.price = 'Lütfen Araç Plakası Girin';

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  const onFinish = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        data.price = Number(data && data.price) + Number(data && data.newPrice);
        api.put('/rest/rfid/' + id, data).then(({ data }) => {
          if (data && data.result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/rfid-balance-upload');
          } else
            message.error(
              data && data.result_message && data.result_message.message,
              2,
            );
        });
      } else {
        api
          .post('/rest/rfid', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/rfid-balance-upload');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  console.log(data);

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/rfid-balance-upload">
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form
            form={form}
            className="w-100"
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="on"
            size="large"
          >
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }} className="mt-3">
                <h3 className="text-center">{data && data.userName}</h3>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label="Mevcut Bakiye" name="newPrice">
                  <span>{data && data.price} TL</span>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Para Yükle"
                  name="newPrice"
                  required
                  help={errors.newPrice}
                  validateStatus={errors.newPrice ? 'error' : 'success'}
                >
                  <input
                    type="number"
                    class="form-control"
                    name="newPrice"
                    defaultValue={'0'}
                    onChange={(e) => {
                      setData({ ...data, newPrice: Number(e.target.value) });
                    }}
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Button danger htmlType="submit" size="large" block>
              Kaydet
            </Button>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default RfidBalanceDetail;
