import React, { useEffect, useState, useCallback } from 'react';
import { useGlobal } from 'reactn';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Form,
  Row,
  Col,
  message,
  Input,
  Select,
  Steps,
} from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import {
  FileInput,
  FaultRecordStepOne,
  FaultRecordHistoryModal,
} from '../../components';

const FaultRecordDetail = (props) => {
  const params = useParams();
  const history = useHistory();
  const id = params.id !== 'add' ? params.id : false;
  const [languagesCms] = useGlobal('languagesCms');
  const [publicId, setPublicId] = useState(false);

  const newRecord = {
    qrCode: {
      unique_id: '',
      connector_id: '',
    },
    description: '',
    deviceMedia: [],
    status: 'open',
    responsible: 'callCenter',
    userId: '',
    step: 0,
  };

  const [data, setData] = useState(id ? [] : newRecord);
  const [loading, setLoading] = useState(id ? true : false);
  const [isSmall] = useGlobal('isSmall');
  const [validationCheck, setValidationCheck] = useState(false);
  const [errors, setErrors] = useState([]);

  const [modules] = useGlobal('modules');
  const path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }
  //onetime run
  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/fault-record/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  const validate = useCallback(() => {
    let errors = {};

    if (
      data.qrCode &&
      (data.qrCode.unique_id === null || data.qrCode.unique_id === undefined)
    )
      errors.unique_id = languagesCms.ENFORCED;

    if (
      data.qrCode &&
      (data.qrCode.connector_id === null ||
        data.qrCode.connector_id === undefined)
    )
      errors.connector_id = languagesCms.ENFORCED;

    if (
      data.status === null ||
      data.status === undefined ||
      data.status === '' ||
      ['open', 'resolved'].includes(data.status) === false
    )
      errors.status = languagesCms.ENFORCED;

    if (
      data.responsible === null ||
      data.responsible === undefined ||
      data.responsible === '' ||
      [
        'callCenter',
        'operator',
        'fieldServices',
        'software',
        'guarantee',
      ].includes(data.responsible) === false
    )
      errors.responsible = languagesCms.ENFORCED;

    if (data.userId === null || data.userId === undefined || data.userId === '')
      errors.userId = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  const save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        if (data.devicePublicId) delete data.devicePublicId;
        api
          .put('/rest/fault-record/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/fault-record');
            } else message.error(result_message.message, 2);
          });
      } else {
        if (data.devicePublicId) delete data.devicePublicId;
        api
          .post('/rest/fault-record', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/fault-record');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  // qr okutarak cihaz bilgilerini getirme
  const findDeviceByScanningQr = async () => {
    api
      .post('/rest/notification-deepLink', {
        returnId: data._id,
        userId: data.userId,
        deepLink: 'qrCode',
        description: 'Lütfen Cihazınızın QR Kodunu Okutunuz',
      })
      .then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          message.success(
            'Bildirim Gönderildi, Kullanıcıdan Gelecek olan Bilgileri Bekleyiniz ...',
            2,
          );
        } else {
          message.error(result_message.message, 2);
        }
      })
      .catch((err) => {
        message.error(err, 2);
        message.error('Bildirim Gönderilemedi !', 2);
      });
  };

  // qr okutarak cihaz bilgilerini getirme
  const getMediaWithNotification = async () => {
    api
      .post('/rest/notification-deepLink', {
        returnId: data._id,
        userId: data.userId,
        deepLink: 'deviceMedia',
        description: 'Cihazın Fotoğrafını Çekiniz',
      })
      .then(({ data: { result, result_message } }) => {
        if (result_message.type === 'success') {
          message.success(
            'Bildirim Gönderildi, Kullanıcıdan Gelecek olan Medya Bilgileri Bekleyiniz ...',
            2,
          );
        } else {
          message.error(result_message.message, 2);
        }
      })
      .catch((err) => {
        message.error(err, 2);
        message.error('Bildirim Gönderilemedi !', 2);
      });
  };

  const deviceFind = async () => {
    setData({
      ...data,
      qrCode: { ...data.qrCode, unique_id: null, connector_id: null },
    });
    if (data.devicePublicId) {
      api
        .get('/rest/device-public-id/' + data.devicePublicId)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            setData({
              ...data,
              qrCode: {
                ...data.qrCode,
                unique_id: result.unique_id,
                connector_id: result.connector_id,
              },
            });
            message.success('Cihaz Bilgileri Gerekli Alanlara Eklendi ...');
          } else message.error(result_message.message, 2);
        });
    } else {
      message.error('Lütfen Cihaz Public Id Giriniz !');
    }
  };

  return (
    <div>
      <>
        <div className="list-head">
          <div className="list-title">
            <h1>{module ? module.name : ''}</h1>
          </div>
          <div className="list-buttons">
            <div className='me-2'>
              <FaultRecordHistoryModal recordId={data._id} />
            </div>
            <Link to="/fault-record">
              <Button type="light" icon={<LeftOutlined />} size={'large'}>
                {!isSmall && languagesCms.BACK}
              </Button>
            </Link>
          </div>
        </div>

        <div className="form-wrap">
          <Card title={'Cihaz Durum'} loading={loading}>
            <Form layout="horizontal" size={'large'} onFinish={save}>
              <Row direction="row">
                <Steps
                  size="small"
                  current={data.step}
                  items={[
                    {
                      title: 'Kayıt Oluştur',
                    },
                    {
                      title: 'Kayıt Tamamlama',
                    },
                  ]}
                />
              </Row>
              {data.step === 0 && (
                <>
                  <div
                    style={{
                      overflowX: 'hidden',
                    }}
                  >
                    <FaultRecordStepOne />
                  </div>
                </>
              )}
              {data.step === 1 && (
                <>
                  <Row direction="row">
                    <h4
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        marginBottom: '20px',
                        marginTop: '20px',
                      }}
                    >
                      <i className="fa-solid fa-fingerprint">
                        {' '}
                        &nbsp; Cihaz Bul
                      </i>
                    </h4>
                    <div className="d-grid gap-2 d-md-block text-center mx-auto mb-3 mt-3">
                      <button
                        type="button"
                        onClick={() => {
                          setPublicId(publicId === false ? true : false);
                        }}
                        className={`btn btn-outline-danger btn-sm me-1 ${
                          data &&
                          data.qrCode &&
                          data.qrCode.unique_id !== '' &&
                          data.qrCode.connector_id !== ''
                            ? ' disabled'
                            : ''
                        }`}
                      >
                        Public İd ile Cihaz Bul
                      </button>{' '}
                      <button
                        type="button"
                        onClick={() => findDeviceByScanningQr()}
                        className={`btn btn-outline-danger btn-sm me-1 ${
                          data &&
                          data.qrCode &&
                          data.qrCode.unique_id !== '' &&
                          data.qrCode.connector_id !== ''
                            ? ' disabled'
                            : ''
                        }`}
                      >
                        Bildirim ve QR Okuma İle Cihaz Bul
                      </button>
                    </div>

                    {publicId && (
                      <>
                        <Col xs={{ span: 24 }} md={{ span: 20 }}>
                          <Form.Item
                            label={'Cihaz Public Id'}
                            help={errors.devicePublicId}
                            validateStatus={
                              errors.devicePublicId ? 'error' : 'success'
                            }
                          >
                            <Input
                              name="devicePublicId"
                              value={data.devicePublicId}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  devicePublicId: e.target.value,
                                });
                              }}
                            />
                            <small>
                              Buradan Cihaz Public Id Yardımıyla Cihaz
                              Bilgilerine Erişim Sağlanabilir ...
                            </small>
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 4 }}>
                          <button
                            className="btn btn-outline-danger"
                            type="button"
                            onClick={deviceFind}
                          >
                            Cihaz Bul
                          </button>
                        </Col>
                      </>
                    )}
                    <Row direction="row">
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item
                          label={'Cihaz Id'}
                          required
                          help={errors.unique_id}
                          validateStatus={
                            errors.unique_id ? 'error' : 'success'
                          }
                        >
                          <Input
                            name="unique_id"
                            value={data && data.qrCode && data.qrCode.unique_id}
                            disabled
                            onChange={(e) =>
                              setData({
                                ...data,
                                qrCode: {
                                  ...data.qrCode,
                                  unique_id: e.target.value,
                                },
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item
                          label={'Konnektör Id'}
                          required
                          help={errors.connector_id}
                          validateStatus={
                            errors.connector_id ? 'error' : 'success'
                          }
                        >
                          <Input
                            name="connector_id"
                            disabled
                            value={
                              data && data.qrCode && data.qrCode.connector_id
                            }
                            onChange={(e) =>
                              setData({
                                ...data,
                                qrCode: {
                                  ...data.qrCode,
                                  connector_id: e.target.value,
                                },
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item
                          label={'Durum'}
                          required
                          help={errors.status}
                          validateStatus={errors.status ? 'error' : 'success'}
                        >
                          <Select
                            name="status"
                            defaultValue={data.status}
                            onChange={(e) => setData({ ...data, status: e })}
                          >
                            <Select.Option value={'open'}>Aktif</Select.Option>
                            <Select.Option value={'resolved'}>
                              Çözüldü
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item
                          label={'Sorumlu'}
                          required
                          help={errors.responsible}
                          validateStatus={
                            errors.responsible ? 'error' : 'success'
                          }
                        >
                          <Select
                            name="responsible"
                            defaultValue={data.responsible}
                            onChange={(e) =>
                              setData({ ...data, responsible: e })
                            }
                          >
                            <Select.Option value={'callCenter'}>
                              Çağrı Merkezi
                            </Select.Option>
                            <Select.Option value={'operator'}>
                              İşletmeci
                            </Select.Option>
                            <Select.Option value={'fieldServices'}>
                              Saha Hizmetleri
                            </Select.Option>
                            <Select.Option value={'software'}>
                              Yazılım
                            </Select.Option>
                            <Select.Option value={'guarantee'}>
                              Garanti
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 24 }}>
                        <Form.Item
                          label={'Açıklama'}
                          required
                          help={errors.description}
                          validateStatus={
                            errors.description ? 'error' : 'success'
                          }
                        >
                          <Input.TextArea
                            name="description"
                            value={data.description}
                            onChange={(e) =>
                              setData({
                                ...data,
                                description: e.target.value,
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item label={'Cihaz Fotoğrafları'}>
                          <FileInput
                            languagesCms={languagesCms}
                            name="deviceMedia"
                            title="Medyalar"
                            type="file"
                            multi={false}
                            ext={['image']}
                            record={data}
                            setRecord={setData}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item>
                          <button
                            type="button"
                            onClick={() => getMediaWithNotification()}
                            className="btn btn-outline-danger btn-sm"
                          >
                            Bildirim İle Fotoğraf Al
                          </button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Row>
                  <Row direction="row">
                    <Col span={24}>
                      <Form.Item>
                        <Button
                          type="primary"
                          disabled={loading}
                          htmlType="submit"
                          size="large"
                          block
                        >
                          {' '}
                          {languagesCms.SAVE}{' '}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          </Card>
        </div>
      </>
    </div>
  );
};
export default FaultRecordDetail;
