import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Select,
  Alert,
  Breadcrumb,
  DatePicker,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { FileInputSingle, DealerPicker, RfidListModal } from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/tr_TR';

const { Option } = Select;

const AttendeeDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let [languagesCms] = useGlobal('languagesCms');
  let [langCms] = useGlobal('langCms');
  let [langCode] = useGlobal('langCode');
  const [authorizations] = useGlobal('authorizations');
  const [user] = useGlobal('user');

  let newRecord = {
    name: '',
    lastname: '',
    role: 7,
    phone: '',
    email: '',
    tc: '',
    lang: langCode ? langCode : langCms ? langCms[0].code : '',
    credit: '0.0',
    stationId: '',
    address: '',
    car: '',
    dateOfBirth: '',
    userGroup: 'site',
    userType: 'individual',
    apartment: '',
    blok: '',
    floor: '',
    facilityId: '',
  };
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [isSmall] = useGlobal('isSmall');

  let [modules] = useGlobal('modules');

  //onetime run
  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/attendees/' + id)
        .then(({ data: { result, result_message } }) => {
          delete result.password;
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.name === null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED;

    if (data.lastname === null || data.lastname.length === 0)
      errors.lastname = languagesCms.ENFORCED;

    if (data.phone === null || data.phone.length === 0)
      errors.phone = languagesCms.ENFORCED;

    if (data.email === null || data.email.length === 0)
      errors.email = languagesCms.ENFORCED;

    if (
      data.apartment === null ||
      data.apartment === '' ||
      data.apartment === undefined
    )
      errors.apartment = languagesCms.ENFORCED;

    if (data.floor === null || data.floor === '' || data.floor === undefined)
      errors.floor = languagesCms.ENFORCED;

    if (data.blok === null || data.blok === '' || data.blok === undefined)
      errors.blok = languagesCms.ENFORCED;

    if (data.dateOfBirth === null || data.dateOfBirth === '')
      errors.dateOfBirth = languagesCms.ENFORCED;

    if (data.tc === null || (data && data.tc && data.tc.length !== 11))
      errors.tc = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        if (data.mspName) data.mspName = data.mspName.toString();
        if (data.phone && !data.phone.startsWith('+')) {
          data.phone = '+' + data.phone;
        }
        api
          .put('/rest/attendees/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/attendees');
            } else message.error(result_message.message, 2);
          });
      } else {
        if (data.mspName) data.mspName = data.mspName.toString();
        if (data.phone && !data.phone.startsWith('+')) {
          data.phone = '+' + data.phone;
        }
        if (data.stationId === '') data.stationId = [];
        api
          .post('/rest/attendees', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/attendees');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h3 style={{ color: '#fff', marginBottom: '8px' }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a
                  style={{ color: '#fff', fontSize: '18px' }}
                  href="/attendees"
                >
                  Son Kullanıcılar
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item style={{ color: '#fff', fontSize: '18px' }}>
                {data.name + ' ' + data.lastname}
              </Breadcrumb.Item>
            </Breadcrumb>
          </h3>
        </div>

        <div className="list-buttons">
          <Link to="/attendees">
            <Button
              type="light"
              icon={<LeftOutlined />}
              size={'large'}
              style={{ fontSize: '14px', height: '100%' }}
            >
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form
            disabled={
              authorizations &&
              authorizations
                .find((a) => a.number === user.role)
                .restraintList.includes('edit')
            }
            labelCol={{
              span: 24,
            }}
            layout="horizontal"
            size={'large'}
            onFinish={save}
          >
            {!id && (
              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={'Kullanıcı Türü'}
                    required
                    help={errors.userType}
                    validateStatus={errors.userType ? 'error' : 'success'}
                  >
                    <Select
                      defaultValue={data.userType ? data.userType : ''}
                      style={{ width: 120 }}
                      onChange={(v) => {
                        setData({ ...data, userType: v });
                      }}
                    >
                      <Option value={'individual'}>Bireysel</Option>
                      <Option value={'corporate'}>Kurumsal</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    label={'Kullanıcı Grubu'}
                    required
                    help={errors.userGroup}
                    validateStatus={errors.userGroup ? 'error' : 'success'}
                  >
                    <Select
                      defaultValue={data.userGroup ? data.userGroup : ''}
                      style={{ width: 120 }}
                      onChange={(v) => {
                        setData({ ...data, userGroup: v });
                      }}
                    >
                      <Option value={'site'}>Tesis</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Avatar"
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <FileInputSingle
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="avatar"
                  />
                  <Alert
                    message="Avatar 1024 x 1024 çözünürlüğünde olmalıdır."
                    banner
                  />
                </Form.Item>
              </Col>
              {id && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label="RFID">
                    <RfidListModal
                      userId={data && data._id}
                      facilityId={data && data.facilityId}
                    />
                  </Form.Item>
                </Col>
              )}

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.NAME}
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="name"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.LASTNAME}
                  required
                  help={errors.lastname}
                  validateStatus={errors.lastname ? 'error' : 'success'}
                >
                  <Input
                    name="lastname"
                    value={data.lastname}
                    onChange={(e) =>
                      setData({ ...data, lastname: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.PHONE}
                  required
                  help={errors.phone}
                  validateStatus={errors.phone ? 'error' : 'success'}
                >
                  <PhoneInput
                    width={'100%'}
                    country={'tr'}
                    value={data.phone}
                    onChange={(e) => setData({ ...data, phone: e })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.EMAIL}
                  required
                  help={errors.email}
                  validateStatus={errors.email ? 'error' : 'success'}
                >
                  <Input
                    name="email"
                    value={data.email}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              {!id && (
                <>
                  {data && data.userType === 'individual' ? (
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label={languagesCms.TC}
                        required
                        name="tc"
                        rules={[
                          {
                            required: true,
                            message: 'Lütfen Bir Tc Numara Giriniz!',
                          },
                          {
                            max: 11,
                            message: 'Tc 11 karakter olarak girilmelidir !',
                          },
                          {
                            min: 11,
                            message: 'Tc 11 karakter olarak girilmelidir !',
                          },
                        ]}
                        help={errors.tc}
                        validateStatus={errors.tc ? 'error' : 'success'}
                      >
                        <Input
                          name="tc"
                          value={data.tc}
                          onChange={(e) =>
                            setData({ ...data, tc: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                      <Form.Item
                        label={languagesCms.VKN}
                        required
                        name="vkn"
                        rules={[
                          {
                            required: true,
                            message: 'Lütfen Bir VKN Numara Giriniz!',
                          },
                          {
                            max: 10,
                            message: 'VKN 10 karakter olarak girilmelidir !',
                          },
                          {
                            min: 10,
                            message: 'VKN 10 karakter olarak girilmelidir !',
                          },
                        ]}
                        help={errors.vkn}
                        validateStatus={errors.vkn ? 'error' : 'success'}
                      >
                        <Input
                          name="vkn"
                          value={data.vkn}
                          onChange={(e) =>
                            setData({ ...data, vkn: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}
                </>
              )}
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Doğum Tarihiniz'}
                  required
                  help={errors.dateOfBirth}
                  validateStatus={errors.dateOfBirth ? 'error' : 'success'}
                >
                  <DatePicker
                    locale={locale}
                    format="DD-MM-YYYY"
                    onChange={(date, dateString) => {
                      setData({ ...data, dateOfBirth: dateString });
                    }}
                    placeholder="Tarih"
                    defaultValue={
                      data &&
                      (data.dateOfBirth
                        ? moment(data.dateOfBirth, 'DD-MM-YYYY')
                        : moment(new Date(), 'DD-MM-YYYY'))
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.CAR}
                  help={errors.car}
                  validateStatus={errors.car ? 'error' : 'success'}
                >
                  <Input
                    name="car"
                    value={data.car}
                    onChange={(e) => setData({ ...data, car: e.target.value })}
                  />
                </Form.Item>
              </Col>
              {data.role && data.role > 2 && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item label={'Tesis Seçiniz'}>
                    <DealerPicker
                      languagesCms={languagesCms}
                      record={data}
                      setRecord={setData}
                      name="facilityId"
                      dataLimit={
                        [1, 2].includes(user && user.role) === true
                          ? 'allData'
                          : [5].includes(user && user.role) === true
                          ? 'theirSites'
                          : null
                      }
                    />
                  </Form.Item>
                </Col>
              )}

              <Row direction="row">
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    label={'Blok'}
                    labelCol={{
                      span: 24,
                    }}
                    help={errors.blok}
                    required
                    validateStatus={errors.blok ? 'error' : 'success'}
                  >
                    <Input
                      name="blok"
                      value={data.blok}
                      onChange={(e) =>
                        setData({ ...data, blok: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    label={'Kat'}
                    labelCol={{
                      span: 24,
                    }}
                    help={errors.floor}
                    required
                    validateStatus={errors.floor ? 'error' : 'success'}
                  >
                    <Input
                      name="floor"
                      value={data.floor}
                      onChange={(e) =>
                        setData({ ...data, floor: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    labelCol={{
                      span: 24,
                    }}
                    label={'Daire'}
                    help={errors.apartment}
                    required
                    validateStatus={errors.apartment ? 'error' : 'success'}
                  >
                    <Input
                      name="apartment"
                      value={data.apartment}
                      onChange={(e) =>
                        setData({ ...data, apartment: e.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    style={{
                      backgroundColor: '#9eb23b',
                      borderColor: 'transparent',
                    }}
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default AttendeeDetail;
