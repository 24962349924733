import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import { Select } from 'antd';
import api from '../../service/index';
import qs from 'qs';

export default (props) => {
  const [user] = useGlobal('user');
  let { record, setRecord, name, mode, disabled } = props;
  let [station, setStation] = useState([]);

  // bu component de Tesis maliyetleri modülünde kullanıcı rolü Tesis ise o kullanıcın Tesisleri listelenir.

  const query = {};
  if (user && user.role === 5) query['dealerAdminId'] = user._id;

  let _params = {
    sort: '-createdAt',
    page: 1,
    pageSize: 100000,
    query,
    fields: ['_id', 'name'],
  };

  useEffect(() => {
    api.get(`/rest/dealer?${qs.stringify(_params)}`).then(async ({ data }) => {
      if (data && data.result) {
        const dealers =
          data &&
          data.result &&
          data.result.rows &&
          data.result.rows.sort((a, b) => {
            return a && a.dealers && a.dealers.localeCompare(b.name);
          });
        setStation(dealers);
      }
    });
  }, []);

  return (
    <Select
      value={record[name] || []}
      showSearch={true}
      mode={mode}
      placeholder={'Tesis Seçiniz'}
      onChange={(v) => setRecord({ ...record, [name]: v })}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled || false}
    >
      {station &&
        station.map((b) => (
          <Select.Option key={b._id} value={b._id}>
            {b.name}
          </Select.Option>
        ))}
    </Select>
  );
};
