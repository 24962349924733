export { default as RedirectComp } from './RedirectComp';
/* Settings */
export { default as Setting } from './Settings/Setting';
export { default as Module } from './Settings/Module';
export { default as ModuleDetail } from './Settings/ModuleDetail';
export { default as Customization } from './Settings/Customization';
export { default as LoginSetting } from './Settings/LoginSetting';
export { default as SurveyWithMediaIcons } from './Settings/SurveyWithMediaIcons';
export { default as Version } from './Settings/Version';
export { default as TopRightModule } from './Settings/TopRightModule';
export { default as Deneme } from './Settings/Deneme';
export { default as DenemeDetail } from './Settings/DenemeDetail';
export { default as BottomBar } from './Settings/BottomBar';
export { default as BottomBarDetail } from './Settings/BottomBarDetail';
export { default as Languages } from './Settings/Languages';
export { default as LanguagesDetail } from './Settings/BottomBarDetail';
export { default as LanguagesCms } from './Settings/LanguagesCms';
export { default as LanguagesCmsDetail } from './Settings/BottomBarDetail';
export { default as LikeTypes } from './Settings/LikeTypes';
export { default as LikeTypesDetail } from './Settings/LikeTypesDetail';
export { default as CoordinateTypes } from './Settings/CoordinateTypes';
export { default as CoordinateTypesDetail } from './Settings/CoordinateTypesDetail';
export { default as PointTypes } from './Settings/PointTypes';
export { default as PointTypesDetail } from './Settings/PointTypesDetail';
export { default as ShakeWinMessages } from './Settings/ShakeWinMessages';
export { default as ShakeWinMessagesDetail } from './Settings/ShakeWinMessagesDetail';
export { default as ResultMessages } from './Settings/ResultMessages';
export { default as ResultMessagesDetail } from './Settings/ResultMessagesDetail';
export { default as CoordinateMenu } from './Settings/CoordinateMenu';
export { default as CoordinateMenuDetail } from './Settings/CoordinateMenuDetail';
export { default as PointMenu } from './Settings/PointMenu';
export { default as PointMenuDetail } from './Settings/PointMenuDetail';
export { default as SupportedLang } from './Settings/SupportedLang';
export { default as SupportedLangDetail } from './Settings/SupportedLangDetail';
export { default as Live } from './Settings/Live';
export { default as Nword } from './Settings/Nword';
export { default as Nword2 } from './Settings/Nword2';
export { default as WebApp } from './Settings/WebApp';
export { default as WebAppDetail } from './Settings/WebAppDetail';
export { default as PofileSettings } from './Settings/PofileSettings';
export { default as AppControls } from './Settings/AppControls';
export { default as HealthList } from './Settings/HealthList';
export { default as SmsAndEmailMessage } from './Settings/SmsAndEmailMessage';
export { default as SmsAndEmailMessageDetail } from './Settings/SmsAndEmailMessageDetail';
export { default as ProfilSettingsMenu } from './Settings/ProfilSettingsMenu';
export { default as ProfilSettingsMenuDetail } from './Settings/ProfilSettingsMenuDetail';
export { default as PostwallSettings } from './Settings/PostwallSettings';
export { default as TopRightChatModule } from './Settings/TopRightChatModule';
export { default as BirthdaySettings } from './Settings/BirthdaySettings';
export { default as JobStartDateSettings } from './Settings/JobStartDateSettings';
export { default as ReservationSettings } from './Settings/ReservationSettings';
export { default as ReservationSettingsBranch } from './Settings/ReservationSettingsBranch';
export { default as PageReservationSettings } from './Settings/PageReservationSettings';
export { default as PageReservationSettingsBranch } from './Settings/PageReservationSettingsBranch';
export { default as TopRightShareButton } from './Settings/TopRightShareButton';
export { default as PublicHolidays } from './Settings/PublicHolidays';
export { default as PublicHolidaysDetail } from './Settings/PublicHolidaysDetail';
export { default as PagePublicHolidays } from './Settings/PagePublicHolidays';
export { default as PagePublicHolidaysDetail } from './Settings/PagePublicHolidaysDetail';
export { default as ReservationAllSettings } from './Settings/ReservationAllSettings';
export { default as PageReservationDepartments } from './Settings/PageReservationDepartments';
export { default as PageReservationDepartmentsDetail } from './Settings/PageReservationDepartmentsDetail';
export { default as ReservationKVKKSettings } from './Settings/ReservationKVKKSettings';
export { default as PageReservationKVKKSettings } from './Settings/PageReservationKVKKSettings';
/* Settings */

export { default as PushNotification } from './PushNotification';
export { default as PushNotificationDetail } from './PushNotificationDetail';

export { default as Attendee } from './Attendee';
export { default as AttendeeDetail } from './AttendeeDetail';

// yöneticiler
export { default as Manager } from './managers/Manager';
export { default as ManagerDetail } from './managers/ManagerDetail';

export { default as Chargepoint } from './devices/Chargepoint';
export { default as ChargepointDetail } from './devices/ChargepointDetail';

// Konnektörler
export { default as Connector } from './connector/Connector';
export { default as ConnectorDetail } from './connector/ConnectorDetail';

// tarifeler
export { default as Price } from './prices/Price';
export { default as PriceDetail } from './prices/PriceDetail';
export { default as PriceStationList } from './prices/priceStationList';
export { default as PriceHistory } from './prices/PriceHistory';

// fiyat Listesi
export { default as PriceList } from './priceList/PriceList';
export { default as PriceListDetail } from './priceList/PriceListDetail';

// özel tarifeler
export { default as PrivatePrice } from './privatePrices/PrivatePrice';
export { default as PrivatePriceDetail } from './privatePrices/PrivatePriceDetail';

// Şarj İstasyonları
export { default as Stations } from './stations/Stations';
export { default as StationsDetail } from './stations/StationsDetail';

export { default as HistoryStations } from './HistoryStations';
export { default as HistoryChargepoint } from './HistoryChargepoint';
export { default as HistoryConnector } from './HistoryConnector';
export { default as HistoryView } from './HistoryView';

export { default as NotFoundPage } from './404';
export { default as Logs } from './Logs';

// Dashboard
export { default as Dashboard } from './Dashboard';

export { default as Reservation } from './reservations/Reservation';
export { default as ReservationDetail } from './reservations/ReservationDetail';
export { default as ReservationStationList } from './reservations/ReservationStationList';

// Tesisler
export { default as Dealer } from './dealer/Dealer';
export { default as DealerDetail } from './dealer/DealerDetail';

// aktif cihazlar
export { default as ActiveDevices } from './activeDevices/ActiveDevices';
export { default as ActiveDevicesDetail } from './activeDevices/ActiveDevicesDetail';

// aktif cihazlar
export { default as Alert } from './alert/Alert';
export { default as AlertDetail } from './alert/AlertDetail';

// Tesis Maliyetleri
export { default as DealerCosts } from './dealerCost/DealerCosts';
export { default as DealerCostDetail } from './dealerCost/DealerCostDetail';

// Sadakat Müşterileri
export { default as LoyaltyPrices } from './LoyaltyPrices';
export { default as LoyaltyPriceDetail } from './LoyaltyPriceDetail';

// Filo Müşterileri
export { default as FleetPrices } from './FleetPrices';
export { default as FleetPriceDetail } from './FleetPriceDetail';

// QR code
export { default as QrCode } from './QrCode';

// Call Center
export { default as CallCenter } from './CallCenter';

// User Charge Information
export { default as UserChargeInformation } from './UserChargeInformations/UserChargeInformation';
export { default as UserChargeInformationList } from './UserChargeInformations/UserChargeInformationList';
export { default as UserChargeInformationDetail } from './UserChargeInformations/UserChargeInformationDetail';

// Arıza Kayıtları
export { default as FaultRecord } from './faultRecord/FaultRecord';
export { default as FaultRecordDetail } from './faultRecord/FaultRecordDetail';

// Hizmet Sağlayıcıları
export { default as ServiceProviders } from './serviceProviders/ServiceProviders';
export { default as ServiceProvidersDetail } from './serviceProviders/ServiceProvidersDetail';

// Bize Ulaşın
export { default as ContactUs } from './contactUs/ContactUs';
export { default as ContactUsDetail } from './contactUs/ContactUsDetail';
export { default as ActiveCalls } from './contactUs/ActiveCalls';
export { default as HistoryCall } from './contactUs/historyCall/HistoryCall';
export { default as HistoryCallDetail } from './contactUs/historyCall/HistoryCallDetail';

// Rfid
export { default as Rfid } from './rfid/Rfid';
export { default as RfidDetail } from './rfid/RfidDetail';

// Raporlar
export { default as Reports } from './reports/Reports';
export { default as ReportsDetail } from './reports/ReportsDetail';

// Rfid
export { default as RfidBalance } from './rfidBalance/RfidBalance';
export { default as RfidBalanceDetail } from './rfidBalance/RfidBalanceDetail';

// Cms Log
export { default as CmsLog } from './log/CmsLog';

// Rfid
export { default as RfidTransactions } from './rfidTransaction/RfidTransactions';
export { default as RfidTransactionDetail } from './rfidTransaction/RfidTransactionDetail';



// Yetkilendirmeler
export { default as Authorization } from './authorization/Authorization';
export { default as AuthorizationDetail } from './authorization/AuthorizationDetail';


// aktif şarjlar
export { default as ActiveCharge } from './activeCharge/ActiveCharge';

// tesis raporları
export { default as FacilityReport } from './facilityReport/FacilityReport';
export { default as UserCharges } from './facilityReport/UserCharges';