import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm, Breadcrumb } from 'antd';
import api from '../../service/index';
import { Link, useParams } from 'react-router-dom';
import qs from 'qs';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  Loading3QuartersOutlined,
  LeftOutlined,
  ClusterOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';

const Chargepoint = (props) => {
  const { Search } = Input;
  let params = useParams();
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  let id = params.id;
  let stationId = props.location.pathname.split('/')[3];
  const [user] = useGlobal('user');
  const { auth } = useSelector((state) => state.auth);

  let query = [];
  query['stationId'] = stationId;
  query['deleted'] = false;

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'unique_id, name, address';
    }

    let restData = await api
      .get(`/rest/chargepoints/list?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.map((item, key) => {
            if (item.hasDC) item.hasDC = <div className="true-circle"></div>;
            else item.hasDC = <div className="false-circle"></div>;

            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/chargepoints/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };

  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/chargepoints/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let columns = [
    {
      title: languagesCms.DEVICE_ID,
      dataIndex: 'unique_id',
      key: 'unique_id',
      sorter: (a, b) => a.unique_id - b.unique_id,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Şarj İstasyonu',
      dataIndex: 'station_name',
      key: 'station_name',
      sorter: (a, b) => a.station_name - b.station_name,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'AC',
      dataIndex: 'ac',
      key: 'ac',
      sorter: (a, b) => a.ac - b.ac,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'DC',
      dataIndex: 'dc',
      key: 'dc',
      sorter: (a, b) => a.dc - b.dc,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'right',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={'/stations/detail/' + stationId + '/' + record.unique_id}>
            <Button icon={<ClusterOutlined />}>
              {languagesCms.CONNECTORS}
            </Button>
          </Link>
          {!(
            auth &&
            auth
              .find((a) => a.number === user.role)
              .restraintList.includes('edit')
          ) && (
            <Link to={'/stations/detail/' + stationId + '/edit/' + record._id}>
              <Button icon={<EditOutlined />}>
                {!isSmall && languagesCms.EDIT}
              </Button>
            </Link>
          )}
          {((user && user.role && user.role === 51) ||
            !(
              auth &&
              auth
                .find((a) => a.number === user.role)
                .restraintList.includes('delete')
            )) && (
            <Popconfirm
              onConfirm={() => deleteRow(record._id)}
              title={languagesCms.CHECK_DELETE}
              okText={languagesCms.OK_TEXT}
              cancelText={languagesCms.CANCEL_TEXT}
            >
              <Button type="danger" icon={<DeleteOutlined />}>
                {!isSmall && languagesCms.DELETE}
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h4
            style={{
              display: 'inline-block',
              marginRight: '2rem',
              marginBottom: '.1rem',
              color: '#fff',
            }}
          >
            {languagesCms.DEVİCES}
          </h4>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a
                style={{
                  color: '#fff',
                }}
                href="/stations"
              >
                {'Şarj İstasyonu'}{' '}
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              style={{
                color: '#fff',
              }}
            >
              {data.length > 0 && data[0].station_name
                ? data[0].station_name
                : ''}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="list-buttons">
          {!isSmall &&
            !(
              auth &&
              auth
                .find((a) => a.number === user.role)
                .restraintList.includes('delete')
            ) && (
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                size="large"
                onClick={deleteSelectedRows}
                style={{ marginRight: '5px' }}
              >
                {languagesCms.DELETE_SELECTED}
              </Button>
            )}
          {((user && user.role && user.role === 51) ||
            !(
              auth &&
              auth
                .find((a) => a.number === user.role)
                .restraintList.includes('add')
            )) && (
            <Link
              to={'/stations/detail/' + id + '/add'}
              style={{ marginRight: '5px' }}
            >
              <Button type="light" icon={<PlusOutlined />} size={'large'}>
                {!isSmall && 'Cihaz Ekle'}
              </Button>
            </Link>
          )}
          <Link to="/stations">
            <Button
              type="light"
              icon={<LeftOutlined />}
              style={{ marginLeft: '5px' }}
              size={'large'}
            >
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder={languagesCms.SEARCH_NAME_LASTNAME_PHONE}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default Chargepoint;
