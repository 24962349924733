import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { Table } from 'antd';
import api from '../../service/index';
import qs from 'qs';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import moment from 'moment';

const ActiveCharge = () => {
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [loading, setLoading] = useState(false);
  let [modules] = useGlobal('modules');
  let query = [];

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    let restData = await api
      .get(`/rest/active-charges?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return (
            data &&
            data.result &&
            data.result.rows.map((item, key) => {
              item.key = key;
              return item;
            })
          );
        }
      });
    setData(restData);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  const columns = [
    {
      title: 'İstasyon',
      dataIndex: 'stationName',
      key: 'stationName',
      align: 'center',
    },
    {
      title: 'Rfid Numarası',
      dataIndex: 'idTag',
      key: 'idTag',
      align: 'center',
    },
    {
      title: 'Kullanıcı',
      dataIndex: 'userName',
      key: 'userName',
      align: 'center',
    },
    {
      title: 'Hizmet Bedeli',
      dataIndex: 'serviceFee',
      key: 'serviceFee',
      align: 'center',
      ellipsis: true,
      render: (text) =>  <span className='font-bold fw-bold text-danger'>{text ? text + ' %' : '0'}</span>,
    },
    {
      title: 'Enerji Maliyeti',
      dataIndex: 'energyCost',
      key: 'energyCost',
      align: 'center',
      ellipsis: true,
      render: (text) => <span className='text-bold fw-bold text-success'>{text ? text + ' TL' : '0'}</span>,
    },
    {
      title: 'Tutar (Hizmet Bedeli Dahil)',
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      ellipsis: true,
      render: (text) => <span className='text-bold fw-bold text-success'>{text ? text + ' TL' : '0'}</span>,
    },
    {
      title: 'Başlangıç Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      ellipsis: true,
      render: (text) => moment(text).format('DD/MM/YYYY HH:mm'),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            Aktif Şarjlar
          </h1>
        </div>
        <div className="list-buttons"></div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default ActiveCharge;
