import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button } from 'antd';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import moment from 'moment';

const DealerCosts = (props) => {
  let [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [languagesCms] = useGlobal('languagesCms');
  const [modules] = useGlobal('modules');
  const path = props.location.pathname.split('/')[1];
  let [module] = modules.filter((el) => el._id === path);
  const [dealerInfo] = useState([]);
  const dealerSet = [...new Set(dealerInfo.map(JSON.stringify))].map(
    JSON.parse,
  );

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let query = [];

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = '-createdAt';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    const restData = await api
      .get(`/rest/dealer-cost?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result && data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            dealerInfo.push({ text: item.dealer, value: item.dealer });
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
    setLoading(false);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  let columns = [
    {
      title: 'Tesis',
      dataIndex: 'dealer',
      key: 'dealer',
      align: 'center',
      sorter: (a, b) => a.dealer - b.dealer,
      sortDirections: ['descend', 'ascend'],
      filters: dealerSet,
      onFilter: (value, record) =>
        record && record.dealer && record.dealer === value,
    },
    {
      title: 'Başlangıç Tarihi',
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'center',
      sorter: (a, b) => a.startDate - b.startDate,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span>{moment(text).format('YYYY-DD-MM')}</span>
      ),
    },
    {
      title: 'Bitiş Tarihi',
      dataIndex: 'endDate',
      key: 'endDate',
      align: 'center',
      sorter: (a, b) => a.endDate - b.endDate,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span>{moment(text).format('YYYY-DD-MM')}</span>
      ),
    },
    {
      title: 'Birim Maliyet ( TL / kWh )',
      dataIndex: 'unitCost',
      key: 'unitCost',
      align: 'center',
      render: (record, text) => Intl.NumberFormat('tr-TR').format(record),
    },
    {
      title: 'Toplam Tüketim ( kWh )',
      dataIndex: 'siteTotalConsumption',
      key: 'siteTotalConsumption',
      align: 'center',
    },
    {
      title: 'Toplam Hizmet Maliyeti ( TL )',
      dataIndex: 'siteTotalServiceCharge',
      key: 'siteTotalServiceCharge',
      align: 'center',
      render: (record, text) => Intl.NumberFormat('tr-TR').format(record),
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'right',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={'/dealer-costs/view/' + record._id}>
            <Button
              style={{
                backgroundColor: '#f5f7f6',
                color: '#000',
                border: 'solid 1px #00BD6D',
                borderRadius: '7px',
              }}
              icon={
                <i
                  style={{ fontSize: '1rem' }}
                  className="fa-solid fa-file-contract"
                ></i>
              }
            >
              Görüntüle
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}{' '}
          </h1>
        </div>
        <div className="list-buttons">
          <Link to={'/dealer-costs/add'}>
            <Button
              type="light"
              icon={<i className="fa-solid fa-file-circle-plus"></i>}
            >
              Maliyet Ekle
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          rowClassName={(record, index) =>
            record.completed ? 'disabled-row' : ''
          }
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default DealerCosts;
