import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Alert,
  Select,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import { FileInputSingle } from '../../components';
import PhoneInput from 'react-phone-input-2';

const DealerDetail = (props) => {
  const [form] = Form.useForm();

  const params = useParams();
  const history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  const [languagesCms] = useGlobal('languagesCms');

  let newRecord = {
    name: '',
    phoneNumber: '',
    iban: '',
    address: '',
    contract: '',
    paymentType: '',
    monthlyFee: 0,
  };

  let [data, setData] = useState(id ? [] : newRecord);
  let [loading, setLoading] = useState(id ? true : false);
  let [isSmall] = useGlobal('isSmall');
  const [validationCheck, setValidationCheck] = useState(false);
  let [errors, setErrors] = useState([]);
  let [modules] = useGlobal('modules');

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/dealers/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.name === null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED;

    if (data.phoneNumber === null || data.phoneNumber === '')
      errors.phoneNumber = languagesCms.ENFORCED;

    if (data.contract === null || data.contract === '')
      errors.contract = languagesCms.ENFORCED;

    if (
      data.paymentType === null ||
      data.paymentType === undefined ||
      data.paymentType === ''
    )
      errors.paymentType = languagesCms.ENFORCED;

    if (
      data.address === null ||
      data.address === undefined ||
      data.address === ''
    )
      errors.address = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  console.log(data);
  console.log(id);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  const onFinish = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/dealers/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/dealers');
            } else message.error(result_message.message, 2);
          });
      } else {
        api
          .post('/rest/dealers', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/dealers');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };
  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Tesis {!id ? 'Ekle' : 'Düzenle'}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/dealers">
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>{id ? languagesCms.EDIT : languagesCms.ADD_NEW}</span>
              <span>
                {id && (
                  <>
                    <b>Tesis No: </b>
                    {data && data.facilityNumber ? data.facilityNumber : ''}
                  </>
                )}
              </span>
            </div>
          }
          loading={loading}
        >
          <Form
            form={form}
            className="w-100"
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="on"
            size="large"
          >
            <Row>
              <Col span={12} className="gutter-row">
                <Form.Item
                  label="Tesis Adı"
                  required
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="name"
                    value={data && data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col span={12} className="gutter-row">
                <Form.Item
                  label="Telefon Numarası"
                  required
                  help={errors.phoneNumber}
                  validateStatus={errors.phoneNumber ? 'error' : 'success'}
                >
                  <PhoneInput
                    width={'100%'}
                    country={'tr'}
                    value={data.phoneNumber}
                    onChange={(e) => setData({ ...data, phoneNumber: e })}
                  />
                </Form.Item>
              </Col>
              <Col span={12} className="gutter-row">
                <Form.Item
                  label="Adresi"
                  required
                  help={errors.address}
                  validateStatus={errors.address ? 'error' : 'success'}
                >
                  <Input.TextArea
                    name="address"
                    value={data && data.address}
                    autoSize={{
                      minRows: 2,
                      maxRows: 6,
                    }}
                    onChange={(e) =>
                      setData({ ...data, address: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12} className="gutter-row">
                <Form.Item
                  name={'iban'}
                  rules={[
                    {
                      pattern: new RegExp(
                        '^TR[0-9]{2}[a-zA-Z0-9]{4}[0-9]{10}$',
                      ),
                      message: 'Iban Numarası Hatalı ( Örnek Iban : TR1234567890123456 ), Tamamını Doğru girdiğinizde Uyarı Kalkacaktır',
                    },
                    {
                      max: 18,
                      message: 'Iban Numarası 16 Karakterden Fazla Olamaz',
                    },
                  ]}
                  label="İban Numarası"
                >
                  <Input
                    name="iban"
                    value={data && data.iban}
                    defaultValue={data && data.iban}
                    onChange={(e) => setData({ ...data, iban: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col span={12} className="gutter-row">
                <Form.Item
                  required
                  help={errors.paymentType}
                  validateStatus={errors.paymentType ? 'error' : 'success'}
                  label="Ödeme Tipi"
                  name="paymentType"
                  large
                >
                  <Select
                    defaultValue={data && data.paymentType}
                    placeholder="Ödeme Tipi Seçiniz"
                    onChange={(v) => {
                      if (v === 'subscription')
                        setData({ ...data, paymentType: v, monthlyFee: 200 });
                      else
                        setData({
                          ...data,
                          paymentType: v,
                          monthlyFee: 0,
                        });
                    }}
                  >
                    <Select.Option key={'pre-paid'} value={'pre-paid'}>
                      Ön Ödemeli
                    </Select.Option>
                    <Select.Option
                      key={'reflection-on-dues'}
                      value={'reflection-on-dues'}
                    >
                      Aidata Yansıtma
                    </Select.Option>
                    <Select.Option key={'subscription'} value={'subscription'}>
                      Abonelik
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              {data && data.paymentType === 'subscription' && (
                <Col span={12} className="gutter-row">
                  <Form.Item label="Aylık Rfid Kart Bedeli">
                    <Input
                      name="monthlyFee"
                      value={data && data.monthlyFee}
                      defaultValue="200"
                      addonAfter="TL"
                      onChange={(e) =>
                        setData({ ...data, monthlyFee: Number(e.target.value) })
                      }
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span={12} className="gutter-row">
                <Form.Item
                  required
                  help={errors.contract}
                  validateStatus={errors.contract ? 'error' : 'success'}
                  label="Kontrat"
                  name="contract"
                  large
                >
                  <FileInputSingle
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="contract"
                    fileType="pdf"
                  />
                  <Alert
                    message="Lütfen Pdf Formatında Yükleme Yapınız"
                    banner
                  />
                </Form.Item>
              </Col>
            </Row>
            {id && (
              <div className="ms-5 mb-5 mt-5">
                <p>Tesis Yöneticisi : </p>
                <span
                  style={{
                    fontFamily: 'Gilroy Bold',
                    fontSize: '1.5rem',
                  }}
                >
                  {data && data.user && data.user[0]
                    ? data.user[0].name + ' ' + data.user[0].lastname
                    : 'Yönetici atanmadı !!!'}
                </span>
                <p
                  style={{
                    fontFamily: 'Gilroy Bold',
                    color: '#ea580c',
                  }}
                >
                  {`Not: Yöneticiler -> "Düzenle" ( Atanmak istenen Yönetici ) -> Tesis Seçimi -> Seçim ( Atanmak istenen Tesis ) -> Kaydet`}
                </p>
              </div>
            )}
            <Row>
              <Col span={24}>
                <Form.Item
                  wrapperCol={{
                    span: 24,
                  }}
                >
                  <Button
                    className="save-button"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    block
                  >
                    {id ? 'Güncelle' : 'Kaydet'}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default DealerDetail;
