import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import tr from 'date-fns/locale/tr';
import en from 'date-fns/locale/en-US';
import { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
registerLocale('tr', tr);
registerLocale('en', en);

const DatePicker = ({ record, setRecord, name, format, showTimeInput }) => {
  const [langCode] = useGlobal('langCode');
  const [currentLang, setCurrentLang] = useState(
    langCode && langCode.toLowerCase(),
  );

  useEffect(() => {
    if (langCode && langCode === 'TR') setCurrentLang('tr');
    else if (langCode === 'EN') setCurrentLang('en');
  }, [langCode]);

  return (
    <ReactDatePicker
      locale={currentLang || "tr"}
      selected={
        record && record[name] ? moment(record[name]).toDate() : new Date()
      }
      onChange={(date) =>
        setRecord({
          ...record,
          [name]: moment(date).format(format ? format : 'YYYY-MM-DD'),
        })
      }
      inline
      dateFormat={format ? format : 'YYYY-MM-DD'}
      showTimeInput={showTimeInput ? true : false}
    />
  );
};

export default DatePicker;
