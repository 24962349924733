import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';
import { useParams } from 'react-router-dom';

export default (props) => {
  const params = useParams();
  let { record, setRecord, name, mode, disabled } = props;
  let [station, setStation] = useState([]);
  let _params = {
    sort: '-createdAt',
    page: 1,
    pageSize: 100000,
    cmsQuery: { stationId: params && params.chargepoint },
  };

  useEffect(() => {
    api
      .get(`/rest/connectors/cms-query?${qs.stringify(_params)}`)
      .then(async ({ data }) => {
        console.log(data);
        if (data && data.result) {
          const dbCpo = data.result.sort((a, b) => {
            return a && a.name && a.name.localeCompare(b.name);
          });

          setStation(dbCpo);
        }
      });
  }, [record]);

  return (
    <Select
      value={record[name] || []}
      showSearch={true}
      mode={mode}
      placeholder={'Şarj İstasyonu Seçiniz'}
      onChange={(v) => setRecord({ ...record, [name]: v })}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled || false}
    >
      {station &&
        station.map((b) => (
          <Select.Option key={b._id} value={b.unique_id}>
            {b.name}
          </Select.Option>
        ))}
    </Select>
  );
};
