import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Switch,
  Breadcrumb,
  Select,
  TimePicker,
  Checkbox,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import {
  CityPicker,
  MapInput,
  CountryPicker,
  DealerPicker,
  FileInput,
  CpAdminPicker,
} from '../../components';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import moment from 'moment';
import locale from 'antd/lib/date-picker/locale/tr_TR';
import StationManagers from '../../components/stationModal/stationManagers';

const StationsDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let [languagesCms] = useGlobal('languagesCms');
  const [user] = useGlobal('user');
  const [authorizations] = useGlobal('authorizations');
  const [filters, setFilters] = useState([]);

  let newRecord = {
    name: '',
    active: true,
    rechargeable: true,
    countryId: '',
    cityId: '',
    floor_location: 0,
    reservation: 'available',
    park_area: 0,
    free_parking: '1',
    stAdminId: '',
    payment_method: [],
    facilityId: '',
    unique_id: '',
    type_of_use: '',
    epdkStationNo: '',
    workingHours: '00:00 - 23:59',
    locationServices: [],
    coordinate: {
      address: '',
      position: {
        lat: 36.93447,
        lng: 36.57154,
      },
    },
    images: [],
    stationManagers: {},
  };

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [isSmall] = useGlobal('isSmall');

  let [modules] = useGlobal('modules');

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/stations/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
    api
      .get('/rest/get-filters')
      .then(({ data: { result, result_message } }) => {
        console.log(result);
        console.log(result_message);
        if (result) {
          result = result && result.filter((a) => a.groupName !== 'station');
          setFilters(result);
        } else message.error('Filtreler Getirilemedi');
      });
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.name === null || data.name.length === 0)
      errors.name = languagesCms.ENFORCED;

    if (
      data.unique_id === null ||
      data.unique_id === undefined ||
      data.unique_id.length === 0
    )
      errors.unique_id = languagesCms.ENFORCED;

    if (
      data.workingHours === undefined ||
      data.workingHours === null ||
      data.workingHours === ''
    )
      errors.workingHours = languagesCms.ENFORCED;

    if (
      data.facilityId === undefined ||
      data.facilityId === null ||
      data.facilityId === ''
    )
      errors.facilityId = languagesCms.ENFORCED;

    if (
      data.countryId === undefined ||
      data.countryId === null ||
      data.countryId === ''
    )
      errors.countryId = languagesCms.ENFORCED;

    if (
      data.images === undefined ||
      data.images === null ||
      data.images === '' ||
      data.images.length === 0
    )
      errors.images = languagesCms.ENFORCED;

    if (
      data.stAdminId === undefined ||
      data.stAdminId === null ||
      data.stAdminId === ''
    )
      errors.stAdminId = languagesCms.ENFORCED;

    if (
      data.type_of_use === undefined ||
      data.type_of_use === null ||
      data.type_of_use === ''
    )
      errors.type_of_use = languagesCms.ENFORCED;

    if (
      data.payment_method === undefined ||
      data.payment_method === null ||
      data.payment_method === '' ||
      data.payment_method.length === 0
    )
      errors.payment_method = languagesCms.ENFORCED;

    if (
      data.locationServices === undefined ||
      data.locationServices === null ||
      data.locationServices === '' ||
      data.locationServices.length === 0
    )
      errors.locationServices = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  console.log(data);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/stations/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/stations');
            } else message.error(result_message.message, 2);
          });
      } else {
        api
          .post('/rest/stations', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/stations');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  const locationServices = filters.map((item) => {
    return {
      label: item.title,
      value: item.key,
    };
  });

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h3 style={{ marginBottom: '8px' }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a style={{ color: '#fff', fontSize: '18px' }} href="/stations">
                  {'Şarj İstasyonu'}
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item style={{ color: '#fff', fontSize: '18px' }}>
                {data && data.name ? data && data.name : ''}
              </Breadcrumb.Item>
            </Breadcrumb>
          </h3>
        </div>
        <div className="list-buttons">
          <Link to="/stations">
            <Button type="light" icon={<LeftOutlined />} size={'large'}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form
            disabled={
              authorizations &&
              authorizations
                .find((a) => a.number === user.role)
                .restraintList.includes('edit')
            }
            layout="horizontal"
            size={'large'}
            onFinish={save}
          >
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  required
                  label={languagesCms.NAME}
                  help={errors.name}
                  validateStatus={errors.name ? 'error' : 'success'}
                >
                  <Input
                    name="name"
                    value={data && data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Row>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label={languagesCms.STATUS}
                      labelCol={{
                        span: 24,
                      }}
                      required
                      help={errors.active}
                      validateStatus={errors.active ? 'error' : 'success'}
                    >
                      <Switch
                        style={{
                          backgroundColor:
                            data && data.active ? '#00BD6D' : 'red',
                          marginLeft: '23px',
                        }}
                        checked={data && data.active ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          v === true
                            ? setData({ ...data, active: v, rechargeable: v })
                            : setData({ ...data, active: v })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label="Şarj Edilebilir"
                      required
                      labelCol={{
                        span: 24,
                      }}
                      help={errors.rechargeable}
                      validateStatus={errors.rechargeable ? 'error' : 'success'}
                    >
                      <Switch
                        style={{
                          backgroundColor:
                            data && data.rechargeable ? '#00BD6D' : 'red',
                          marginLeft: '23px',
                        }}
                        checked={data && data.rechargeable ? true : false}
                        checkedChildren={languagesCms.ACTIVE}
                        unCheckedChildren={languagesCms.PASSIVE}
                        onChange={(v) =>
                          data && data.active === false
                            ? setData({ ...data, rechargeable: v })
                            : message.warning(
                                'Aktif Cihaz Şarja Kapatılamaz !!!',
                              )
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  required
                  label={languagesCms.COUNTRY}
                  help={errors.countryId}
                  validateStatus={errors.countryId ? 'error' : 'success'}
                >
                  <CountryPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="countryId"
                    mode="single"
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  required
                  label={languagesCms.CITY}
                  help={errors.cityId}
                  validateStatus={errors.cityId ? 'error' : 'success'}
                >
                  <CityPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="cityId"
                    mode="single"
                    disabled={data && data.countryId ? false : true}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  required
                  label="Resimler"
                  help={errors.images}
                  validateStatus={errors.images ? 'error' : 'success'}
                >
                  <FileInput
                    languagesCms={languagesCms}
                    name="images"
                    title="Medyalar"
                    type="file"
                    multi={false}
                    ext={['image']}
                    record={data}
                    setRecord={setData}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={languagesCms.UNIQUE_ID}
                  help={errors.unique_id}
                  required
                  validateStatus={errors.unique_id ? 'error' : 'success'}
                >
                  <Input
                    name="unique_id"
                    value={data && data.unique_id}
                    onChange={(e) =>
                      setData({ ...data, unique_id: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="İstasyon No ( EPDK )">
                  <Input
                    name="epdkStationNo"
                    value={data && data.epdkStationNo}
                    onChange={(e) =>
                      setData({ ...data, epdkStationNo: e.target.value })
                    }
                  />
                  <b style={{ fontSize: '11px', color: '#ea580c' }}>
                    <i className="fa-solid fa-info  fs-6 me-2"></i> Lütfen Epdk
                    taradından belirlenen İstasyon No giriniz !!!
                  </b>
                  <br />
                  <b style={{ fontSize: '11px', color: '#ea580c' }}>
                    <i className="fa-solid fa-info fs-6 me-2"></i> Test için
                    açılan istasyonda bu alan boş bırakılmalıdır !!!
                  </b>
                </Form.Item>
              </Col>
              {console.log(data)}
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.RESERVATION}
                  help={errors.reservation}
                  validateStatus={errors.reservation ? 'error' : 'success'}
                >
                  <Select
                    defaultValue={data && data.reservation}
                    onChange={(e) => setData({ ...data, reservation: e })}
                  >
                    <Select.Option key={'available'} value={'available'}>
                      {'Available'}
                    </Select.Option>
                    <Select.Option
                      key={'not_available'}
                      value={'not_available'}
                    >
                      {'Not Available'}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.REGION}>
                  <Input
                    name="region"
                    placeholder={languagesCms.CITY_COUNTRY}
                    value={data && data.region}
                    onChange={(e) =>
                      setData({ ...data, region: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Tesis'}
                  required
                  help={errors.facilityId}
                  validateStatus={errors.facilityId ? 'error' : 'success'}
                >
                  <DealerPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="facilityId"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'ST Admin'}
                  required
                  help={errors.stAdminId}
                  validateStatus={errors.stAdminId ? 'error' : 'success'}
                >
                  <CpAdminPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="stAdminId"
                    mode="multiple"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Kullanım Türü'}
                  help={errors.type_of_use}
                  required
                  validateStatus={errors.type_of_use ? 'error' : 'success'}
                >
                  <Select
                    defaultValue={data.type_of_use}
                    onChange={(e) => setData({ ...data, type_of_use: e })}
                  >
                    <Select.Option key={'public'} value={'public'}>
                      {'Halka Açık'}
                    </Select.Option>
                    <Select.Option key={'private'} value={'private'}>
                      {'Özel'}
                    </Select.Option>
                    <Select.Option
                      key={'private'}
                      value={'facility specific (fleet)'}
                    >
                      {'Tesise Özel ( Filo )'}
                    </Select.Option>
                    <Select.Option
                      key={'private'}
                      value={'facility specific (site)'}
                    >
                      {'Tesise Özel ( Site )'}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={'Ödeme Şekli'}
                  help={errors.payment_method}
                  required
                  validateStatus={errors.payment_method ? 'error' : 'success'}
                >
                  <Select
                    mode="multiple"
                    value={(data && data.payment_method) || []}
                    onChange={(e) => setData({ ...data, payment_method: e })}
                  >
                    <Select.Option key={'rfid'} value={'rfid'}>
                      RFID
                    </Select.Option>
                    <Select.Option key={'creditCard'} value={'creditCard'}>
                      Kredi Kartı
                    </Select.Option>
                    <Select.Option
                      key={'mobilePayment'}
                      value={'mobilePayment'}
                    >
                      Mobil Ödeme
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={'Konum Hizmetleri'}
                  help={errors.locationServices}
                  required
                  validateStatus={errors.locationServices ? 'error' : 'success'}
                >
                  <Checkbox.Group
                    style={{ overflow: 'hidden' }}
                    options={locationServices}
                    defaultValue={data.locationServices}
                    onChange={(e) =>
                      setData({ ...data, ...{ locationServices: e } })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Çalışma Saatleri"
                  help={errors && errors.workingHours}
                  validateStatus={
                    errors && errors.workingHours ? 'error' : 'success'
                  }
                >
                  <TimePicker.RangePicker
                    locale={locale}
                    name="workingHours"
                    format={'HH:mm'}
                    defaultValue={
                      data &&
                      data.workingHours && [
                        moment(data.workingHours.split(' ')[0], 'HH:mm'),
                        moment(data.workingHours.split(' ')[2], 'HH:mm'),
                      ]
                    }
                    onChange={(e, time) =>
                      setData({
                        ...data,
                        workingHours: time[0] + ' - ' + time[1],
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={'Firma Sorumlusu'}>
                  <StationManagers name="companyManager" record={data} setRecord={setData} id={id} modalName={`Firma Sorumlusu ${id ? "Düzenle" : "Ekle"}`} />
                  <br />
                  <b style={{ fontSize: '11px', color: '#ea580c' }}>
                    <i className="fa-solid fa-info fs-6 me-2"></i>İsteğe bağlı
                    firma sorumlusu eklenebilir.
                  </b>
                  <br />
                  <b style={{ fontSize: '11px', color: '#ea580c' }}>
                    <i className="fa-solid fa-info fs-6 me-2"></i>
                    {`Bu alana eklenen bilgiler İzleme -> İletişim alanında görüntülenir.`}
                  </b>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={'Elektrik Sorumlusu'}>
                  <StationManagers name="electricalManager" record={data} setRecord={setData} id={id} modalName={`Elektrik Sorumlusu ${id ? "Düzenle" : "Ekle"}`} />
                  <br />
                  <b style={{ fontSize: '11px', color: '#ea580c' }}>
                    <i className="fa-solid fa-info fs-6 me-2"></i>İsteğe bağlı
                    firma sorumlusu eklenebilir.
                  </b>
                  <br />
                  <b style={{ fontSize: '11px', color: '#ea580c' }}>
                    <i className="fa-solid fa-info fs-6 me-2"></i>
                    {`Bu alana eklenen bilgiler İzleme -> İletişim alanında görüntülenir.`}
                  </b>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={'Muhasebe Sorumlusu'}>
                  <StationManagers name="accountant" record={data} setRecord={setData} id={id} modalName={`Muhasebe Sorumlusu ${id ? "Düzenle" : "Ekle"}`} />
                  <br />
                  <b style={{ fontSize: '11px', color: '#ea580c' }}>
                    <i className="fa-solid fa-info fs-6 me-2"></i>İsteğe bağlı
                    firma sorumlusu eklenebilir.
                  </b>
                  <br />
                  <b style={{ fontSize: '11px', color: '#ea580c' }}>
                    <i className="fa-solid fa-info fs-6 me-2"></i>
                    {`Bu alana eklenen bilgiler İzleme -> İletişim alanında görüntülenir.`}
                  </b>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={'Network/Bilgi İşlem Sorumlusu'}>
                  {/* {
                    data && data.stationManagers && stationManagers
                  } */}
                  <StationManagers name="internetManager" record={data} setRecord={setData} id={id} modalName={`Network/Bilgi İşlem Sorumlusu ${id ? "Düzenle" : "Ekle"}`} />
                  <br />
                  <b style={{ fontSize: '11px', color: '#ea580c' }}>
                    <i className="fa-solid fa-info fs-6 me-2"></i>İsteğe bağlı
                    firma sorumlusu eklenebilir.
                  </b>
                  <br />
                  <b style={{ fontSize: '11px', color: '#ea580c' }}>
                    <i className="fa-solid fa-info fs-6 me-2"></i>
                    {`Bu alana eklenen bilgiler İzleme -> İletişim alanında görüntülenir.`}
                  </b>
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.ADRESS}
                  help={errors.address}
                  validateStatus={errors.address ? 'error' : 'success'}
                >
                  <MapInput
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="coordinate"
                    disabled={false}
                    title="coordinate"
                    required={false}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                    style={{ backgroundColor: '#9eb23b', border: 'none' }}
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default StationsDetail;
