import React from 'react'
import { useGlobal } from 'reactn';
import {Button} from 'antd'
import {Link} from 'react-router-dom'
import {HomeOutlined} from '@ant-design/icons'

let NotFoundPage = () => {
    let [languagesCms] = useGlobal('languagesCms');
    return (
        <div className="mt-50 text-center font-xl font-bold font-mobile">
            <p>Aradığınız Sayfayı Bulamadık.</p>
            <div className="flex items-center justify-center max-height">
                <img className="notfound-image" src="/images/404.png" alt="404"/>
            </div>
            <Link to={"/postwall"}>
                <Button type='primary' shape="round" size='large' icon={<HomeOutlined/>}> {languagesCms.GO_TO_HOMEPAGE} </Button>
            </Link>
        </div>
    )
}
export default NotFoundPage