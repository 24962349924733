import React, { useState, useEffect } from 'react';
import api from '../../service/index';
import { Select } from 'antd';
import qs from 'qs';

const SelectFromAllUser = (props) => {
  const { record, setRecord, name, mode, disabled, facilityId } = props;
  const [user, setUser] = useState([]);
  const _params = {
    sort: '-createdAt',
    page: 1,
    pageSize: 100000,
  };

  useEffect(() => {
    console.log('değişiklik tekrar çek');
    api
      .get(`/rest/users-all/${facilityId}?${qs.stringify(_params)}`)
      .then(async ({ data }) => {
        if (data && data.result) {
          const dbUser = data.result.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });

          setUser(dbUser);
        }
      });
  }, [facilityId]);

  return (
    <Select
      value={(record && record[name]) || []}
      showSearch={true}
      mode={mode}
      placeholder={'Kullanıcı Seçiniz'}
      onChange={(v) => setRecord({ ...record, [name]: v })}
      // filterOption={(input, option) => {
      //   if (option.children) {
      //     return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      //       ? true
      //       : false;
      //   } else if (option.label) {
      //     return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      //       ? true
      //       : false;
      //   }
      // }}
      disabled={disabled || false}
    >
      {user &&
        user.map((b) => (
          <Select.Option key={b._id} value={b._id}>
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              {b.name + ' ' + b.lastname}
            </span>
            <span
              style={{
                color: 'red',
                fontWeight: 'bold',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;{b.phone}
            </span>
          </Select.Option>
        ))}
    </Select>
  );
};

export default React.memo(SelectFromAllUser);
