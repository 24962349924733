import React, { useEffect } from 'react';
import { setGlobal, useGlobal } from 'reactn';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import Login from './pages/Login';
import Admin from './Admin';
import axios from 'axios';
import api from './service';
import Cookies from 'js-cookie';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { setAuth } from './stores/auth';
import { setAlert } from './stores/alert';
import { message } from 'antd';

axios.interceptors.response.use(
  (result) => {
    return result;
  },
  (err) => {
    if (err.response && err.response.status === 401) {
      setGlobal({ token: null });
    }
    return Promise.reject(err);
  },
);

setGlobal({
  isSmall: window.innerWidth <= 1024,
  collapsed: false,
  token: Cookies.get('token'),

  user:
    localStorage.getItem('user') === undefined
      ? {}
      : JSON.parse(localStorage.getItem('user')),
  modules:
    localStorage.getItem('modules') === undefined
      ? []
      : JSON.parse(localStorage.getItem('modules')),
  settings:
    localStorage.getItem('settings') === undefined
      ? []
      : JSON.parse(localStorage.getItem('settings')),
  // customization: localStorage.getItem("customization") === undefined ? {} : JSON.parse(localStorage.getItem("customization")),
  langs:
    localStorage.getItem('langs') === undefined
      ? {}
      : JSON.parse(localStorage.getItem('langs')),
  langCms:
    localStorage.getItem('langCms') === undefined
      ? {}
      : JSON.parse(localStorage.getItem('langCms')),
  // langCode:
  //   localStorage.getItem('langCode') === undefined
  //     ? {}
  //     : JSON.parse(localStorage.getItem('langCode')),
  languagesCms:
    localStorage.getItem('languagesCms') === undefined
      ? {}
      : JSON.parse(localStorage.getItem('languagesCms')),
  // logo: localStorage.getItem("logo") === undefined ? "" : JSON.parse(localStorage.getItem("logo")),
  // pdfIcon: localStorage.getItem("pdfIcon") === undefined ? "" : JSON.parse(localStorage.getItem("pdfIcon")),
  // login_setting: localStorage.getItem("login_setting") === undefined ? "" : JSON.parse(localStorage.getItem("login_setting")),
});

const App = (props) => {
  let [, setToken] = useGlobal('token');
  let [isSmall, setIsSmall] = useGlobal('isSmall');
  let [, setCollapsed] = useGlobal('collapsed');
  let [, setLangsCms] = useGlobal('langCms');
  let [langCode, setLangCode] = useGlobal('langCode');
  const dispatch = useDispatch();

  let getLangs = async () => {
    await api.get(`/rest/settings?${qs.stringify()}`).then(({ data }) => {
      if (
        data &&
        data.result_message &&
        data.result_message.message === 'invalid signature'
      ) {
        Cookies.remove('token');
        // return props.history.push("/login");
        return <Redirect to={'/login'} />;
      }
      if (data && data.result && data.result.rows) {
        data.result.rows.forEach((element) => {
          if (element._id === 'supportedCmsLangs') {
            setLangsCms(element.items);
            localStorage.setItem('langsCms', JSON.stringify(element.items));
            if (!langCode) {
              setLangCode(element.items[0].code);
            }
          }
        });
      } else
        message.error(
          (data && data.result_message && data.result_message.message) ||
            'Error',
        );
    });
  };
  useEffect(() => {
    if (!Cookies.get('token')) return;
    api.setHeader('Authorization', 'Bearer ' + Cookies.get('token'));
    api.get(`/rest/authorizations`).then(async ({ data }) => {
      if (data && data.result) {
        await dispatch(setAuth(data.result));
      }
    });
    api.get(`/rest/alert-count`).then(async ({ data }) => {
      if (data && data.result) {
        await dispatch(setAlert(data.result));
      }
    });
  }, []);

  useEffect(() => {
    getLangs();
    window.onresize = () => {
      setIsSmall(window.innerWidth < 1024);
    };
    if (window.location.pathname === '/login' && Cookies.get('token')) {
      window.location.href = '/';
    }
  }, [setIsSmall, setToken]);
  useEffect(() => {
    getLangs();
    if (isSmall) setCollapsed(true);
    else setCollapsed(false);
  }, [isSmall, setCollapsed]);

  return (
    <Router>
      <Switch>
        <Route key="login" path="/login" component={Login} />
        <Route key="pages" path="/" component={Admin} />
      </Switch>
    </Router>
  );
};

export default App;
