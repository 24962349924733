import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button } from 'antd';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import moment from 'moment';

const FaultRecord = (props) => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [languagesCms] = useGlobal('languagesCms');
  const [modules] = useGlobal('modules');
  const path = props.location.pathname.split('/')[1];
  let [module] = modules.filter((el) => el._id === path);
  let query = [];
  query['deleted'] = false;

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    setLoading(true);
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    const restData = await api
      .get(`/rest/fault-record?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result && data.result) {
          setTotalCount(data.result.total);
          return (
            data.result &&
            data.result.rows.map((item, key) => {
              item.key = key;
              return item;
            })
          );
        }
      });
    setData(restData);
    setLoading(false);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  let columns = [
    {
      title: 'Şarj İstasyonu',
      dataIndex: 'station',
      key: 'station',
      align: 'center',
      sorter: (a, b) => a.station - b.station,
      sortDirections: ['descend', 'ascend'],
      responsive: ['xs', 'sm', 'md'],
    },
    {
      title: 'Tesis',
      dataIndex: 'dealer',
      key: 'dealer',
      align: 'center',
      sorter: (a, b) => a.dealer - b.dealer,
      sortDirections: ['descend', 'ascend'],
      responsive: ['xs', 'sm', 'md'],
    },
    {
      title: 'Cihaz Id',
      dataIndex: 'unique_id',
      key: 'unique_id',
      align: 'center',
      sorter: (a, b) => a.unique_id - b.unique_id,
      sortDirections: ['descend', 'ascend'],
      responsive: ['xs', 'sm', 'md'],
    },
    {
      title: 'Konnektör Id',
      dataIndex: 'connector_id',
      align: 'center',
      key: 'connector_id',
      sorter: (a, b) => a.connector_id - b.connector_id,
      sortDirections: ['descend', 'ascend'],
      responsive: ['md'],
    },
    {
      title: 'Kayıt Tarihi',
      dataIndex: 'createdAt',
      align: 'center',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortDirections: ['descend', 'ascend'],
      render: (text) => moment(text).format('DD-MM-YYYY HH:mm:ss'),
      responsive: ['md'],
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'right',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={'/fault-record/edit/' + record._id}>
            <Button
              style={{
                backgroundColor: '#f5f7f6',
                color: '#000',
                border: 'solid 1px #00BD6D',
                borderRadius: '7px',
              }}
              icon={<i className="fa-solid fa-hammer"></i>}
            >
              Detay
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}{' '}
          </h1>
        </div>
        <div className="list-buttons">
          <Link to={'/fault-record/detail'}>
            <Button
              type="light"
              onClick={() => {
                setData([]);
                setTimeout(() => {
                  get();
                }, 50);
              }}
              icon={<i className="fa-solid fa-plus"></i>}
            >
              Arıza Kaydı Oluştur
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default FaultRecord;
