import { Loading3QuartersOutlined } from '@ant-design/icons';
import { message, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ActiveCallUserModal from '../../components/contactUs/activeCallUserModal';
import api from '../../service';

const ActiveCalls = () => {
  const params = useParams();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const get = () => {
    setLoading(true);
    api
      .get('/rest/contact-us/active-calls/' + params.company)
      .then(({ data: { result, result_message } }) => {
        if (result) {
          setData(result);
          setTotalCount(result && result.length && result.length);
        } else message.error('Arama Bilgisi Alınamadı');
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setLoading(false);
  };

  useEffect(() => {
    get();
  }, []);

  const columns = [
    {
      title: 'Arayan',
      dataIndex: 'callingUser',
      key: 'callingUser',
      align: 'center',
      responsive: ['xs', 'sm', 'md'],
      render: (text, render) => <ActiveCallUserModal user={text} phone={render.src} />
    },
    {
      title: 'Aranan',
      dataIndex: 'searchingUser',
      key: 'searchingUser',
      align: 'center',
      responsive: ['xs', 'sm', 'md'],
    },
    {
      title: 'Arayan Telefon',
      dataIndex: 'src',
      key: 'src',
      align: 'center',
      responsive: ['xs', 'sm', 'md'],
    },
    {
      title: 'Aranan Telefon',
      dataIndex: 'dst',
      key: 'dst',
      align: 'center',
      responsive: ['xs', 'sm', 'md'],
    },
    {
      title: 'Arama Bilgisi',
      dataIndex: 'callinfo',
      key: 'callinfo',
      align: 'center',
      responsive: ['xs', 'sm', 'md'],
    },
    {
      title: 'Arama Tipi',
      dataIndex: 'calltype',
      key: 'calltype',
      align: 'center',
      responsive: ['xs', 'sm', 'md'],
    },
    {
      title: 'Süre',
      dataIndex: 'duration',
      key: 'duration',
      align: 'center',
      responsive: ['xs', 'sm', 'md'],
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            Aktif Çağrılar
          </h1>
        </div>
        <div className="list-buttons"></div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default ActiveCalls;
