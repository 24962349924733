import React, { useState, useEffect } from 'react';
import { useGlobal } from 'reactn';
import { Modal, Form, Col, Row, Button, message } from 'antd';
import LoadingAnimation from '../LoadingAnimation';
import DatePicker from '../Date/DatePicker';
import moment from 'moment';
import qs from 'qs';
import api from '../../service';
import { saveAs } from 'file-saver';
import DealerPicker from '../dealerPicker';

const DateFilter = ({ iconClass, size, className, url, fileName, query }) => {
  const [isSmall] = useGlobal('isSmall');
  const [languagesCms] = useGlobal('languagesCms');
  const [user] = useGlobal('user');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const firstData = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };
  const [resData, setResData] = useState(firstData);

  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(false);
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (resData && (!resData.facilityIds || resData.facilityIds.length === 0)) {
      return message.error('Lütfen Tesis Seçiniz');
    }

    setLoading(false);
    setResData(firstData);
    setIsModalOpen(false);

    const reportUrl = url;
    try {
      const options = {
        method: 'GET',
        decompress: true,
        responseType: 'arraybuffer',
        headers: {
          'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      };
      const result = await api.get(
        `${reportUrl}?${qs.stringify({ ...query, ...resData })}`,
        {
          ...options,
        },
      );

      const blob = new Blob([result.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      saveAs(blob, `${fileName}.xlsx`);
    } catch (error) {
      console.error('Rapor indirme hatası:', error);
    }
  };
  const handleCancel = () => {
    setLoading(false);
    setResData(firstData);
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        type="button"
        danger
        className={className || 'btn btn-danger btn-sm py-0 me-3'}
        size={size || 'large'}
        onClick={showModal}
      >
        <i className={iconClass || `fa-solid fa-clock-rotate-left`}></i>
        {!isSmall && 'Şarj Raporları'}
      </Button>

      <Modal
        title={'Rapor İndir'}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ borderRadius: '21px !important' }}
        okText={'İndir'}
        cancelText={'Vazgeç'}
      >
        <>
          {loading ? (
            <LoadingAnimation />
          ) : (
            <>
              <Form
                form={form}
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                autoComplete="off"
              >
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label={'Başlangıç Tarihi'}
                      labelCol={{
                        span: 24,
                      }}
                      required
                    >
                      <DatePicker
                        record={resData}
                        setRecord={setResData}
                        name="startDate"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label={'Bitiş Tarihi'}
                      labelCol={{
                        span: 24,
                      }}
                      required
                    >
                      <DatePicker
                        record={resData}
                        setRecord={setResData}
                        name="endDate"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <DealerPicker
                    languagesCms={languagesCms}
                    record={resData}
                    setRecord={setResData}
                    name="facilityIds"
                    mode="multiple"
                    dataLimit={
                      [1, 2].includes(user && user.role) === true
                        ? 'allData'
                        : [5].includes(user && user.role) === true
                        ? 'theirSites'
                        : null
                    }
                  />
                </Row>
              </Form>
            </>
          )}
        </>
      </Modal>
    </>
  );
};

export default React.memo(DateFilter);
