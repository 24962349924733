import React, { useState, useEffect } from 'react';
import api from '../../service/index';
import { Select, message } from 'antd';
import qs from 'qs';

const serviceProvidesPicker = (props) => {
  const { record, setRecord, name, mode, disabled } = props;
  const [user, setUser] = useState([]);
  const _params = {
    sort: '-createdAt',
    page: 1,
    pageSize: 100000,
    query: {
      $or: [
        { role: 21 },
        { role: 22 },
        { role: 23 },
        { role: 24 },
        { role: 25 },
      ],
      deleted: { $ne: true },
    },
  };

  useEffect(() => {
    api
      .get(`/rest/attendees/list?${qs.stringify(_params)}`)
      .then(async ({ data: { result, result_message } }) => {
        if (result && result.rows && result.rows.length > 0) {
          const dbUser =
            result &&
            result.rows &&
            result.rows.sort((a, b) => {
              return a.superscription.localeCompare(b.superscription);
            });
          setUser(dbUser);
        } else {
          message.error(result_message.message);
        }
      });
  }, [record]);

  return (
    <Select
      value={(record && record[name]) || []}
      showSearch={true}
      mode={mode}
      placeholder={'Kullanıcı Seçiniz'}
      onChange={(v) => setRecord({ ...record, [name]: v })}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled || false}
    >
      {user &&
        user.map((b) => (
          <Select.Option key={b._id} value={b._id}>
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              {b.superscription}
            </span>
            <span
              style={{
                color: 'red',
                fontWeight: 'bold',
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;{b.phone}
            </span>
          </Select.Option>
        ))}
    </Select>
  );
};

export default React.memo(serviceProvidesPicker);
