import React, { useState, useEffect, useCallback } from 'react';
import {
  Row,
  Col,
  Modal,
  Input,
  Form,
  message,
  Button,
  DatePicker,
} from 'antd';
import './user.css';
import api from '../../service';
import locale from 'antd/lib/date-picker/locale/tr_TR';
import moment from 'moment';

const RfidModal = ({ record, disabled, id, add, userId, facilityId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  let [validationCheck, setValidationCheck] = useState(false);
  let [errors, setErrors] = useState([]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const get = async () => {
    await api
      .get('/rest/rfid/' + id)
      .then(({ data }) => {
        if (data && data.result) {
          console.log('result : ', data.result);
          const plate =
            data &&
            data.result &&
            data.result.carPlate &&
            data.result.carPlate.split('-');

          delete data.result.carPlate;

          console.log('plate: ,', plate);

          data.result['carPlate1'] = plate && plate[0] && plate[0];
          data.result['carPlate2'] = plate && plate[1] && plate[1];
          data.result['carPlate3'] = plate && plate[2] && plate[2];

          return data.result;
        } else {
          message.error('Detay verileri getirilemedi !!!');
        }
      })
      .then((data) => setData(data))
      .catch((err) => {
        console.log(err);
        message.error('Detay verileri getirilemedi !!!');
      });
  };

  const getUser = async () => {
    await api.get('/rest/attendees/' + id).then(({ data }) => {
      if (data && data.result) {
        console.log(data.result);
        record = data.result;
      }
    });
  };

  useEffect(() => {
    if (id && isModalOpen) {
      get();
    }
    if (!id) {
      setData({ status: 'active', cardIssueDate: new Date() });
    }
    if (userId) {
      getUser();
    }
  }, [isModalOpen === true]);

  console.log(errors);
  console.log(record);

  let validate = useCallback(() => {
    let errors = {};

    console.log(data);

    if (
      data.carPlate1 === null ||
      data.carPlate1 === undefined ||
      data.carPlate1 === ''
    )
      errors.carPlate1 = 'Lütfen Araç Plakası Girin';

    if (
      data.carPlate2 === null ||
      data.carPlate2 === undefined ||
      data.carPlate2 === ''
    )
      errors.carPlate2 = 'Lütfen Araç Plakası Girin';
    if (
      data.carPlate3 === null ||
      data.carPlate3 === undefined ||
      data.carPlate3 === ''
    )
      errors.carPlate3 = 'Lütfen Araç Plakası Girin';

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  const onFinish = (values) => {
    const plate =
      data && data.carPlate1 + '-' + data.carPlate2 + '-' + data.carPlate3;

    data.carPlate = plate && plate.toUpperCase();

    console.log(data);
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      delete data.carPlate1;
      delete data.carPlate2;
      delete data.carPlate3;

      console.log('burada');
      if (id) {
        api.put(`/rest/rfid/${id}`, data).then(({ data }) => {
          if (data && data.result) {
            message.success('Rfid başarıyla Güncellendi ...');
            handleOk();
            form.resetFields();
          } else {
            console.log(data);
            message.error(
              data.result_message.message ||
                'Rfid Güncelleme hatalı lütfen çağrı merkezinden destek alınız !!!',
            );
          }
        });
      } else {
        data.facilityId = facilityId || (record && record.facilityId);
        data.userId = userId || (record && record._id);
        api.post('/rest/rfid', data).then(({ data }) => {
          if (data && data.result) {
            message.success('Rfid başarıyla eklendi ...');
            handleOk();
            form.resetFields();
          } else {
            console.log(data);
            message.error(
              data.result_message.message ||
                'Rfid eklenemedi lütfen çağrı merkezinden destek alınız !!!',
            );
          }
        });
      }
    }
  };

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setIsModalOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  console.log(data);
  console.log(errors);

  return (
    <>
      {id ? (
        <Button
          size="middle"
          disabled={disabled}
          className="btn btn-outline-primary btn-sm"
          onClick={showModal}
          icon={<i className="fa-regular fa-pen-to-square"></i>}
        >
          Düzenle
        </Button>
      ) : add === true ? (
        <div>
          <button
            type="button"
            onClick={showModal}
            className="btn btn-outline-primary"
          >
            {' '}
            <i className="fa-solid fa-plus me-1"></i> RFID Ekle
          </button>
        </div>
      ) : (
        <>
          {disabled && (
            <b style={{ fontSize: '11px', color: '#ea580c' }}>
              <i className="fa-solid fa-info fs-6 me-2"></i> Kullanıcıya Rfid
              ekleyebilmek için lütfen bir siteye atama yapınız !!!
            </b>
          )}
        </>
      )}

      <Modal
        title="RFID Yönetimi"
        visible={isModalOpen}
        okText="Kapat"
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        style={{ borderRadius: '21px !important' }}
      >
        <>
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item
                  label="RFID Numarası"
                  name="rfid"
                  required
                  rules={[
                    {
                      required: id ? false : true,
                      message: 'Lütfen açıklama giriniz !!!',
                    },
                    {
                      min: 4,
                      message: 'En Az 4 Karakter Girişi Yapınız!',
                    },
                    {
                      max: 20,
                      message: 'En Fazka 20 Karakter Girişi Yapabilirsiniz!',
                    },
                  ]}
                >
                  {id ? (
                    <span className="fs-3">{data && data.rfid}</span>
                  ) : (
                    <Input
                      onChange={(e) =>
                        setData({ ...data, rfid: e.target.value })
                      }
                      disabled={id}
                      value={data && data.rfid}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="Kart Tanımlama Tarihi" name="cardIssueDate">
                  {id ? (
                    <span className="fs-3">
                      {moment(data && data.cardIssueDate).format('YYYY-MM-DD')}
                    </span>
                  ) : (
                    <DatePicker
                      locale={locale}
                      disabled
                      format="YYYY-MM-DD"
                      onChange={(date, dateString) => {
                        setData({ ...data, cardIssueDate: dateString });
                      }}
                      placeholder="Tarih"
                      defaultValue={
                        data &&
                        (data.cardIssueDate
                          ? moment(data.cardIssueDate, 'DD-MM-YYYY')
                          : moment(new Date(), 'DD-MM-YYYY'))
                      }
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item label="RFID Durumu" name="status">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(v) => {
                      setData({ ...data, status: v.target.value });
                    }}
                  >
                    <option
                      defaultSelected
                      selected={data.status === 'active' ? true : false}
                      value="active"
                    >
                      Aktif
                    </option>
                    <option
                      selected={data.status === 'passive' ? true : false}
                      value="passive"
                    >
                      Pasif
                    </option>
                  </select>
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Araç Plakası"
                  required
                  help={
                    errors.carPlate1 || errors.carPlate2 || errors.carPlate3
                  }
                  validateStatus={
                    errors.carPlate1 || errors.carPlate2 || errors.carPlate3
                      ? 'error'
                      : 'success'
                  }
                >
                  <div className="rounded-1 input-group mb-3">
                    <input
                      placeholder="İl Kodu"
                      type="number"
                      style={{
                        border: '1px solid #ced4da',
                        width: '33%',
                        padding: '0.375rem 0',
                      }}
                      name="carPlate1"
                      defaultValue={data && data.carPlate1}
                      onChange={(e) => {
                        setData({ ...data, carPlate1: e.target.value });
                      }}
                    />

                    <input
                      placeholder="Harf"
                      defaultValue={data && data.carPlate2}
                      style={{
                        border: '1px solid #ced4da',
                        width: '33%',
                        padding: '0.375rem 0',
                      }}
                      onChange={(e) => {
                        setData({ ...data, carPlate2: e.target.value });
                      }}
                    />
                    <input
                      placeholder="Sayı"
                      type="number"
                      style={{
                        border: '1px solid #ced4da',
                        width: '33%',
                        padding: '0.38rem 0',
                      }}
                      defaultValue={data && data.carPlate3}
                      onChange={(e) => {
                        setData({ ...data, carPlate3: e.target.value });
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Kart Bakiyesi"
                  name="price"
                  required
                  help={errors.price}
                  validateStatus={errors.price ? 'error' : 'success'}
                >
                  <input
                    type="number"
                    class="form-control"
                    name="price"
                    defaultValue={data && data.price ? data.price : ''}
                    onChange={(e) => {
                      setData({ ...data, price: Number(e.target.value) });
                    }}
                    placeholder="0"
                  />
                  <small className="text-danger">
                    Sadece Ön Ödemeli Tesisler Kullanabilir !
                  </small>
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label="Kart Açıklaması"
                  name="description"
                  required
                  rules={[
                    {
                      required: id ? false : true,
                      message: 'Lütfen açıklama giriniz !!!',
                    },
                    {
                      min: 10,
                      message: 'En Az 10 Karakter Girişi Yapınız!',
                    },
                  ]}
                >
                  <textarea
                    onChange={(e) =>
                      setData({ ...data, description: e.target.value })
                    }
                    defaultValue={
                      data && data.description ? data.description : ''
                    }
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                  ></textarea>
                </Form.Item>
              </Col>
            </Row>

            <Button danger htmlType="submit" size="large" block>
              RFID Kaydet
            </Button>
          </Form>
        </>
      </Modal>
    </>
  );
};

export default RfidModal;
