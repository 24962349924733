import { Input } from 'antd';
import React, { useMemo } from 'react';

const DebounceSrcatch = ({ name, record, setRecord, disabled }) => {
  const debounce = (func, delay) => {
    let timer = null;
    return function () {
      let context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        func.apply(context, args);
      }, delay);
    };
  };

  const setDataFunction = (e) => {
    console.log(e);
    setRecord({ ...record, [name]: e });
  };

  const optimizedFn = useMemo(
    () => debounce(setDataFunction, 350),
    [setRecord, record[name]],
  );

  return (
    <>
      <Input
        name={name}
        value={record && record[name] && record[name]}
        onChange={(e) => optimizedFn(e.target.value)}
        disabled={disabled ? disabled : false}
      />
    </>
  );
};

export default React.memo(DebounceSrcatch);
