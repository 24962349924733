import React, { useState } from 'react';
import { message, Modal, Space } from 'antd';
import api from '../../service';
import moment from 'moment';

const FaultRecordHistoryModal = ({ recordId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);

  const getData = () => {
    api
      .get('/rest/fault-record-history/' + recordId)
      .then(({ data: { result, result_message } }) => {
        if (result) {
          setData(result);
        } else {
          message.error(result_message.message);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error('Bir hata oluştu !');
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
    getData();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Space wrap>
        <button type="button" onClick={showModal} className="btn btn-light">
          <i className="fa-solid fa-clock-rotate-left"> </i>Geçmiş
        </button>
      </Space>

      <Modal
        title="RFID Yönetimi"
        visible={isModalOpen}
        bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
        onOk={showModal}
        onCancel={handleCancel}
        style={{
          borderRadius: '21px !important',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100% !important',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <>
                    <div
                      style={{
                        borderRadius: '15px',
                        border: '1px solid #e8e8e8',
                        padding: '10px',
                        width: '100vh',
                        overflow: 'hidden',
                        marginBottom: '10px',
                        backgroundColor: '#f5f5f5',
                      }}
                    >
                      <div style={{ width: '50%', float: 'left' }}>
                        <b>Kullanıcı :</b>
                        <br />
                        {item.username}
                      </div>
                      <div style={{ width: '50%', float: 'left' }}>
                        <b>Açıklaması :</b>
                        <br />
                        {item.description}
                      </div>
                      <div style={{ width: '50%', float: 'left' }}>
                        <b>Sorumlusu :</b>
                        <br />
                        {item.responsible && item.responsible === 'callCenter'
                          ? 'Çağru Merkezi'
                          : item.responsible === 'operator'
                          ? 'İşletmeci'
                          : item.responsible === 'fieldServices'
                          ? 'Saha Hizmetleri'
                          : item.responsible === 'software'
                          ? 'Yazılım'
                          : item.responsible === 'guarantee'
                          ? 'Garanti'
                          : ''}
                      </div>
                      <div style={{ width: '50%', float: 'left' }}>
                        <b>Tarihi :</b>
                        <br />
                        {moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div style={{ textAlign: 'center' }}>
                  Kayıt Üzerinde Henüz Bir Değişiklik Yapılmadı ...
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(FaultRecordHistoryModal);
