import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Card, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { CityPicker } from '../../components';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CountryPicker from '../../components/countryPicker';
import DistrictPicker from '../../components/districtPicker';

const ServiceProvidersDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let [languagesCms] = useGlobal('languagesCms');
  let [langCms] = useGlobal('langCms');
  let [langCode] = useGlobal('langCode');
  const [authorizations] = useGlobal('authorizations');
  const [user] = useGlobal('user');

  let newRecord = {
    name: 'hizmet',
    lastname: 'saglayici',
    role: 21,
    phone: '',
    superscription: '',
    email: '',
    vkn: '',
    lang: langCode ? langCode : langCms ? langCms[0].code : '',
    credit: '0.00',
    countryId: '43',
    cityId: '',
    filters: {
      ac_type_2: true,
      ccs2: true,
      chademo: true,
      unknown: true,
      suitable_for_charging: true,
      out_of_service: true,
      in_use: true,
    },
    districtId: '',
    stationId: '',
    postCode: '',
    address: '',
    userGroup: 'guest',
    userType: 'individual',
  };
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [isSmall] = useGlobal('isSmall');

  let [modules] = useGlobal('modules');

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/attendees/' + id)
        .then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            setData(result);
          } else {
            message.error(result_message.message, 2.5);
            history.push('/service-providers');
          } 
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.phone === null || data.phone.length === 0)
      errors.phone = languagesCms.ENFORCED;

    if (data.email === null || data.email.length === 0)
      errors.email = languagesCms.ENFORCED;

    if (data.superscription === null || data.superscription === '')
      errors.superscription = languagesCms.ENFORCED;

    if (data.districtId === null || data.districtId.length === 0)
      errors.districtId = languagesCms.ENFORCED;

    if (data.cityId === null || data.cityId.length === 0)
      errors.cityId = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (data.phone && !data.phone.startsWith('+')) data.phone = "+" + data.phone;
      if (id) {
        api
          .put('/rest/attendees/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/service-providers');
            } else message.error(result_message.message, 2);
          });
      } else {
        api
          .post('/rest/service-providers', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/service-providers');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>

        <div className="list-buttons">
          <Link to="/service-providers">
            <Button
              type="light"
              icon={<LeftOutlined />}
              size={'large'}
              style={{ fontSize: '14px', height: '100%' }}
            >
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form
            disabled={
              authorizations &&
              authorizations
                .find((a) => a.number === user.role)
                .restraintList.includes('edit')
            }
            layout="horizontal"
            size={'large'}
            onFinish={save}
          >
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Unvan"
                  required
                  help={errors.superscription}
                  validateStatus={errors.superscription ? 'error' : 'success'}
                >
                  <Input
                    name="superscription"
                    value={data.superscription}
                    onChange={(e) =>
                      setData({ ...data, superscription: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.PHONE}
                  required
                  help={errors.phone}
                  validateStatus={errors.phone ? 'error' : 'success'}
                >
                  <PhoneInput
                    width={'100%'}
                    country={'tr'}
                    value={data.phone}
                    onChange={(e) => setData({ ...data, phone: e })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.EMAIL}
                  required
                  help={errors.email}
                  validateStatus={errors.email ? 'error' : 'success'}
                >
                  <Input
                    name="email"
                    value={data.email}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.COUNTRY}
                  help={errors.countryId}
                  validateStatus={errors.countryId ? 'error' : 'success'}
                >
                  <CountryPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="countryId"
                    mode="single"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.CITY}
                  help={errors.cityId}
                  validateStatus={errors.cityId ? 'error' : 'success'}
                >
                  <CityPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="cityId"
                    mode="single"
                    disabled={data.countryId ? false : true}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.DISTRICT}
                  help={errors.districtId}
                  validateStatus={errors.districtId ? 'error' : 'success'}
                >
                  <DistrictPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="districtId"
                    mode="single"
                    disabled={data.cityId ? false : true}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.VKN}
                  help={errors.vkn}
                  validateStatus={errors.vkn ? 'error' : 'success'}
                >
                  <Input
                    name="vkn"
                    value={data.vkn}
                    onChange={(e) => setData({ ...data, vkn: e.target.value })}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.POST_CODE}
                  help={errors.postCode}
                  validateStatus={errors.postCode ? 'error' : 'success'}
                >
                  <Input
                    name="postCode"
                    value={data.postCode}
                    onChange={(e) =>
                      setData({ ...data, postCode: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.CREATE_NEW_PASSWORD}
                  help={errors.password}
                  validateStatus={errors.password ? 'error' : 'success'}
                >
                  <Input
                    name="password"
                    onChange={(e) =>
                      setData({ ...data, password: e.target.value })
                    }
                  />
                  <br />
                  <small>{languagesCms.PASSWORD_LEAVE_BLANK}</small>
                  <br />
                  <small>{languagesCms.PASSMESSAGE}</small>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.ADRESS}
                  help={errors.address}
                  validateStatus={errors.address ? 'error' : 'success'}
                >
                  <Input.TextArea
                    name="address"
                    value={data.address}
                    onChange={(e) =>
                      setData({ ...data, address: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    style={{
                      backgroundColor: '#9eb23b',
                      borderColor: 'transparent',
                    }}
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};
export default ServiceProvidersDetail;
