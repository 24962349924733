import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Button } from 'antd';
import api from '../../service/index';
import { Link, useParams } from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';
import { LeftOutlined } from '@ant-design/icons';
import { LoadingAnimation } from '../../components';
import GetReports from '../../components/GetReports';

const FacilityReport = (props) => {
  const params = useParams();
  const [data, setData] = useState([]);
  const [queryData, setQueryData] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isSmall] = useGlobal('isSmall');
  const [languagesCms] = useGlobal('languagesCms');

  const { userId } = params;

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };
  let get = async (page, filters, sorter, query) => {
    setLoading(true);

    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }

    let _params = { ...page, ...query };

    let restData = await api
      .get(`/rest/history/${userId}?${qs.stringify(_params)}`, {
        _params,
      })
      .then(({ data }) => {
        if (data && data.result && data.result.rows) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);

    setLoading(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setLoading(true);

    const query = {
      startDate: urlParams.get('startDate'),
      endDate: urlParams.get('endDate'),
    };
    setQueryData({ ...query, userId });
    get(undefined, undefined, undefined, query);
  }, []);

  let columns = [
    {
      title: 'Son Kullanıcı ( Sürücü )',
      dataIndex: 'userName',
      align: 'center',
      key: 'userName',
      render: (text, record) => (record.userName ? record.userName : ''),
    },
    {
      title: 'Şarj İstasyonu',
      dataIndex: 'stationName',
      align: 'center',
      key: 'stationName',
    },
    {
      title: 'Tesis',
      dataIndex: 'dealerName',
      align: 'center',
      key: 'dealerName',
    },
    {
      title: 'Cihaz ID',
      dataIndex: 'unique_id',
      align: 'center',
      key: 'unique_id',
    },
    {
      title: languagesCms.CONNECTOR_COUNT,
      dataIndex: 'connector_id',
      align: 'center',
      key: 'connector_id',
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      align: 'center',
      key: 'transactionId',
    },
    {
      title: languagesCms.DATE,
      dataIndex: 'createdAt',
      align: 'center',
      key: 'createdAt',
      render: (text, record) =>
        moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            Tesis Şarj Raporları
          </h1>
        </div>
        <div className="list-buttons">
          {data && data.length > 0 && (
            <GetReports
              fileName={
                languagesCms.Charging_Stations + '_' + languagesCms.REPORT
              }
              url={'/rest/reports/charge-report'}
              query={queryData}
              className=" w-100"
              size={'medium'}
            />
          )}

          <Link
            to={`/facility-charge-information`}
            style={{ marginRight: '5px', marginLeft: '5px' }}
          >
            <Button
              type="light"
              icon={<LeftOutlined />}
              size={'medium'}
              style={{ fontSize: '14px', height: '100% ' }}
            >
              {!isSmall && 'Geri'}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          size="medium"
          loading={{
            spinning: loading,
            indicator: <LoadingAnimation />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default FacilityReport;
