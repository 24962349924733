import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Button } from 'antd';
import api from '../../service/index';
import { Link, useParams } from 'react-router-dom';
import qs from 'qs';
import { LeftOutlined } from '@ant-design/icons';
import { LoadingAnimation } from '../../components';
import moment from 'moment';
import GetReports from '../../components/GetReports';

const PriceHistory = (props) => {
  const params = useParams();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isSmall] = useGlobal('isSmall');
  const [languagesCms] = useGlobal('languagesCms');

  const { id, stationId } = params;

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };
  let get = async (page, filters, sorter) => {
    setLoading(true);

    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }

    let _params = { ...page };

    let restData = await api
      .get(`/rest/price-history/${id}?${qs.stringify(_params)}`, {
        _params,
      })
      .then(({ data }) => {
        if (data && data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.map((item, key) => {
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);

    setLoading(false);
  };

  console.log(data);

  useEffect(() => {
    get();
  }, []);

  let columns = [
    {
      title: 'Kullanıcı',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
    },
    {
      title: 'Önceki Fiyat',
      dataIndex: 'oldPrice',
      key: 'oldPrice',
      align: 'center',
      sortDirections: ['descend', 'ascend'],
      render: (text) =>
        text && <span className="text-danger">{text} TL / kWh</span>,
    },
    {
      title: 'Sonraki Fiyat',
      dataIndex: 'newPrice',
      key: 'newPrice',
      align: 'center',
      sortDirections: ['descend', 'ascend'],
      render: (text) =>
        text && <span className="text-success">{text} TL / kWh</span>,
    },
    {
      title: 'Önceki Hizmet Bedeli',
      dataIndex: 'oldServiceFee',
      key: 'oldServiceFee',
      align: 'center',
      sortDirections: ['descend', 'ascend'],
      render: (text) => text && <span className="text-danger">{text} %</span>,
    },
    {
      title: 'Sonraki Hizmet Bedeli',
      dataIndex: 'newServiceFee',
      key: 'newServiceFee',
      align: 'center',
      sortDirections: ['descend', 'ascend'],
      render: (text) => text && <span className="text-success">{text} %</span>,
    },
    {
      title: languagesCms.DATE,
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text) => moment(text).format('DD/MM/YYYY HH:mm:ss'),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            İstasyon Tarifeleri
          </h1>
        </div>
        <div className="list-buttons">
          <GetReports
            fileName={'Fiyat Gecmisi'}
            url={`/rest/reports/price-history/${id}`}
          />
          <Link to={`/prices/${stationId}`} style={{ marginRight: '5px' }}>
            <Button
              type="light"
              icon={<LeftOutlined />}
              size={'large'}
              style={{ fontSize: '14px', height: '100% ' }}
            >
              {!isSmall && 'Geri'}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          size="medium"
          loading={{
            spinning: loading,
            indicator: <LoadingAnimation />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default PriceHistory;
