import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { Table, Space, Button } from 'antd';
import api from '../../service/index';
import { Link, useParams } from 'react-router-dom';
import qs from 'qs';
import { Loading3QuartersOutlined, LeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import GetReports from '../../components/GetReports';

const UserChargeInformationList = (props) => {
  const params = useParams();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isSmall] = useGlobal('isSmall');
  const [languagesCms] = useGlobal('languagesCms');
  const [modules] = useGlobal('modules');
  const path = props.location.pathname.split('/')[1];
  const [module] = modules.filter((el) => el._id === path);
  let query = [];
  query['deleted'] = false;

  const [stationNameInfo] = useState([]);
  const stationNameSet = [...new Set(stationNameInfo.map(JSON.stringify))].map(
    JSON.parse,
  );
  const [dealerNameInfo] = useState([]);
  const dealerNameSet = [...new Set(dealerNameInfo.map(JSON.stringify))].map(
    JSON.parse,
  );
  const [deviceIdInfo] = useState([]);
  const deviceIdSet = [...new Set(deviceIdInfo.map(JSON.stringify))].map(
    JSON.parse,
  );

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }
    let _params = { sort: shortString, ...page, query };

    let restData = await api
      .get(`/rest/user-charge-list/${params.userId}?${qs.stringify(_params)}`, {
        _params,
      })
      .then(({ data }) => {
        if (data.result && data.result.rows) {
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            item.stationName = item && item.stationId && item.stationId.name;
            if (item.stationName) {
              stationNameInfo.push({
                text: item.stationName,
                value: item.stationName,
              });
            }
            if (item.dealerName) {
              dealerNameInfo.push({
                text: item.dealerName,
                value: item.dealerName,
              });
            }
            if (item.unique_id) {
              deviceIdInfo.push({
                text: item.unique_id,
                value: item.unique_id,
              });
            }
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
    setLoading(false);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  let columns = [
    {
      title: 'Şarj İstasyonu',
      dataIndex: 'stationName',
      key: 'stationName',
      filters: stationNameSet,
      onFilter: (value, record) =>
        record.stationName && record.stationName.indexOf(value) === 0,
    },
    {
      title: 'Tesis',
      dataIndex: 'dealerName',
      key: 'dealerName',
      filters: dealerNameSet,
      onFilter: (value, record) =>
        record.dealerName && record.dealerName.indexOf(value) === 0,
    },
    {
      title: 'Cihaz Id',
      dataIndex: 'unique_id',
      key: 'unique_id',
      filters: deviceIdSet,
      onFilter: (value, record) =>
        record.unique_id && record.unique_id.indexOf(value) === 0,
    },
    {
      title: 'Rfid',
      dataIndex: 'idTag',
      key: 'idTag',
    },
    {
      title: 'Şarj Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) =>
        moment(record.createdAt).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'right',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link
            to={`/user-charge-information/list/${params.userId}/${record._id}`}
          >
            <Button
              style={{
                backgroundColor: '#f5f7f6',
                color: '#000',
                border: 'solid 1px #00BD6D',
                borderRadius: '7px',
              }}
              icon={<i className="fa-solid fa-chevron-right"></i>}
            >
              {!isSmall && 'Detay'}
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module && module.name && data && data[0]
              ? module.name + ' - ' + (data[0].name + ' ' + data[0].lastname)
              : module && module.name
              ? module.name + ' - Geçmiş Şarj Bulunmamaktadır'
              : ''}
          </h1>
        </div>
        <div className="list-buttons">
          <GetReports
            fileName={'KullanıcıSarjlari'}
            url={`/rest/reports/user-charge/${params.userId}`}
          />
          <Link to="/user-charge-information">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default UserChargeInformationList;
