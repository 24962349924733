import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { Table, Space, Button, message } from 'antd';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { EditOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

const Authorization = (props) => {
  const [data, setData] = useState([]);
  
  const [languagesCms] = useGlobal('languagesCms');
  const [isSmall] = useGlobal('isSmall');
  let [modules] = useGlobal('modules');
  const [user] = useGlobal('user');
  let [authorizations] = useGlobal('authorizations');
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  let path = props.location.pathname.split('/')[1];
  let [module] = modules.filter((el) => el._id === path);
  let query = [];

  const handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  const get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }
    let _params = { sort: shortString, ...page, query };

    let restData = await api
      .get(`/rest/authorizations-list?${qs.stringify(_params)}`, {
        _params,
      })
      .then(({ data }) => {
        if (data && data.result) {
          setTotalCount(data.result.total);
          return (
            data &&
            data.result &&
            data.result.rows &&
            data.result.rows.map((item, key) => {
              item.key = key;
              return item;
            })
          );
        } else message.error('Bir hata oluştu lütfen tekrar deneyin !');
      })
      .finally(() => {
        setLoading(false);
      });
    setData(restData);
  };

  useEffect(() => {
    setLoading(true);
    get();
  }, []);

  const columns = [
    {
      title: 'Yetki',
      dataIndex: 'name',
      align: 'left',
      key: 'key',
      render: (text) =>
        text === 'argeAdmin'
          ? 'Arge Admin'
          : text === 'callcenter'
          ? 'Çağrı Merkezi'
          : text === 'cpoadmin'
          ? 'CPO Admin'
          : text === 'mspadmin'
          ? 'MSP Admin'
          : text === 'stadmin'
          ? 'St Admin'
          : text === 'user'
          ? 'Son Kullanıcı'
          : text === 'dealer'
          ? 'Bayi'
          : text,
    },
    {
      title: languagesCms.ACTIONS,
      align: 'center',
      width: 150,
      key: 'key',
      render: (text, record) => (
        <Space size="small">
          {!(
            authorizations &&
            authorizations
              .find((a) => a.number === user.role)
              .restraintList.includes('edit')
          ) && (
            <Link to={`/authorization/edit/${record._id}`}>
              <Button icon={<EditOutlined />}>
                {!isSmall && languagesCms.EDIT}
              </Button>
            </Link>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}{' '}
          </h1>
        </div>
        <div className="list-buttons"></div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default Authorization;
