import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input } from 'antd';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { EditOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

const HistoryStations = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  const [languagesCms] = useGlobal('languagesCms');
  const [user] = useGlobal('user');
  let [modules] = useGlobal('modules');
  let path = props.location.pathname.split('/')[1];
  let [module] = modules.filter((el) => el._id === path);

  let query = [];
  query['deleted'] = false;

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'unique_id,name,chargepointStatus';
    }
    let restData = await api
      .get(`/rest/stations?${qs.stringify(_params)}`, { _params })
      .then(async ({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            if (item.active) item.active = <div className="true-circle"></div>;
            else item.active = <div className="false-circle"></div>;

            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  function turkishSort(a, b) {
    var alfabe =
      'AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789';
    if (a.length === 0 || b.length === 0) {
      return a.length - b.length;
    }
    for (var i = 0; i < a.length && i < b.length; i++) {
      var ai = alfabe.indexOf(a[i]);
      var bi = alfabe.indexOf(b[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  }

  let columns = [
    {
      title: 'Şarj İstasyonu Adı',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.CITY,
      dataIndex: 'city',
      key: 'city',
      sorter: (a, b) => a.city - b.city,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.COUNTRY,
      dataIndex: 'country',
      key: 'country',
      sorter: (a, b) => turkishSort(a.country, b.country),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.STATUS,
      width: '50px',
      dataIndex: 'active',
      key: 'active',
      sorter: (a, b) => a.isLogin - b.isLogin,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'right',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={'/historyView/' + record._id}>
            <Button
              icon={<i className="fa-solid fa-clipboard-list"></i>}
              style={{
                backgroundColor: '#f5f7f6',
                color: '#000',
                border: 'solid 1px #00BD6D',
                borderRadius: '7px',
              }}
            >
              {' '}
              {languagesCms.DETAIL}{' '}
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1
            style={{
              display: 'inline-block',
              marginRight: '2rem',
              color: '#fff',
            }}
          >
            {module ? module.name : ''}
          </h1>
        </div>
        <div className="list-buttons">
          {user && [1, 2].includes(user.role) && (
            <Link to={'/historyView'}>
              <Button
                type="light"
                icon={<EditOutlined />}
                style={{
                  marginRight: '10px',
                  fontSize: '14px',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                size={'large'}
              >
                {languagesCms.ALL_CHARGING_HISTORY}
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder={languagesCms.SEARCH}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default HistoryStations;
