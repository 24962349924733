import React from 'react';

const noAuthorization = () => {
  return (
    <div
      style={{
        marginTop: '100px',
      }}
    >
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        fontSize: '100px',
        color: '#000',
      }}>
        <i className="fa-solid fa-lock"></i>
      </div>
      <div>
        {' '}
        <h2
          style={{
            fontSize: '30px',
            color: 'red',
            textAlign: 'center',
          }}
        >
          Bu Sayfaya Erişim Yetkiniz Bulunmamaktadır !
        </h2>
      </div>
    </div>
  );
};

export default React.memo(noAuthorization);
