import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Button } from 'antd';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { LeftOutlined } from '@ant-design/icons';
import { LoadingAnimation } from '../../components';
import UserChargeHistory from '../../components/user/userChargeHistory';
import DateFilterFacility from '../../components/reports/DateFilterFacility';

const FacilityReport = (props) => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isSmall] = useGlobal('isSmall');
  const [languagesCms] = useGlobal('languagesCms');

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };
  let get = async (page, filters, sorter) => {
    setLoading(true);

    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }

    let _params = { ...page };

    let restData = await api
      .get(`/rest/attendees?${qs.stringify(_params)}`, {
        _params,
      })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.map((item, key) => {
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);

    setLoading(false);
  };

  useEffect(() => {
    get();
  }, []);

  let columns = [
    {
      title: languagesCms.NAME,
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: languagesCms.LASTNAME,
      dataIndex: 'lastname',
      key: 'lastname',
      align: 'center',
    },

    {
      title: languagesCms.PHONE,
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
    },
    {
      title: 'Aksiyonlar',
      dataIndex: 'description',
      key: 'description',
      width: '28%',
      align: 'center',
      render: (text, record) => (
        <>
          <div>
            <UserChargeHistory
              size={'medium'}
              className="w-100 btn btn-outline-danger mb-1 btn-sm py-0"
              userId={record && record._id}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            Tesis Şarj Raporları
          </h1>
        </div>
        <div className="list-buttons">
          <DateFilterFacility
            className={'w-100 me-2 btn btn-danger btn-sm py-0'}
            fileName={'Tesis_Sarj_Raporu'}
            url={'/rest/reports/facility-charge-report'}
          />
          <Link to={`/prices`} style={{ marginRight: '5px' }}>
            <Button
              type="light"
              icon={<LeftOutlined />}
              size={'large'}
              style={{ fontSize: '14px', height: '100% ' }}
            >
              {!isSmall && 'Geri'}
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          size="medium"
          loading={{
            spinning: loading,
            indicator: <LoadingAnimation />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default FacilityReport;
