import React, { useEffect, useState, useCallback } from 'react';
import { useGlobal } from 'reactn';
import { Button, Modal, Space, Table } from 'antd';
import api from '../../service/index';
import qs from 'qs';
import moment from 'moment';

import { LoadingAnimation } from '../../components';

const CmsLog = (props) => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewData, setViewData] = useState({});

  const [languagesCms] = useGlobal('languagesCms');
  const [modules] = useGlobal('modules');

  const path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  const handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  const get = useCallback(async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = '-date';
    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page };

    const restData = await api
      .get(`/rest/cms-log?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  console.log(data);

  const columns = [
    {
      title: 'Kullanıcı',
      dataIndex: 'userName',
      key: 'key',
      sorter: (a, b) => a.userName - b.userName,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Mesaj',
      dataIndex: 'message',
      key: 'key',
      align: 'center',
    },
    {
      title: 'Log Tipi',
      dataIndex: 'level',
      key: 'key',
      align: 'center',
      render: (text) =>
        text === 'info'
          ? 'Bilgi'
          : text === 'error'
          ? 'Hata'
          : text === 'warning'
          ? 'Uyarı'
          : text,
    },
    {
      title: 'Tarih',
      dataIndex: 'createdAt',
      key: 'key',
      align: 'center',
      render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: languagesCms.ACTIONS,
      align: 'center',
      key: 'key',
      render: (text, record) => {
        return (
          <Space size="small">
            <Button
              className="btn btn-outline-danger"
              onClick={() => {
                setIsModalOpen(true);
                setViewData(record && record.data);
              }}
            >
              Detay
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : languagesCms.USER_LOGS}</h1>
        </div>
        <div className="list-buttons"></div>
      </div>

      <Modal
        title="Bilgi"
        open={isModalOpen}
        okText="Kapat"
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        <pre
          style={{
            fontWeight: 'medium',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '1.3rem',
          }}
        >
          {JSON.stringify(viewData, null, 2)}
        </pre>
      </Modal>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <LoadingAnimation />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default CmsLog;
