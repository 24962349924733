import React from 'react';

const LoadingAnimation = () => {
  return (
    <div className="loading-container">
      <div className="loading-body">
        <div className="loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingAnimation;
