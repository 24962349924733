import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, message } from 'antd';
import api from '../../service/index';
import { Link, useHistory } from 'react-router-dom';
import qs from 'qs';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import moment from 'moment';
import { env } from '../../app.config';

const ContactUs = (props) => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [languagesCms] = useGlobal('languagesCms');
  const [modules] = useGlobal('modules');
  const path = props.location.pathname.split('/')[1];
  let [module] = modules.filter((el) => el._id === path);
  let query = [];
  query['status'] = 'open';

  const history = useHistory();

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    setLoading(true);
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = '-createdAt';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    const restData = await api
      .get(`/rest/contact-us?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result && data.result) {
          setTotalCount(data.result.total);
          return (
            data.result &&
            data.result.rows.map((item, key) => {
              item.key = key;
              return item;
            })
          );
        }
      });
    setData(restData);
    setLoading(false);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  let columns = [
    {
      title: 'Kullanıcı',
      dataIndex: 'user',
      key: 'user',
      responsive: ['xs', 'sm', 'md'],
    },
    {
      title: 'Şarj İstasyonu',
      dataIndex: 'station',
      key: 'station',
      align: 'center',
      sorter: (a, b) => a.station - b.station,
      sortDirections: ['descend', 'ascend'],
      responsive: ['xs', 'sm', 'md'],
    },
    {
      title: 'Tesis',
      dataIndex: 'dealer',
      key: 'dealer',
      align: 'center',
      sorter: (a, b) => a.dealer - b.dealer,
      sortDirections: ['descend', 'ascend'],
      responsive: ['xs', 'sm', 'md'],
    },
    {
      title: 'Cihaz Id',
      dataIndex: 'unique_id',
      key: 'unique_id',
      align: 'center',
      sorter: (a, b) => a.unique_id - b.unique_id,
      sortDirections: ['descend', 'ascend'],
      responsive: ['xs', 'sm', 'md'],
    },
    {
      title: 'Konnektör Id',
      dataIndex: 'connector_id',
      align: 'center',
      key: 'connector_id',
      sorter: (a, b) => a.connector_id - b.connector_id,
      sortDirections: ['descend', 'ascend'],
      responsive: ['md'],
    },
    {
      title: 'Öncelik',
      dataIndex: 'priority',
      align: 'center',
      key: 'priority',
      sortDirections: ['descend', 'ascend'],
      responsive: ['md'],
      filters: [
        { text: 'Yüksek', value: 'high' },
        { text: 'Orta', value: 'medium' },
        { text: 'Düşük', value: 'low' },
      ],
      onFilter: (value, record) =>
        record.priority && record.priority.indexOf(value) === 0,
      render: (text) => {
        if (text === 'high') {
          return (
            <span
              style={{ color: 'red', fontWeight: 'bold', fontSize: '1rem' }}
            >
              Yüksek
            </span>
          );
        } else if (text === 'medium') {
          return (
            <span
              style={{ color: 'orange', fontWeight: 'bold', fontSize: '1rem' }}
            >
              Orta
            </span>
          );
        } else {
          return (
            <span
              style={{ color: 'green', fontWeight: 'bold', fontSize: '1rem' }}
            >
              Düşük
            </span>
          );
        }
      },
    },
    {
      title: 'Kayıt Tarihi',
      dataIndex: 'createdAt',
      align: 'center',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) =>
        moment(record.createdAt).format('DD-MM-YYYY HH:mm:ss'),
      responsive: ['md'],
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'right',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={'/contact-us/edit/' + record._id}>
            <Button
              style={{
                backgroundColor: '#f5f7f6',
                color: '#000',
                border: 'solid 1px #00BD6D',
                borderRadius: '7px',
              }}
              icon={<i className="fa-solid fa-hammer"></i>}
            >
              Detay
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  const activeCalls = () => {
    return history.push(
      window.location.pathname + '/active-calls/' + env.COMPANY,
    );
  };

  const historyCall = () => {
    return history.push(
      window.location.pathname + '/history-calls/' + env.COMPANY,
    );
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1 style={{ display: 'inline-block', marginRight: '2rem' }}>
            {module ? module.name : ''}{' '}
          </h1>
        </div>
        <div className="list-buttons">
          <Button
            type="light"
            onClick={historyCall}
            className="customize-botton2 me-2"
          >
            Geçmiş Çağrılar
          </Button>
          <Button
            type="light"
            onClick={activeCalls}
            className="customize-botton2 me-2"
          >
            Aktif Çağrılar
          </Button>
          <Button
            type="light"
            onClick={() => {
              api
                .get('/rest/contact-us-first-record')
                .then(({ data: { result, result_message } }) => {
                  if (result) {
                    return history.push('/contact-us/add/' + result._id);
                  } else {
                    message.error(
                      'Kayıt Oluşturulamıyor ! ',
                      result_message.message,
                      3,
                    );
                  }
                })
                .catch((err) => {
                  message.error(err.message);
                });
            }}
            icon={<i className="fa-solid fa-plus"></i>}
          >
            Kayıt Oluştur
          </Button>
        </div>
      </div>

      <div className="table-wrap">
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default ContactUs;
