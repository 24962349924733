import React, { useEffect, useState, useGlobal } from 'reactn';
import {
  Table,
  Space,
  Button,
  Input,
} from 'antd';
import api from '../../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import {
  EnvironmentOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';

const PriceStationList = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [languagesCms] = useGlobal('languagesCms');
  let [modules] = useGlobal('modules');
  const [cpoInfo] = useState([]);
  const [SiteInfo] = useState([]);
  const SiteSet = [...new Set(SiteInfo.map(JSON.stringify))].map(JSON.parse);

  let query = [];
  query['deleted'] = false;

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'name';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page, query };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();

      // arama başarılı çalışması için alanlar ', ' bu şekilde ayrım yapılması gerekiyor.
      _params['searchFields'] = 'unique_id, name, chargepointStatus';
    }
    let restData = await api
      .get(`/rest/station-get?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.map((item, key) => {
            if ( item.Site ) SiteInfo.push({ text: item.Site && item.Site.SiteName, value: item.Site && item.Site.SiteName });
            if (item.cpoUserName) {
              cpoInfo.push({ text: item.cpoUserName, value: item.cpoUserName });
            }
            if (item.active) item.active = <div className="true-circle"></div>;
            else item.active = <div className="false-circle"></div>;

            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };
  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };

  let columns = [
    {
      title: languagesCms.NAME,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.CITY,
      dataIndex: 'city',
      key: 'city',
      sorter: (a, b) => a.city - b.city,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Tesis',
      dataIndex: 'dealerId',
      key: 'dealerId',
      render: (text, record) => record.Site && record.Site.SiteName && record.Site.SiteName.length > 0 ? record.Site.SiteName : '-',
      filters: SiteSet,
      onFilter: (value, record) =>
        record.Site && record.Site.SiteName && record.Site.SiteName.indexOf(value) === 0
    },
    {
      title: languagesCms.ACTIONS,
      key: 'action',
      align: 'center',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          <Link to={`/prices/${record._id}`}>
            <Button 
              icon={<EnvironmentOutlined />}>
                {!isSmall && "Fiyatları Görüntüle"}
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h4
            style={{
              display: 'inline-block',
              marginRight: '2rem',
              marginBottom: '1rem',
              color: '#fff',
            }}
          >
            İstasyon Fiyatları
          </h4>
        </div>
      </div>

      <div className="table-wrap">
        <Search
          placeholder="Arama..."
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default PriceStationList;